import React from 'react';
import './Home.css';
import 'semantic-ui-css/semantic.min.css';
import { Grid, Image, Button, Container } from 'semantic-ui-react'
import workimg from '../../Images/Group41.png';
function Work() {
  return (
    <div className="workstart">
      <p className="worksectionheader">How Shade Charger Table Works</p>
      <Container>
        <Grid>
          <Grid.Column mobile={16} tablet={16} computer={5}>
            <div className="worklistsec">
              <p className="servicelistheader">Log in</p>
              <p className="workdetails">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                     sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              <img src={workimg} className="worlsecimg" />
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={5}>
            <div className="worklistsec">
              <p className="servicelistheader">Scan the QR code</p>
              <p className="workdetails">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                     sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              <img src={workimg} className="worlsecimg" />
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={5}>
            <div className="worklistsec">
              <p className="servicelistheader">Payment</p>
              <p className="workdetails">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                     sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              <img src={workimg} className="worlsecimg" />
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    </div>
  );
}

export default Work;
