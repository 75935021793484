import { combineReducers } from 'redux';
import {userDetail} from '../redux/reducers/userDetail';
import {selectedTable} from '../redux/reducers/selectedTable';
import {servicesList} from '../redux/reducers/servicesList';
import { currentOrder } from '../redux/reducers/currentOrder';
import {tableList} from '../redux/reducers/tableList';
import {paymentList} from '../redux/reducers/paymentsList';
import {usersList} from '../redux/reducers/usersList';
import {ordersList} from "../redux/reducers/ordersList";
import {companyorderlist} from "../redux/reducers/companyorderlist";
import {errorMessage} from '../redux/reducers/errorMessage';
import {testChargeMessage} from './reducers/testChargeMessage';
import {voucherList} from "../redux/reducers/voucherList";
import {companyList} from "../redux/reducers/companyList";
import {selectedCompanyByAdmin} from '../redux/reducers/selectedCompany';
import {storeselectedcompany} from '../redux/reducers/storeselectedcompany';
import {feedBacklist} from '../redux/reducers/feedBacklist';



const rootReducer = combineReducers({
 userDetail,
 selectedTable,
 servicesList,
 currentOrder,
 tableList,
 paymentList,
 usersList,
 ordersList,
 companyorderlist,
 errorMessage,
 testChargeMessage,
 voucherList,
 companyList,
 selectedCompanyByAdmin,
 storeselectedcompany,
 feedBacklist,
 

});

export default rootReducer;