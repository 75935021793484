import React, { Component } from 'react';
import '../Main/Main.css';
import './Admin.css';
import 'semantic-ui-css/semantic.min.css'
import { Grid, Image, Button, Radio, Container, Checkbox, Form, Icon, Input, Card, Modal } from 'semantic-ui-react'
import { withRouter, NavLink } from 'react-router-dom';
import MaterialTable from 'material-table';
import tableicon from '../../Images/audit.png';
import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AddTableListForm from './AddTableListForm'
import AddCompanyForm from './AddCompanyForm';

class CompanyList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            modalOpen: false,
            editData: {},
            editmodalOpen: false
        }
    }
    handleOpen = () => this.setState({ modalOpen: true })

    handleClose = () => this.setState({
        modalOpen: false,
        editData: {}
    })


    editTable = (rowData) => {
        console.log(rowData)
        this.setState({ editData: rowData }, () => {
            console.log(this.state.editData);
            this.setState({ modalOpen: true });
            this.setState({ editmodalOpen: true })
        })
        // this.props.actions.editCompany(rowData._id, "Company"
        // )
    }

    componentDidMount() {
        if (this.props.userDetail.role === "admin") {
            this.props.actions.getCompanyList();
            //>> add a refresh button
            // console.log(this.state.editData);
        }

    }


    // savecompany = (rowData) => {
    //     console.log(rowData)
    //     let y = {};
    //     //  y.admin.firstName = this.state.data.admin.firstName ? this.state.data.admin.firstName:null;
    //     //  y.admin.lastName = this.state.data.admin.lastName ? this.state.data.admin.lastName:null;
    //     y.companyName = this.state.data.companyName;
    //     y.companyAddress = this.state.data.companyAddress;
    //     y.companyPhoneNumber = this.state.data.companyPhoneNumber;
    // }



    // console.log(y)
    //     const reqValues = {
    //         method: "PUT",
    //         body: JSON.stringify(y

    //         )

    //     };
    //     fetch(process.env.REACT_APP_apiurl + "/company/updateCompany?id=" + rowData, reqValues)
    //         .then(result =>
    //             // console.log(result))
    //             result.json()
    //         )
    //         .then(result => {
    //             console.log(result);
    //             // console.log(getState());
    //             if (!result.error) {
    //                 this.props.actions.editCompany(result.result)

    //             }
    //         })

    //         .catch(err => {
    //             console.log("Error", err);
    //         });
    //     // };
    // }


    componentWillReceiveProps(newProps) {

        if (!Object.is(newProps.companyList, this.props.companyList)) {
            this.setState({ data: newProps.companyList }, () => {
                console.log("Recieving props>>>>>>>", newProps.companyList)
                console.log(this.state.data)
            })
            //     if (!Object.is(newProps.companyList, this.props.companyList)) {

            //         console.log(newProps.companyList,"L70>>>>")
            //         var arr = newProps.companyList.map(x=>{


            //             var obj={};
            //             obj.key= x._id ;
            //             //    obj.value= x._id ;
            //                 //  obj.text= x.companyName ;
            //             return obj;
            //         })

            // }
            // console.log(this.state.data)

        }
    }



    test1 = (dat) => {
        console.log(dat)

        let data = {
            "https": "false",
            "ip": "192.168.100.100",
            "password": "admin",
            "relay": [{ "number": 1, "pulse": 600 }]

        }


        const reqValues = {
            method: "POST",
            body: JSON.stringify(data),

            headers: {
                "Content-Type": "application/json"
            }
        };
        fetch(
            "http://cdfb5973.ngrok.io/activatePorts",
            reqValues
        )
            .then(
                result =>

                    result.json()

            )

            .then(result => {
                console.log(result);
                //       if (result && result.message==="Service started") {
                //   this.setState({
                //       showModal:true
                //   })

                //       }
            })
            .catch(err => {
                console.log(err);
            });

    }

    start7 = (dat) => {
        console.log(dat)

        let data = {
            "https": "false",
            "ip": "192.168.100.100",
            "password": "admin",
            "relay": [{ "number": 2, "state": 1 }, { "number": 3, "state": 1 }, { "number": 4, "state": 1 },
            { "number": 5, "state": 1 }, { "number": 6, "state": 1 }, { "number": 7, "state": 1 },
            { "number": 8, "state": 1 }]

        }


        const reqValues = {
            method: "POST",
            body: JSON.stringify(data),

            headers: {
                "Content-Type": "application/json"
            }
        };
        fetch(
            "http://cdfb5973.ngrok.io/activatePorts",
            reqValues
        )
            .then(
                result =>

                    result.json()

            )

            .then(result => {
                console.log(result);
                //       if (result && result.message==="Service started") {
                //   this.setState({
                //       showModal:true
                //   })

                //       }
            })
            .catch(err => {
                console.log(err);
            });

    }
    stop7 = (dat) => {
        console.log(dat)

        let data = {
            "https": "false",
            "ip": "192.168.100.100",
            "password": "admin",
            "relay": [{ "number": 2, "state": 0 }, { "number": 3, "state": 0 }, { "number": 4, "state": 0 },
            { "number": 5, "state": 0 }, { "number": 6, "state": 0 }, { "number": 7, "state": 0 },
            { "number": 8, "state": 0 }]

        }


        const reqValues = {
            method: "POST",
            body: JSON.stringify(data),

            headers: {
                "Content-Type": "application/json"
            }
        };
        fetch(
            "http://cdfb5973.ngrok.io/activatePorts",
            reqValues
        )
            .then(
                result =>

                    result.json()

            )

            .then(result => {
                console.log(result);
                //       if (result && result.message==="Service started") {
                //   this.setState({
                //       showModal:true
                //   })

                //       }
            })
            .catch(err => {
                console.log(err);
            });

    }



    setStatus = (x,e,name) => {
        console.log(e.target.value)
        console.log(name)
        console.log(x, "l267>>>>>>>>>>>>>")
        // let y={};
        // y = this.props.companyList;
        // y.enabled=this.props.companyList
        let string= "false"
        if(name.checked){
            string="true"
        }
        console.log(string)
        const reqValues = {
            method: "PUT",

            headers: {
                "Content-Type": "application/json",
            }
        };
        fetch(process.env.REACT_APP_apiurl + "/company/updateCompanyStatus?id=" + x._id +"&data="+ string, reqValues)
            .then(result =>
                // console.log(result))
                result.json()
            )
            .then(result => {
                console.log(result);

                if (!result.error) {
                    this.props.actions.setCompanyStatus(result.result)
                }

            })
            .catch(err => {
                console.log("Error", err);
            });
    };





    // confirm = rowData => {
    //     console.log(rowData);
    //     message.success("Deleting table :" + rowData.name);
    //     this.props.actions.deleteTable(rowData._id);
    //   };
    //   cancel(e) {
    //     console.log(e);
    //     message.error("Clicked on No");
    //   }


    render() {

        var columnData = [
            {
                title: 'Table Name', field: 'name',
                // editComponent: props => (
                //     <input
                //         type="radio"
                //         value={props.value}
                //         onChange={e => props.onChange(e.target.value)}
                //     />
                // )
            },
            { title: 'Site Number', field: 'sno' },
            { title: 'GPS Longitude & Latitude', field: 'gps' },
            {
                title: 'Current Status',
                field: 'status',
                editComponent: props => (
                    <div>
                        <Radio toggle />
                    </div>
                )
                // lookup: { 1: 'Activate', 2: 'Deactivate' },
            },
            {
                title: 'Battery Status',
                field: 'b_status',

            },
        ];

        var companyData = [
            { name: 'Table1', sno: 'T1234', gps: '41.40338, 2.17403.', c_status: 'Active', b_status: '22%' },
            { name: 'Table2', sno: 'T1245', gps: '41.40338, 2.17403.', c_status: 'Inactive', b_status: '44%' },
        ]
        return (
            <div className="tableliststart">
                <div className="historytablestart">

                    <div className="profilepagestart">
                        {/* <div className="payment-heading">
                            <Grid>
                                <Grid.Column computer={8}>
                                    <h2 className="headingtxt"><img src={tableicon} />List of Tables</h2>
                                </Grid.Column>
                            </Grid>
                        </div> */}



                        <div style={{ maxWidth: '100%' }} className="tablediv">
                            <Button onClick={this.handleOpen} className="adminvouchrbutn">
                                <Icon name="plus" />Add new</Button>
                            <Modal
                                open={this.state.modalOpen}
                                onClose={this.handleClose}
                            // trigger={} 
                            >
                                <Modal.Header className="modalheader">
                                    {this.state.editmodalOpen === true ?
                                        <span>Edit Company</span> :
                                        <span>
                                            Add New Company </span>}<Icon onClick={this.handleClose} name="close" className="closeicon" />
                                </Modal.Header>
                                <Modal.Content className="addmodal-content">
                                    {/* {this.state.modalOpen &&  */}
                                    {this.state.modalOpen && <AddCompanyForm
                                        editData={this.state.editData}
                                        onClose={this.handleClose}
                                    />
                                    }
                                </Modal.Content>
                            </Modal>
                            <MaterialTable
                                title="Company List"
                                columns={[
                                    {
                                        title: 'Company Name', field: 'companyName',

                                    },
                                    {
                                        title: 'Address', field: 'companyAddress',
                                        render: rowData => {
                                            return (
                                                <div>
                                                    {
                                                        rowData.companyAddress
                                                    }
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        title: 'Phone Number',
                                        field: 'companyPhoneNumber',
                                        render: rowData => {
                                            if(rowData.companyPhoneNumber)
                                            {
                                            let x = rowData.companyPhoneNumber.replace(/[^\d]+/g, '')
                                              .replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3');
                                            return (
                          
                                              <div>
                                                <p>{x}</p>
                                              </div>
                                            )
                                            }
                                          }

                                    },
                                    {
                                        title: 'Users', field: 'admin',
                                        render: rowData => {
                                            return (
                                                <div>
                                                    {rowData.admin ? rowData.admin.firstName + " " + rowData.admin.lastName : null}
                                                    {/* &&{ rowData.admin && rowData.admin.firstName + " " + rowData.admin.lastName */}
                                                </div>
                                            );
                                        }

                                    },
                                    {
                                        title: 'Status',
                                        field: 'enabled',
                                        render: x => {
                                            // console.log(x,"L431>>>")
                                            return (
                                                <div>
                                                    {/* {
                                                        rowData.enabled === true && */}
                                                    <Radio toggle defaultChecked={x.enabled}  onChange={(e,name) => this.setStatus(x,e,name)} />
                                                    {/* } */}
                                                </div>
                                            )
                                        }
                                        // lookup: { 1: 'Activate', 2: 'Deactivate' },
                                    },

                                ]}
                                data={this.state.data}

                                editable={{

                                    //     onRowUpdate: oldData =>
                                    //         new Promise((resolve, reject) => {
                                    //             console.log(oldData)
                                    //            let z= 
                                    //             this.props.actions.editCompany(oldData)
                                    //             resolve()
                                    //         }, 1000),



                                    // onRowDelete: oldData =>
                                    //     new Promise((resolve, reject) => {
                                    //         // setTimeout(() => {
                                    //         //     {
                                    //         console.log(oldData)
                                    //         this.props.actions.deleteCompany(oldData._id)
                                    //         // let data = this.state.data;
                                    //         // const index = data.indexOf(oldData);
                                    //         // data.splice(index);
                                    //         // this.setState({ data }, () => resolve());
                                    //         // }
                                    //         resolve()
                                    //     }, 1000)
                                }}
                                actions={[

                                    {
                                        icon: () => <Icon name='edit outline' />,
                                        tooltip: 'Edit table data ',
                                        onClick: (event, rowData) => { this.editTable(rowData) }
                                        //   alert("You saved " + rowData.companyName)

                                    },

                                ]}
                                // onRowClick={(event, rowData, togglePanel) => togglePanel()}
                                options={{


                                    // selection: true,
                                    pageSize: 20,
                                    pageSizeOptions: [30, 40, 50],
                                    actionsColumnIndex: -1

                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// export default TableList;

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch, state) {
    return {
        actions: bindActionCreators(actioncreators, dispatch)
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CompanyList)
);
