import React, { Component } from 'react';
import '../Main/Main.css';
import './Admin.css';
import 'semantic-ui-css/semantic.min.css'
import { Image, Button, Container, Checkbox, Form, Icon, Input, Card, TextArea } from 'semantic-ui-react'
import { NavLink, withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';
// import send from '../../Images/send.png';
import send from '../../Images/send-button.png';
import gitimg from '../../Images/profile.png'
import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";



class FeedbackDetails extends Component {


    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            data: {},
            details: {},
            repliesArr: []
        }
    }


    componentWillReceiveProps(newProps) {
        console.log(newProps.idData)
        if (!Object.is(newProps.idData, this.props.idData)) {
            // this.setState({data:newProps.idData },
            //     ()=> console.log(this.state.data))


            let id = newProps.idData
            console.log(id, "L31>>>")
            const reqValues = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            };
            fetch(process.env.REACT_APP_apiurl + "/feedBack/findFeedbackById?id=" + id, reqValues)
                .then(result => result.json())
                .then(result => {
                    console.log(result);
                    if (!result.error) {
                        this.setState({
                            data: result.result,
                            repliesArr: result.result.replies
                        }, () =>
                                // this.props.actions.getfeedbackbyId(result.result)
                                console.log(this.state.data, "L46>>>"))
                    }
                })
        }
    };



    createReply = () => {
        let id = this.state.data._id
        let obj = {};
        obj.repliedDate = new Date();
        obj.repliedBy = this.props.userDetail._id;
        obj.comment = this.state.body;


        var arr = [...this.state.repliesArr]
        arr.push(obj);
        console.log(id)
        var sendBody = {}
        sendBody.replies = arr
        console.log(sendBody)
        const reqValues = {
            method: "PUT",
            body: JSON.stringify(sendBody),
            headers: {
                "Content-Type": "application/json",
            }
        };
        fetch(process.env.REACT_APP_apiurl + "/feedBack/updateFeedBack?id="+ id, reqValues)
            .then(result =>
                // console.log(result))
                result.json()
            )
            .then(result => {
                console.log(result);
                // this.setState({ replies: this.state.replies })
                // let replies = [...this.state.replies]
                if (!result.error) {
                    this.setState({ repliesArr: arr })
                    // replies.push(result.result.replies)
                    // this.props.actions.updateFeedback(result.result)
                }

            })
            .catch(err => {
                console.log("Error", err);
            });
    };



    // saveReply = () => {
    //     console.log(this.state.replies)
    //     if (this.state.data._id) {
    //         let array = [...this.state.replies]
    //         let id = this.state.data._id
    //         let body = {};
    //         var index = array.findIndex(x => x._id === id)
    //         array[index] = { ...this.state.data };
    //         body.replies = array
    //         this.createReply(body)
    //     }
    //     else {
    //         var array = [...this.state.replies]
    //         array.push(this.state.data)
    //         console.log(array, "L357")
    //         this.setState({ replies: array }, () => { console.log(this.state.replies) })
    //         let body = {};
    //         body.replies = array
    //         console.log(body)
    //         this.createReply(body)


    //     }
    // }
    saveReply = (e) => {
        console.log(e.target.value)
        this.setState({ body: e.target.value },
            () => console.log(this.state.body))
    }





    render() {
        return (
            <div className="feedbackdetails">

                <div className="feedbackdetailstitle">
                    {/* <div className="feedbackdetailno">
                        <p>1.</p>
                    </div> */}
                    <div className="feedbackdetailstext">
                        <p className="feedbackdetailtitle">
                            {/* Finibus Bonorum et Malorum */}
                            {this.state.data ? this.state.data.title : null}
                        </p>
                        <p className="feedbackdetaildesc">
                            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                             dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                              Excepteur sint occaecat cupidatat non proident,
                            sunt in culpa qui officia deserunt mollit anim id est laborum. */}
                            {this.state.data ? this.state.data.description : null}
                        </p>
                    </div>
                </div>

                <hr className="dividerresponses" />

                <p className="responsetitle">Responses</p>
                {
                    this.state.repliesArr.map(x => {
                        return <div className="responsesec">
                            <div className="repliedimg">
                            {x.repliedBy.profileImage ? 
                                <img src={process.env.REACT_APP_apiurl + "/getimages/" + x.repliedBy.profileImage} className="gitimgalign" />: null}
                            </div>
                            <div className="replieddetailsec">
                                <p className="responsedname">{x.repliedBy ? x.repliedBy.firstName+ " "+x.repliedBy.lastName : null}</p>
                                <p className="responseddateinfo">Reply :
                        <span className="responsedate">{x.repliedDate ? x.repliedDate : null}</span></p>
                                <p className="repliedtext">
                                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor */}
                                    {/* incididunt ut labore et dolore magna aliqua. */}
                                    {x.comment ? x.comment : null}
                                </p>
                            </div>
                        </div>
                    })}

                {/* <hr className="dividerresponses" />

                <div className="responsesec">
                    <div className="repliedimg">
                        <img src={gitimg} className="gitimgalign" />
                    </div>
                    <div className="replieddetailsec">
                        <p className="responsedname">Any 1</p>
                        <p className="responseddateinfo">Reply : <span className="responsedate">Sep 9,2019</span></p>
                        <p className="repliedtext">  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div> */}
                {/* 
                <hr className="dividerresponses" />

                <div className="responsesec">
                    <div className="repliedimg">
                        <img src={gitimg} className="gitimgalign" />
                    </div>
                    <div className="replieddetailsec">
                        <p className="responsedname">Any 1</p>
                        <p className="responseddateinfo">Reply : <span className="responsedate">Sep 9,2019</span></p>
                        <p className="repliedtext">  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div> */}

                {/* <hr className="dividerresponses" />

                <div className="responsesec">
                    <div className="repliedimg">
                        <img src={gitimg} className="gitimgalign" />
                    </div>
                    <div className="replieddetailsec">
                        <p className="responsedname">Any 1</p>
                        <p className="responseddateinfo">Reply : <span className="responsedate">Sep 9,2019</span></p>
                        <p className="repliedtext">  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div> */}

                {/* <hr className="dividerresponses" />

                <div className="responsesec">
                    <div className="repliedimg">
                        <img src={gitimg} className="gitimgalign" />
                    </div>
                    <div className="replieddetailsec">
                        <p className="responsedname">Any 1</p>
                        <p className="responseddateinfo">Reply : <span className="responsedate">Sep 9,2019</span></p>
                        <p className="repliedtext">  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div> */}

                {/* <hr className="dividerresponses" />

                <div className="responsesec">
                    <div className="repliedimg">
                        <img src={gitimg} className="gitimgalign" />
                    </div>
                    <div className="replieddetailsec">
                        <p className="responsedname">Any 1</p>
                        <p className="responseddateinfo">Reply : <span className="responsedate">Sep 9,2019</span></p>
                        <p className="repliedtext">  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div> */}

                {/* <hr className="dividerresponses" />

                <div className="responsesec">
                    <div className="repliedimg">
                        <img src={gitimg} className="gitimgalign" />
                    </div>
                    <div className="replieddetailsec">
                        <p className="responsedname">Any 1</p>
                        <p className="responseddateinfo">Reply : <span className="responsedate">Sep 9,2019</span></p>
                        <p className="repliedtext">  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div> */}

                {/* <hr className="dividerresponses" />

                <div className="responsesec">
                    <div className="repliedimg">
                        <img src={gitimg} className="gitimgalign" />
                    </div>
                    <div className="replieddetailsec">
                        <p className="responsedname">Any 1</p>
                        <p className="responseddateinfo">Reply : <span className="responsedate">Sep 9,2019</span></p>
                        <p className="repliedtext">  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div> */}

                {/* <hr className="dividerresponses" />

                <div className="responsesec">
                    <div className="repliedimg">
                        <img src={gitimg} className="gitimgalign" />
                    </div>
                    <div className="replieddetailsec">
                        <p className="responsedname">Any 1</p>
                        <p className="responseddateinfo">Reply : <span className="responsedate">Sep 9,2019</span></p>
                        <p className="repliedtext">  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div> */}


                <div className="commentsection hidden-xs">
                    <Form className="commentform">
                        <Form.Field>
                            <TextArea placeholder='Type.....' rows={2} onChange={(e) => this.saveReply(e)} />
                        </Form.Field>
                        <Button onClick={this.createReply} className="commentsendbtn"><img src={send} className="sendbtnimg"
                        />
                        </Button>
                    </Form>
                </div>


            </div>
        );
    }
}





const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch, state) {
    return {
        actions: bindActionCreators(actioncreators, dispatch)
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(FeedbackDetails)
);

// export default FeedbackDetails;