import React, { Component } from 'react';
import './Main.css';
import 'semantic-ui-css/semantic.min.css'
import { Grid, Image, Button, Container, Checkbox, Form, Icon, Input, Card } from 'semantic-ui-react'
// import { NavLink } from 'react-router-dom';
import edit from '../../Images/edit.png'
import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, NavLink } from "react-router-dom";

import {
    DateInput,
    TimeInput,
    DateTimeInput,
    DatesRangeInput
} from 'semantic-ui-calendar-react';

const options = [
    { key: 'a', text: 'Adult', value: 'adult' },
    { key: 'j', text: 'Juvenile', value: 'juvenile' },
]

const options2 = [
    { key: 'c', text: 'CC', value: 'cc' },
    { key: 'p', text: 'Prepaid Credit', value: 'prepaid Credit' },
    { key: 'v', text: 'Voucher', value: 'voucher' },
]
var moment = require('moment');
class Editprofile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            details: {},
            date: '',
            dob: '',
            time: '',
            dateTime: '',
            datesRange: '',
            nonmatchingPassword: false,
            show: false
        };
    }



    handleChange = (event, { name, value }) => {


        if (this.state.hasOwnProperty(name)) {
            console.log(event, { name, value })
            let x = { [name]: value }
            this.setState({ [name]: value });
            this.setState(
                prevState => ({
                    details: {
                        ...prevState.details,
                        ...x
                    }
                }),
                () => {
                    console.log(this.state.details);

                }
            );
        }

    }

    textInput = (e) => {
        console.log(e.target.name)
        console.log(e.target.value)
        let x = { [e.target.name]: e.target.value }
        this.setState(
            prevState => ({
                details: {
                    ...prevState.details,
                    ...x
                }
            }),
            () => {
                console.log(this.state.details);
            }
        );
    }
   
    
      componentDidMount() {
        console.log(this.props.userDetail)
    
        let x = {
            firstName: this.props.userDetail.firstName,
          email: this.props.userDetail.email,
          dob: this.props.userDetail.dob,
          password: this.props.userDetail.password,
          lastName: this.props.userDetail.lastName
        }
    
    
        this.setState({
          details: x
        }, () => { console.log(this.state.details) })
      }

    editUsers = (e) => {
        console.log(e)
        this.setState({ nonmatchingPassword: false })
        // this.setState(prevState => ({ show: !prevState.show }), () => { console.log(this.state.show) });
        // if (this.state.details.password !== this.state.details.confirmPassword) {

        //     this.setState({ nonmatchingPassword: true })
        // }

        // else {
            this.props.action.editUserProfile(this.props.userDetail._id,this.state.details, this.props.history)
            console.log("user created successfully>.......62", )
        // }
    }


    // onLoading = () => {
    //     // let sessionKey = sessionStorage.getItem("token")
    //     this.props.actions.editUserProfile(this.state.details, this.props.history)
    // }


    render() {
        return (
            <div className="editpagestart subpages">
                <div className="registerfrm">
                    <Container>
                        <div className="profile-heading">
                            <h2>Edit Profile</h2>
                        </div>
                        <div className="sub-inerfrm">


                            <div className="editprofilepage">

                                <Grid>
                                    <Grid.Column computer={8} mobile={16} className="ptb">


                                        <div className="profileinner">
                                            <div className="editprofileinput">
                                                <form action="">
                                                    <div class="field">
                                                        <input type="text" value={this.state.details.firstName} name={"firstName"} id="firstName" placeholder="first Name" onChange={this.textInput} />
                                                        <label for="name">First Name</label>
                                                    </div>
                                                </form>
                                            </div>


                                        </div>
                                    </Grid.Column>
                                    <Grid.Column computer={8} mobile={16} className="ptb">
                                        <div className="profileinner">
                                            <div className="editprofileinput">
                                                <form action="">
                                                    <div class="field">
                                                        <input type="text" value={this.state.details.lastName} name={"lastName"} id="lastName" placeholder="Last Name" onChange={this.textInput} />
                                                        <label for="last name">Last Name</label>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </Grid.Column>
                                    <Grid.Column computer={8} mobile={16} className="ptb">
                                        <div className="profileinner">
                                            <div className="editprofileinput">
                                                <form action="">
                                                    <div class="field datepickerfield">
                                                        <DateInput
                                                            name={"dob"}
                                                            type="text"
                                                            placeholder="date"
                                                            value={this.state.details.dob}
                                                            iconPosition="left"
                                                            onChange={this.handleChange}
                                                            id="dob"
                                                        />

                                                        <label for="date">Date Of Birth</label>
                                                        {/* <DateInput name={"dob"} onChange={this.changetext}
                                                            // defaultValue={this.state.details.dob ? new Date(moment(this.state.details.dob)) : null}
                                                            showTime={"false"} /> */}
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                    </Grid.Column>

                                    <Grid.Column computer={8} mobile={16} className="ptb">
                                        <div className="profileinner">
                                            <div className="editprofileinput">
                                                <form action="">
                                                    <div class="field">
                                                        <input type="text" value={this.state.details.email} name={"email"} id="email" placeholder="email" onChange={this.textInput} />
                                                        <label for="email">Email</label>
                                                    </div>
                                                </form>
                                            </div>


                                        </div>
                                    </Grid.Column>
                                    {/* <Grid.Column computer={8} mobile={16} className="ptb">
                                        <div className="profileinner">
                                            <div className="editprofileinput">
                                                <form action="">
                                                    <div class="field">
                                                        <input type="Password" name="password" id="password" placeholder="password" />
                                                        <label for="password">Password</label>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </Grid.Column> */}

                                </Grid>
                                <div className="savebtn">
                                    <NavLink to="/home/Profile">
                                        <Button onClick={this.editUsers} type='submit'>Save</Button>
                                    </NavLink>
                                </div>
                            </div>




                        </div>

                        {/* Reset Password start---------------- */}

                        <div className="resetpswrd">
                            <div className="profile-heading">
                                <h2>Reset Password</h2>
                            </div>
                            <div className="sub-inerfrm">
                                <div className="editprofilepage">
                                    <Grid>
                                        <Grid.Column computer={8} mobile={16} className="ptb">
                                            <div className="profileinner">
                                                <div className="editprofileinput">
                                                    <form action="">
                                                        <div class="field">
                                                            <input type="Password" name={"password"} value={this.state.details.password} id="old password" placeholder="old password"  onChange={this.textInput} />
                                                            <label for="old password">Old Password</label>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column computer={8} mobile={16} className="ptb">
                                            <div className="profileinner">
                                                <div className="editprofileinput">
                                                    <form action="">
                                                        <div class="field">
                                                            <input type="Password" name={"password"} id="new password" placeholder="new password"  onChange={this.textInput} />
                                                            <label for="new password">New Password</label>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column computer={8} mobile={16} className="ptb">
                                            <div className="profileinner">
                                                <div className="editprofileinput">
                                                    <form action="">
                                                        <div class="field">
                                                            <input type="Password" name={"confirm password"} id="confirm password" placeholder="confirm password" onChange={this.textInput} />
                                                            <label for="confirm password">Confirm Password</label>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column computer={8} mobile={16} className="ptb">
                                            <div className="savebtn confrmbtn">
                                                {/* <NavLink to="/home/Profile"> */}
                                                    <Button type='submit' onClick={this.editUsers}>Confirm</Button>
                                                    {/* </NavLink> */}
                                            </div>
                                        </Grid.Column>
                                    </Grid>

                                </div>

                            </div>
                        </div>
                        {/* Reset Password end---------------- */}
                    </Container>
                </div>

            </div>
        );
    }
}

// export default Editprofile;
const mapStateToProps = state => {
    return state;
}
function mapDispatchToProps(dispatch, state) {
    return {
        action: bindActionCreators(actioncreators, dispatch)
    }
}
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Editprofile)
);

