import React, { Component } from "react";
import "./SplashScreen.css";
import "semantic-ui-css/semantic.min.css";
import { Grid, Image, Button, Container, Form, Input } from "semantic-ui-react";
import logo from "../../Images/bluelogo.png";
import logoformobile from "../../Images/bluelogo.png";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import Slider from "react-slick";
import wifi from '../../Images/splashwifi.png';
import volt from '../../Images/110V-Ac-Power-&-USB-Outlets.png'
import Solar from '../../Images/solar.png'
import Speakers from '../../Images/speakers.png'
import tick from '../../Images/tick.svg';
import cafe from '../../Images/cafe_table.png'
import callbox from '../../Images/call_box.png';
import picnic from '../../Images/picnic.png'
import light from '../../Images/splashlight.png'

class SplashScreen extends Component {

    render() {
        var settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000
            
        };
        return (
            <div className='splashscreenstart'>
                <div className='headerslidermaindiv'>
                    {/* ---Header start----- */}
                    <div className='splashscreenheader'>
                        <div className='splashlogodiv'>
                            <NavLink to="/home">
                                <img src={logoformobile} className='landinglogoimg' />
                            </NavLink>
                        </div>
                       
                    </div>
                    {/* ---Header end----- */}
                    {/* ---Slider start---- */}
                    <div className='splashsliderdivstart'>
                        <Slider {...settings}>
                            <div className='splashsliderdiv'>
                                <img src={Solar} className='splassliderimg' />
                                <p className='splashslidertext'> Solar Power For Picnic Tables </p>
                            </div>
                            <div className='splashsliderdiv'>
                                <img src={volt} className='splassliderimg' />
                                <p className='splashslidertext'> 110V Ac Power & USB Outlets</p>
                            </div>
                            <div className='splashsliderdiv'>
                                <img src={wifi} className='splassliderimg' />
                                <p className='splashslidertext'> Wifi Internet Hotspot </p>
                            </div>
                            <div className='splashsliderdiv'>
                                <img src={Speakers} className='splassliderimg' />
                                <p className='splashslidertext'>Blutooth Controlled Speakers</p>
                            </div>
                            <div className='splashsliderdiv'>
                                <img src={light} className='splassliderimg' />
                                <p className='splashslidertext'>Automatic Security Lights</p>
                            </div>
                            <div className='splashsliderdiv'>
                                <img src={picnic} className='splassliderimg' />
                                <p className='splashslidertext'>Rugged Concrete Picnic</p>
                            </div>
                            <div className='splashsliderdiv'>
                                <img src={cafe} className='splassliderimg' />
                                <p className='splashslidertext'>Attractive Round Cafe Table</p>
                            </div>
                            <div className='splashsliderdiv'>
                                <img src={callbox} className='splassliderimg' />
                                <p className='splashslidertext'> 911 Call Box Capability</p>
                            </div>
                        </Slider>
                    </div>
                    {/* ---Slider end---- */}
                    {/* --Footer start-- */}
                    <div className="splashscreenfooter">
                        <NavLink to="/signin">
                            <Button type="submit" className='registrationbutton forloginsplash'>
                                Login Now
                        </Button>
                        </NavLink>
                        <NavLink to='/signup'>
                            <Button type="submit" className='registrationbutton forregspalsh'>
                                Create Account
                        </Button>
                        </NavLink>
                    </div>
                    {/* --Footer end-- */}
                </div>

            </div >
        );
    }
}


export default SplashScreen;
