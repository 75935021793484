import React, { Component } from 'react';
import '../Main/Main.css';
import './Admin.css';
import 'semantic-ui-css/semantic.min.css'
import { Grid, Image, Button, Radio, Container, Checkbox, Form, Icon, Input, Card, Modal, Select } from 'semantic-ui-react'
import { withRouter, NavLink } from 'react-router-dom';
import MaterialTable from 'material-table';
import tableicon from '../../Images/audit.png';
import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AddTableListForm from './AddTableListForm'


// const companyname = [
//     { key: "a", value: "a", text: "abc" },
//     { key: "b", value: "b", text: "abc" },
//     { key: "b", value: "b", text: "abc" }
// ];

class TableList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            modalOpen: false,
            editData: {},
            editmodalOpen: false,
            companyListData: [{ key: "b", value: "b", text: "Loading.." }],
            allCompany: [],
            onBtn:false
        }
    }
    handleOpen = () => {
        if (!this.props.storeselectedcompany.value && this.props.userDetail.role === "admin") {
            alert("Please choose a company first to create a table")
        }
        else {
            this.setState({ modalOpen: true })
        }
    }

    handleClose = () => this.setState({
        modalOpen: false,
        editData: {}
    })


    editTable = (rowData) => {
        this.setState({ editData: rowData }, () => {
            this.setState({ modalOpen: true });
            this.setState({ editmodalOpen: true })
        })
    }

    componentDidMount() {
        console.log(this.props.selectedTable, 'L55>>')

        if (this.props.userDetail.role === "admin") {
            // this.props.actions.getTableList()
            this.props.actions.getCompanyList()
            if (this.props.storeselectedcompany) {
                this.props.actions.findTableListByCompany(this.props.storeselectedcompany.value)
            }

        }
        else {
            if (this.props.userDetail.role === "companyAdmin") {

                const reqValues = {
                    method: "GET",
                    // body: JSON.stringify(tableData),
                    headers: {
                        "Content-Type": "application/json",
                        // authorization: localStorage.getItem("token")
                    }
                };

                fetch(process.env.REACT_APP_apiurl + "/company/getCompanybyUserId?id=" + this.props.userDetail._id, reqValues)
                    .then(result => result.json())
                    .then(result => {
                        console.log(result);

                        if (!result.error) {
                            this.props.actions.findTableListByCompany(result.result._id
                            )
                            // this.setState({companyid: result.result._id})
                            // companyobj.companyId=result.result._id

                            // console.log(companyobj, "L30")
                            // this.setState({ formData: companyobj }, () => { console.log(this.state.formData) })
                        }
                        console.log(this.state.companyid)
                    })
                    .catch(err => {
                        console.log(err);
                    });
                //   if  (this.props.storeselectedcompany){
                // console.log(this.props.userDetail.companyId)
                // this.props.actions.findTableListByCompany(this.props.userDetail.companyId)
                // }
            }
        }
        //>> add a refresh button
    }

    componentWillReceiveProps(newProps) {

        //call to get particalar comapany table
        if (!Object.is(newProps.storeselectedcompany, this.props.storeselectedcompany)) {
            this.props.actions.findTableListByCompany(newProps.storeselectedcompany.value)
        }


        if (!Object.is(newProps.tableList, this.props.tableList)) {

            // let showAllTables=[ { key: "all", value: "all", text: "All"} ]


            // let improvedList=[...newProps.tableList,showAllTables]

            // console.log(improvedList,"L88>>>")
            this.setState({ data: newProps.tableList }, () => {
                console.log("Recieving props>>>>>>>", newProps.tableList)
            })
        }

        if (!Object.is(newProps.companyList, this.props.companyList)) {

            console.log(newProps.companyList, "L70>>>>")
            var arr = newProps.companyList.map(x => {


                var obj = {};
                obj.key = x._id;
                obj.value = x._id;
                obj.text = x.companyName;
                return obj;
            })


            let showAllTables = [{ key: "all", value: "all", text: "All" }]


            let improvedList = [...showAllTables, ...arr]

            console.log(improvedList, "L88>>>")

            console.log(arr)
            this.setState({ companyListData: improvedList, allCompany: arr }, () => {
                console.log("companyListDatas>>>>>>>", this.state.companyListData)
            })
        }


    }


    handleChange = (e, data) => {
        console.log(data)
        console.log(data.value)
        console.log("order list company select>>>>" + data)

        if (data.value === "all") {

            this.props.actions.getTableList()

            this.props.actions.storeCompanySelectionData(
                {}
            );

        }
        else {
            var tempCompanyData = this.state.companyListData.find(x => {
                return x.value === data.value
            })

            this.props.actions.storeCompanySelectionData(tempCompanyData);
        }
    }




    setStatus = (x, e, name) => {
        console.log(e.target.value)
        console.log(name)
        console.log(x, "l267>>>>>>>>>>>>>")
        // let y={};
        // y = this.props.companyList;
        // y.enabled=this.props.companyList
        let string = "false"
        if (name.checked) {
            string = "true"
        }
        console.log(string)
        const reqValues = {
            method: "PUT",

            headers: {
                "Content-Type": "application/json",
                authorization: localStorage.getItem("token")
            }
        };
        fetch(process.env.REACT_APP_apiurl + "/table/updateTableStatus?id=" + x._id + "&data=" + string, reqValues)
            .then(result =>
                // console.log(result))
                result.json()
            )
            .then(result => {
                console.log(result);

                if (!result.error) {
                    this.props.actions.setTablesStatus(result.result)
                }

            })
            .catch(err => {
                console.log("Error", err);
            });
    };
    onClicked = () => {
        this.setState({onBtn:!this.state.onBtn})
    }




    render() {

        return (
            <div className="tableliststart">
                <div className="historytablestart">

                    <div className="profilepagestart">
                        {/* <div className="payment-heading">
                            <Grid>
                                <Grid.Column computer={8}>
                                    <h2 className="headingtxt"><img src={tableicon} />List of Tables</h2>
                                </Grid.Column>
                            </Grid>
                        </div> */}
                        {this.props.userDetail.role === "admin" &&
                            <Form.Field className="companyselect">
                                <label className="labelcompany">
                                    Select Company :
                                      </label>
                                <Select className="companyinputselect" placeholder="Select Company"
                                    onChange={(e, data) => this.handleChange(e, data)}
                                    options={this.state.companyListData}
                                    defaultValue={this.props.storeselectedcompany ? this.props.storeselectedcompany.value : null}
                                // })}
                                />


                                {/* </Select> */}
                            </Form.Field>
                        }
                        <div style={{ maxWidth: '100%' }} className="tablediv">

                            <Button onClick={this.handleOpen} className="adminvouchrbutn">
                                <Icon name="plus" />Add new</Button>
                            <Modal
                                open={this.state.modalOpen}
                                onClose={this.handleClose}
                            // trigger={} 
                            >
                                <Modal.Header className="modalheader">
                                    {this.state.editmodalOpen === true ?
                                        <span>Edit New Table</span> :
                                        <span>
                                            Add New Table </span>}<Icon onClick={this.handleClose} name="close" className="closeicon" />
                                </Modal.Header>
                                <Modal.Content className="addmodal-content">
                                    {this.state.modalOpen && <AddTableListForm editData={this.state.editData} onClose={this.handleClose} allCompany={this.state.allCompany} />}
                                </Modal.Content>
                            </Modal>
                            <MaterialTable
                                title="Table List"
                                columns={[
                                    {
                                        title: 'Table Name', field: 'tableNumber',
                                        render: rowData => {
                                            return (
                                                <div className="tablenamedivcss">
                                                    <p className="tablenumbertext">{rowData.tableNumber}</p>
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        title: "Table Description",
                                        field: 'shortDescription'
                                    },
                                    // { title: 'Site Number', field: 'sno' },
                                    {
                                        title: 'Location', field: 'location',
                                        render: rowData => {
                                            return (
                                                <div>
                                                    {
                                                        rowData.location && rowData.location.address
                                                    }
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        title: 'Battery Status', field: 'status',
                                        render: rowData => {
                                            return (
                                                <div>

                                                    <span>
                                                        {rowData.status === "Fully charged" ?
                                                            <span className="wifistatusonline">
                                                                {rowData.status}
                                                            </span>
                                                            :
                                                            rowData.status === "Discharged" ?

                                                                <span className="wifistatusoffline">{rowData.status}</span>
                                                                :
                                                                // rowData.status ==="good"&&

                                                                <span className="wifistatushalf"> {rowData.status}</span>
                                                            //  :
                                                            //     null

                                                        }
                                                    </span>
                                                    {/* <span className="wifistatusonline">Good</span> */}
                                                    {/* <span className="wifistatusoffline">Offline</span>
                                                    <span className="wifistatushalf">Partial</span> */}
                                                </div>
                                            );
                                        }
                                    },
                                    // {
                                    //     title: 'ip', field: 'network',
                                    //     render: rowData => {
                                    //         return (
                                    //             <div>
                                    //                 {
                                    //                     rowData.network && rowData.network.ip
                                    //                 }
                                    //             </div>
                                    //         );
                                    //     }
                                    // },
                                    // {
                                    //     title: 'Hostname', field: 'network',
                                    //     render: rowData => {
                                    //         return (
                                    //             <div>
                                    //                 {
                                    //                     rowData.network && rowData.network.hostName
                                    //                 }
                                    //             </div>
                                    //         );
                                    //     }
                                    // },

                                    {
                                        title: 'Enabled',
                                        field: 'enabled',
                                        render: x => {
                                            return (
                                                <div>
                                                    {/* <Radio toggle defaultChecked={rowData.status === "good" ? true : false} /> */}
                                                    <Radio toggle defaultChecked={x.enabled} onChange={(e, name) => this.setStatus(x, e, name)} />

                                                </div>
                                            )
                                        }
                                        // lookup: { 1: 'Activate', 2: 'Deactivate' },
                                    },
                                    // {
                                    //     title: 'Battery Status',
                                    //     field: 'b_status',

                                    // },
                                ]}
                                data={this.state.data}

                                // editable={{

                                //     onRowDelete: oldData =>
                                //         new Promise((resolve, reject) => {
                                //             // setTimeout(() => {
                                //             //     {
                                //             console.log(oldData)
                                //             this.props.actions.deleteTable(oldData._id)
                                //             // let data = this.state.data;
                                //             // const index = data.indexOf(oldData);
                                //             // data.splice(index);
                                //             // this.setState({ data }, () => resolve());
                                //             // }
                                //             resolve()
                                //         }, 1000)
                                // }}
                                actions={[
                                    // {
                                    //     icon: () => <Icon name='redo' />,
                                    //     tooltip: 'Port 1>60s ',
                                    //     onClick: (event, rowData) => { this.test1(rowData) }
                                    //     //   alert("You saved " + rowData.name)
                                    // },
                                    // {
                                    //     icon: () => <Icon name='play' />,
                                    //     tooltip: 'Port 2-7>start ',
                                    //     onClick: (event, rowData) => { this.start7(rowData) }
                                    //     //   alert("You saved " + rowData.name)
                                    // },
                                    // {
                                    //     icon: () => <Icon name='stop' />,
                                    //     tooltip: 'Port 2-7>stop ',
                                    //     onClick: (event, rowData) => { this.stop7(rowData) }
                                    //     //   alert("You saved " + rowData.name)
                                    // },
                                    {
                                        icon: () => <Icon name='edit outline' />,
                                        tooltip: 'Edit table data ',
                                        onClick: (event, rowData) => { this.editTable(rowData) }
                                        //   alert("You saved " + rowData.name)
                                    },
                                ]}
                                // onRowClick={(event, rowData, togglePanel) => togglePanel()}
                                options={{


                                    // selection: true,
                                    pageSize: 20,
                                    pageSizeOptions: [30, 40, 50],
                                    actionsColumnIndex: -1

                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// export default TableList;

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch, state) {
    return {
        actions: bindActionCreators(actioncreators, dispatch)
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TableList)
);
