
import React, { Component } from 'react';
import './Admin.css';
import 'semantic-ui-css/semantic.min.css'
import { Grid, Image, Button, Container, Checkbox, Form, Icon, Input, Card, Radio, GridColumn, TextArea } from 'semantic-ui-react'
// import { NavLink } from 'react-router-dom';
import {
    DateInput,
    TimeInput,
    DateTimeInput,
    DatesRangeInput
} from 'semantic-ui-calendar-react';
import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, NavLink } from "react-router-dom";
var moment = require("moment");
// import date from 'date-and-time';
// function onChange(date, dateString) {
//     console.log(date, dateString);
// }
const now = new Date();
class VoucherCreationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: '',
            time: '',
            dateTime: '',
            datesRange: '',
            details: {}
        };
    }


    // handleChange = (event, { name, value }) => {
    //     if (this.state.hasOwnProperty(name)) {
    //         this.setState({ [name]: value });
    //     }
    // }
    handletext = (event) => {
        // console.log(event.name)
        // console.log(event.value)
        console.log(event.target.name)
        console.log(event.target.value)
        let x = { [event.target.name]: moment(event.target.value).format("MM,DD,YYYY") }

        // let x = { [name]: event.moment(value).format("DD/MM/YY") }
        this.setState(
            prevState => ({
                details: {
                    ...prevState.details,
                    ...x,

                }
            }),
            () => {
                console.log(this.state.details);

            }
        );

    }

    // [name]: moment(value).format("DD/MM/YY") });
    textsInput = (e) => {
        console.log(e.target.name)
        console.log(e.target.value)
        let x = { [e.target.name]: e.target.value }
        this.setState(
            prevState => ({
                details: {
                    ...prevState.details,
                    ...x
                }
            }),
            () => {
                console.log(this.state.details);

            }
        );
    }


    textInput = (e) => {
        console.log(e.target.name)
        console.log(typeof e.target.value, e.target.type)
        let value= e.target.value
        if(e.target.type=='number'){
            value= parseInt(value)
        }
        let x = { [e.target.name]: value }
        this.setState(
            prevState => ({
                details: {
                    ...prevState.details,
                    ...x,

                }
            }),
            () => {
                console.log(this.state.details);

            }
        );
    }
    createVouchers = (e) => {
        console.log(e)
        // console.log(this.onChange)
        // console.log(this.onChange)
        // this.setState({ nonmatchingPassword: false })

        this.props.actions.createVoucher(this.state.details)

        console.log("voucher created successfully>.......62", )
        // }
    }

    componentWillReceiveProps(newProps) {

        if (newProps.voucherList.length> this.props.voucherList.length) {
         this.props.onClose()
        }
    
    
      }


    render() {

        return (
            <div className="tableliststart adminvoucherform">
                {/* <div className="historytablestart">
                    <div className="profilepagestart"> */}
                {/* <div className="payment-heading">
                            <div className="payment-heading">
                            <h2 className="headingtxt">Voucher Creation</h2>
                            </div>
                        </div> */}
                <div style={{ maxWidth: '100%' }} className="tablediv voucherform">
                    <div className="formfieldarea">

                        <Form className="formfield emailfield">
                            <Grid>
                                <Grid.Column computer={8} className="border-remove">
                                    <Form.Field>
                                        <label className="labelnme">Voucher identifier code</label>
                                        <Input iconPosition='left' type="text" name="identifierId" onChange={this.textsInput} />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column computer={8} className="border-remove">
                                    <Form.Field className="datepickerfield">
                                        <label className="labelnme">Validity of The Voucher</label>
                                        {/* <DateInput
                                            name="vouchervalidity"
                                            placeholder="Date"
                                            value={this.state.date}
                                            iconPosition="left"
                                            onChange={this.handleChange}
                                        /> */}
                                        <Input iconPosition='left' type="date" name="expiry" onChange={this.handletext} />
                                        {/* parse={value => Number(value)}
                                        name="baseValue"
                                        label="Default value" */}
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column computer={8} className="border-remove">
                                    <Form.Field>
                                        <label className="labelnme">Value of Voucher</label>
                                        <Input iconPosition='left' type='number' name="value" onChange={this.textInput} />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column computer={8} className="border-remove">
                                    <Form.Field>
                                        <label className="labelnme">No of Voucher</label>
                                        <Input iconPosition='left' type="number" name="numberOfVoucher" onChange={this.textInput} />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid>
                            {/* <Grid>
                            <Grid.Column computer={16}>
                            <Form.Field className="deatils-textarea">
                                <label className="labelnme">Details</label>
                                <TextArea name="deatils" type="text" />
                            </Form.Field>
                            </Grid.Column>
                            </Grid> */}

                            <div className="formbutn addeditmodal-formbutn"><Button type='submit' onClick={this.createVouchers} >Submit</Button></div>
                        </Form>

                    </div>
                </div>
                {/* </div>
                </div> */}
            </div>
        );
    }
}

// export default VoucherCreationForm;
const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch, state) {
    return {
        actions: bindActionCreators(actioncreators, dispatch)
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(VoucherCreationForm)
);