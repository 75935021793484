import React, { Component } from "react";
import "./Main.css";
import { Grid, Image, Button, Container } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { Tab } from "semantic-ui-react";
import phonepe from "../../Images/paypal.png";
import wifi from "../../Images/wifistatus.png";
import plug from "../../Images/plugcolor.png";
import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

const moment = require("moment");

class History extends Component {
  componentDidMount() {
    this.props.actions.getOrderHistory(5); // 5> Number of orders
this.props.actions.getVoucherByUser();

  }

  selectedImg = name => {
    var selectedObj = this.props.servicesList.find(x => {
      return x.name.toLowerCase() === name.toLowerCase();
    });

    console.log(selectedObj.selected, "l98>>>>>>>>>>");
    return selectedObj.small;
  };

  render() {
    const panes = [
      {
        menuItem: "Services",
        render: () => (
          <Tab.Pane attached={false}>
            {this.props.ordersList.map(x => {
              console.log(x, "<>>>>>26");
              return (
                <div className="serviceusage">
                  <div>
                    <Grid>
                      <Grid.Column computer={8} mobile={8} tablet={10}>
                        <p className="servusnghedng">Service Usage</p>
                      </Grid.Column>
                      <Grid.Column computer={8} mobile={8} tablet={10}>
                        <p className="datetme">
                          <span className="usedate">
                            {moment(x.createdDate).format("MM/DD/YYYY")}
                          </span>
                          <span className="usetime">
                            {moment(x.createdDate).format("hh:mm a")}
                          </span>
                        </p>
                      </Grid.Column>
                    </Grid>
                  </div>
                  <div className="innerdetailscard">
                    {/* <div className="servceusage">
                      <Grid>
                        <Grid.Column computer={5} mobile={5} tablet={10}>
                          <ul className="srvcicntxt">
                            <li>
                              {" "}
                              <img src={wifi} className="serviceicon" />
                            </li>
                            <li className="wifitxt">
                              <p className="credit_value sapcewifipowre">
                                {" "}
                                Wifi
                              </p>
                            </li>
                          </ul>
                        </Grid.Column>
                        <Grid.Column computer={8} mobile={7} tablet={10}>
                          <div className="wifipowrndvalue">
                            <p className="duration">Duration: 20.00 Min</p>
                          </div>
                        </Grid.Column>
                        <Grid.Column computer={3} mobile={4} tablet={10}>
                          <div className="wifipowrndvalue">
                            <p className="moneyvalue">$20.00</p>
                          </div>
                        </Grid.Column>
                      </Grid>
                    </div> */}

                    {x.services.map(z => {
                      return (
                        <div className="servceusage">
                          <Grid>
                            <Grid.Column computer={5} mobile={5} tablet={10}>
                              <ul className="srvcicntxt">
                                <li>
                                  {" "}
                                  <img src={this.selectedImg(z.serviceName)} className="serviceicon" />
                                </li>
                                <li className="wifitxt">
                                  <p className="credit_value sapcewifipowre">
                                    {" "}
                                    {z.serviceName}
                                  </p>
                                </li>
                              </ul>
                            </Grid.Column>
                            <Grid.Column computer={8} mobile={7} tablet={10}>
                              <div className="wifipowrndvalue">
                                <p className="duration">Duration: {z.duration / 60} Min</p>
                              </div>
                            </Grid.Column>
                            <Grid.Column computer={3} mobile={4} tablet={10}>
                              <div className="wifipowrndvalue">
                                <p className="moneyvalue">${(z.cost).toFixed(2)}</p>
                              </div>
                            </Grid.Column>
                          </Grid>
                        </div>
                      );
                    })}

                    <div className="servceusage totalcostsection">
                      <Grid>
                        <Grid.Column computer={5} mobile={5} tablet={10} />
                        <Grid.Column computer={8} mobile={7} tablet={10}>
                          <div className="wifipowrndvalue">
                            <p className="duration">TOTAL</p>
                          </div>
                        </Grid.Column>
                        <Grid.Column computer={3} mobile={4} tablet={10}>
                          <div className="wifipowrndvalue">
                            <p className="moneyvalue totalcost">${(x.totalCost).toFixed(2)}</p>
                          </div>
                        </Grid.Column>
                      </Grid>
                    </div>
                    <div className="usageinfo">
                      <p>
                        Location :{" "}
                        <span>{x.tableId.location.address}</span>
                      </p>
                      <p>
                        Table No : <span>{x.tableId.tableNumber}</span>
                      </p>
                      <p>
                        Status : <span>{x.status}</span>
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </Tab.Pane>
        )
      },

      {
        menuItem: "Payment",
        render: () => (
          <Tab.Pane attached={false}>
            <div className="historypayment">
              <div className="historyinner">
                <div className="historyimg">
                  <img src={phonepe} className="hpaymenticon" />
                </div>
                <div className="hpaymenttext paytext">
                  <p className="historyinnertext">
                    Payment Credited{" "}
                    <span className="hservicetdate">13 aug 2019</span>
                  </p>
                </div>
              </div>
              <div className="historyamount">
                <p className="paymentamount"> $30 </p>
              </div>
            </div>
          </Tab.Pane>
        )
      },
      {
        menuItem: "Credit",
        render: () => (
          <Tab.Pane attached={false}>
           {this.props.voucherList.map(x => {
              // console.log(x, "<>>>>>26");
              return (
              <div className="historypayment">
                <div className="historyinner">
                  <div className="hpaymenttext paytext">
                    <p className="historyinnertext">
                      Voucher Details
                     <p className="benefits">
                        <b>Redeem Id :</b> {x.redeemId}
                      </p>
                      <p className="benefits">
                        <b> Expires on :</b>{moment(x.expiry).format("MM/DD/YYYY")}
                      </p>
                    </p>
                  </div>
                </div>
              </div>
           )})}
          </Tab.Pane>
        )
      }

    ];

    return (
      <div className="historydiv subpages">
        <div className="servcetabarea">
          <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
        </div>
      </div>
    );
  }
}

// export default History;

const mapStateToProps = state => {
  return state;
};

function mapDispatchToProps(dispatch, state) {
  return {
    actions: bindActionCreators(actioncreators, dispatch)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(History)
);
