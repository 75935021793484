import React, { Component } from 'react';
import './Main.css';
import 'semantic-ui-css/semantic.min.css'
import { Grid, Image, Button, Container, Checkbox, Form, Icon, Input, Card } from 'semantic-ui-react'
// import { NavLink } from 'react-router-dom';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
// import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import location from '../../Images/location.jpg';
import global from "../../Images/language.png";

import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, NavLink } from "react-router-dom";

const mapStyles = {
    width: '100%',
    height: '100%',
};
class mapPointer extends Component {
    constructor(props) {
        super(props);
        this.onMarkerClick = this.onMarkerClick.bind(this);
        this.state = {
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            tables:[],
            selectedTable:{}
        };
    }

    // onMarkerClick(props, marker, e) {
        onMarkerClick(props,x) {

            console.log(x)
        this.setState({
            selectedTable: x.data,
            activeMarker: x,
            showingInfoWindow: !this.state.showingInfoWindow
        })
    }

    onClose = props => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                 activeMarker: null
            });
        }
    };


    componentDidMount() {
        this.props.actions.getTableList();   //>> add a refresh button
    }


    componentWillReceiveProps(newProps) {

        if (!Object.is(newProps.tableList, this.props.tableList)) {
            this.setState({ tables: newProps.tableList }, () => {
                console.log("Recieving props>>>>>>>", newProps.tableList)
            })
        }


    }

    render() {
        if (!this.props.google) {
            return <div>Loading...</div>;
        }
        return (
            <div className="mapdisplay">
                <Map
                    google={this.props.google}
                    zoom={14}
                    style={mapStyles}
                    initialCenter={{
                        lat: 36.7783,
                        lng: -119.4179
                    }}>
                       {this.state.tables.map(x=>{
                           return(
                            //    <div>
                    <Marker
                       key={x._id}
                       data={x}
                        onClick={(props,marker)=>this.onMarkerClick(props,marker)}
                        name={x.tableNumber}
                        position={{ lat: x.location && x.location.coOrdinates&&x.location.coOrdinates.latitude, lng:x.location &&x.location.coOrdinates&& x.location.coOrdinates.longitude }}
                    />
                           )
                        })}
                     <InfoWindow
                        marker={this.state.activeMarker}
                        visible={this.state.showingInfoWindow}
                        onClose={this.onClose}
                    >
                        <div className="infowindowcontent">
                            <img src={this.state.selectedTable.imageId?process.env.REACT_APP_apiurl+"/getimages/"+this.state.selectedTable.imageId:location} alt="table" className="placeviewimg" />
                            <div className="mapinfodetail">
                                <div className="mapmarkerdetails">
                                    <p className="placenametext">{this.state.selectedTable.tableNumber}</p>
                                    <p className="placelocationtype"><img src={global} alt="table"className="globalicon" />Public</p>
                                   {this.state.selectedTable.services&&this.state.selectedTable.services.map(serv=>{
                                  return(
                                   <p className="wglobalifiavailability mb0">{serv.name} :
                                <span className="wifiplacestatus">{serv.currentStatus?serv.currentStatus:"unavailable-data"}</span></p>
                                    ) })
                                   }
                                {/* <p className="wglobalifiavailability">Charger :
                                <span className="wifiplacestatus">Good</span></p> */}
                                </div>
                                {/* <div className="markerswitchdetails">
                                    <p className="available">2</p>
                                    <p className="inuse">1</p>
                                    <p className="notfunctioning">1</p>
                                </div> */}
                                <div className="tableswitchdetails">
                                    <div className="tableswitch">
                                        {this.state.selectedTable.status&&this.state.selectedTable.status==="good"?
                                        <div className="individualswitch">
                                            <div className="switchview availableswitch"></div>
                                            <span className="numberswitch">2</span> Available</div>
                                 :
                                        <div className="individualswitch">
                                            <div className="switchview inuseswitch"></div>
                                            <span className="numberswitch">1</span> Not Functioning</div>
                                        }
                                        {/* <div className="individualswitch">
                                            <div className="switchview notfuctioningswitch"></div>
                                            <span className="numberswitch">1</span> In Use</div> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </InfoWindow>
                         
                </Map>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch, state) {
    return {
        actions: bindActionCreators(actioncreators, dispatch)
    };
}

const exp = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(mapPointer)
);


export default GoogleApiWrapper({
    // apiKey: 'YOUR_GOOGLE_API_KEY_GOES_HERE'
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY
})(exp);