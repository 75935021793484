import React, { Component } from 'react';
import './Home.css';
import 'semantic-ui-css/semantic.min.css';
import { Grid, Image, Button, Dropdown, Menu } from 'semantic-ui-react'
import { NavLink,withRouter } from 'react-router-dom';
import bluelogo from '../../Images/bluelogo.png';
import menubar from '../../Images/menu.png'
import user from '../../Images/user.png'
import location from '../../Images/selectsettings/Group25.png';
import History from '../../Images/selectsettings/Group24.png';
import Home from '../../Images/selectsettings/Group26.png';
import hme00 from '../../Images/hme00.png'
import homeactve from '../../Images/homeactve.png'
import locatnactve from '../../Images/locatnactve.png'
import histryactve from '../../Images/histryactve.png'
import history01 from '../../Images/history01.png'
import location01 from '../../Images/location01.png'
import home01 from '../../Images/home01.png'
import ConfirmationModal from '../Modal/ConfirmationModal'
import question from '../../Images/question.png';

import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import { withRouter, NavLink } from "react-router-dom";
import voucheractve from '../../Images/voucheractve.png'
import voucher from '../../Images/voucher.png'
import profileactive from '../../Images/profileactive.png';
import profiledeactive from '../../Images/profiledeactive.png'



class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTop: true,
      open: false,
      activeclass: true,
      activeclass2: false,
      activeclass3: false,
      activeclass4: false,
      // user: props.userDetails
        
    
    }
  }



  activeclassselect =()=>{
    this.setState({ activeclass :true });
    this.setState({ activeclass2 :false });
    this.setState({ activeclass3 :false });
    this.setState({ activeclass4 :false });
  }
  activeclassselect2 =()=>{
    this.setState({ activeclass :false });
    this.setState({ activeclass2 :true });
    this.setState({ activeclass3 :false });
    this.setState({ activeclass4 :false });
  }
  activeclassselect3 =()=>{
    this.setState({ activeclass :false });
    this.setState({ activeclass2 :false });
    this.setState({ activeclass3 :true });
    this.setState({ activeclass4 :false });
  }
  activeclassselect4 =()=>{
    this.setState({ activeclass :false });
    this.setState({ activeclass2 :false });
    this.setState({ activeclass3 :false });
    this.setState({ activeclass4 :true });
  }


  menuOpen = () => {
    this.setState({ open: !this.state.open });
  }
  componentDidMount() {
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 100;
      if (isTop !== this.state.isTop) {
        this.setState({ isTop })
      }
    });
  }
// showProfile=()=> {
//   this.props.userDetails.firstname,+" "+ this.props.userDetails.lastname
// }

logout=()=>{
  localStorage.clear();
  sessionStorage.clear();
  console.log("Logging ouT!!!!!!!!!!!!")
  this.props.history.push("/")
}


  render() {
    const { user } = this.state

    const text1 = [
      <div>
        <NavLink to='/home/Profile'>
          <p>Profile</p>
        </NavLink>
      </div>
    ]
    const text2 = [
      <div onClick={this.logout}>
        {/* <div onClick={this.logoutModal}>
          <p>Logout</p>
        </div>
        {this.state.modalopen === true ?
       <ConfirmationModal ConfirmationModal="logoutmodal" modalcontnt="Do you really want to Sign Out from your account?"
       btntxt="Stay Connected"  contentclass="logoutmodaltxt" modalheadr="Sign Out" modalbutton="modalbutnarea"
       btntxt1="Yes, Logout" functn={this.openlogoutpage} modalshowtext="Log Out"
       modalcontntimg={question} modalhederclass="logoutmodalheader"
       modalbutnclass="cancelbutn" modalbutnclass1="yesbutn" modalshwbutncls="logout "/>
        :null}
         */}

        {/* <NavLink to='/signin'> */}
          <p>Logout</p>
        {/* </NavLink> */}
      </div>

    ]
    const options = [
      { key: 1, text: text1, value: 1 },
      { key: 2, text: text2, value: 2 },
    ]

    // let backgroudcolor = this.state.isTop ? '#00000066' : 'white';
    let backgroudcolor = this.state.isTop ? 'white' : 'white';
    // let color = this.state.isTop ? '#e4e4e4' : 'black';
    let color = this.state.isTop ? 'black' : 'black';
    let boxshadow = this.state.isTop ? 'rgba(0, 0, 0, 0.13) 0px 5px 15px 0px' : 'rgba(0, 0, 0, 0.13) 0px 5px 15px 0px';
    // let bordercolor = this.state.isTop ? 'white' : 'black';
    let bordercolor = this.state.isTop ? 'black' : 'black';

    let fontcolor = this.state.activeclass === true ? '#0852b7' : '#555555';
    let fontcolor2 = this.state.activeclass2 === true ? '#0852b7 ' : '#555555';
    let fontcolor3 = this.state.activeclass3 === true ? '#0852b7 ' : ' #555555';
    let fontcolor4 = this.state.activeclass4 === true ? '#0852b7 ' : ' #555555';
    return (


      <div className="headerstart" style={{ /*top: '0',*/ color: color, backgroundColor: backgroudcolor, boxShadow: boxshadow }}>
        <Grid>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <div className="headerlogo ">
              <NavLink to="/home/info"> <img src={bluelogo} className="headerlogoimg hidden-xs" /></NavLink>

              <div className="hidden-md bottomheader">
                <NavLink to="/Home/info">
                  <div className="bottommenu" onClick={this.activeclassselect}>
                  {this.state.activeclass === true ?
                    <img src={ homeactve} className="actvicn"/>:
                    <img src={ home01}  className="actvicn"/>}
                    <p style={{color:fontcolor}}>Home</p>
                  </div>
                </NavLink>
                <NavLink to="/home/location" >
                  <div className="bottommenu" onClick={this.activeclassselect2}>
                  {this.state.activeclass2 === true ?
                    <img src={locatnactve}  className="actvicn"/>:
                    <img src={ location01} className="actvicn"/>}
                    <p style={{color:fontcolor2}}>Location</p>
                  </div>
                </NavLink>

                {/* <NavLink to="/home/voucher">
                <div className="bottommenu" onClick={this.activeclassselect4}>
                  {this.state.activeclass4 === true ?
                  <img src={voucheractve} className="actvicn"/>:
                  <img src={voucher}  className="actvicn"/>
                }
                  <p style={{color:fontcolor4}}>Voucher</p>
                </div>
                </NavLink> */}
               

                <NavLink to="/home/history">
                <div className="bottommenu" onClick={this.activeclassselect3}>
                  {this.state.activeclass3 === true ?
                  <img src={histryactve} className="actvicn"/>:
                  <img src={history01}  className="actvicn"/>
                }
                  <p style={{color:fontcolor3}}>History</p>
                </div>
                </NavLink>
                {/* <NavLink to="/home/profile">
                <div className="bottommenu" onClick={this.activeclassselect4}>
                  {this.state.activeclass4 === true ?
                  <img src={profileactive} className="actvicn"/>:
                  <img src={profiledeactive}  className="actvicn"/>
                }
                  <p style={{color:fontcolor4}}>Profile</p>
                </div>
                </NavLink> */}
                
              </div>


            </div>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={12} className="hidden-xs headerxs">
            <div className="navitems hidden-xs">
              <ul className="navullist" style={{ color: color }}>
                <NavLink to="/home/info">
                  <li className="homenav">Home</li>
                </NavLink>
                <NavLink to="/home/location">
                  <li className="locationnav">Location</li>
                </NavLink>
                {/* <NavLink to="/home/scanner">
                  <li className="locationnav">QR Scanner</li>
                </NavLink> */}
                <NavLink to="/home/history">
                  <li className="homenav">History</li>
                </NavLink>
                <NavLink to="/home/voucher">
                  <li className="homenav">Voucher</li>
                </NavLink>
                {/* <NavLink to="/home/feedback">
                <div className="menufeedback">
                <img src={feedback} className="usericn feedbackimg" />
                  <li className="homenav">Give Feedback</li>
                  </div>
                </NavLink> */}
                {/* <li className="navbtn" > <NavLink to="/signin">
                  <Button className="signinbtn" style={{ color: color, borderColor: bordercolor }}>
                    Sign in
                    </Button>  </NavLink>
                </li>
                <li className="navbtn" ><NavLink to="/signup">
                  <Button className="signinbtn" style={{ color: color, borderColor: bordercolor }}>
                    Sign up
                    </Button></NavLink>
                </li> */}
                <li className="navbtn dropdwnbutnarea ">
                  <Menu compact>
                    <div className="drpdwn-inrarea">
                      <div className="usrimgarea">
                        <img src={user} className="usericn" />
                      </div>
                      <Dropdown text={this.props.userDetail&&(this.props.userDetail.firstName+" "+this.props.userDetail.lastName) } options={options} simple item className="dropdwnbutn" />
                    </div>
                  </Menu>
                </li>
              </ul>
            </div>
          </Grid.Column>

        </Grid>
      </div>
    );
  }
}

// export default withRouter(Header);
const mapStateToProps=  state=>{
  return state;
}
function mapDispatchToProps (dispatch,state) {
  return {
    action:  bindActionCreators(actioncreators, dispatch)
  }
}
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header)
);
