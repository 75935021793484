import React, { Component } from "react";
import "./Main.css";
import MobileHeader from "../Home/MobileHeader";
import Header from "../Home/Header";
// import Slider from "react-slick";
// import "slick-carouqueryIdsel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import slider1 from "../../Images/slider1.jpg";
// import slider3 from "../../Images/slider3.jpg";
// import slider4 from "../../Images/slider4.jpg";
// import slider5 from '../../Images/slider05.png';
import slider6 from "../../Images/slider06.jpg";
import slider7 from "../../Images/slider07.jpg";
import dolar from "../../Images/dolar.png";
import attach from "../../Images/attach.png";
import vouchericn01 from "../../Images/vouchericn01.png";
import vouchericn02 from "../../Images/vouchericn02.png";
import { Grid, Button, Container } from "semantic-ui-react";
import usages from "../../Images/usages.png";
import wifi from "../../Images/wifistatus.png";
import plug from "../../Images/plugcolor.png";
import Solarimage from "../../Images/Solarimage.jpg";
import Shadecharger from "../../Images/Shadecharger.png";
import { NavLink, withRouter } from "react-router-dom";
import * as actioncreators from "../../redux/action";
import OtpInput from "react-otp-input";
import creditcard from '../../Images/credit-card.svg';
import price from '../../Images/price.svg';
import buyvoucher from '../../Images/voucherimage.png';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const moment = require("moment");
class Summary extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      value: '',
    };
  }
  onChange = otp => this.setState({ value: otp },()=>{console.log(this.state.value)});
  gotovoucherpage = () => {
    this.props.history.push({
      pathname: "/home/voucher",
      from: "summary"
    });
  };

  componentDidMount() {
    if (localStorage.getItem("token") === null) {
      this.props.history.push("/signin");
    }

    sessionStorage.setItem('component', 'summary');
    console.log(this.props);
    this.props.actions.getLoggedinUserDetails(this.props.history)
    this.props.actions.getOrderHistory(3);
  }
  goToCreditPage = () => {
    this.props.history.push('/home/addcredit')
  }



  tableIdSubmitted=()=>{

   let queryId = this.state.value;

    const reqValues = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    };

    fetch(
      process.env.REACT_APP_apiurl + "/table/find?id=" + queryId.toString(),
      reqValues
    )
      .then(result => result.json())
      .then(result => {
        console.log(result);

        if (!result.error) {
          //push to signin, keep tableid
          sessionStorage.setItem("tableId", queryId);

          if (localStorage.getItem("token") === null) {
            this.props.history.push({
              pathname: "/signin",
              from: "Home"
            });
          }
          else{

            //Redirect here
            
            this.props.actions.saveTableData(result.result, this.props.history);

            // this.props.actions.resumeSession(
            //   localStorage.getItem("token"),
            //   this.props.history,
            //   queryId
            // );
          }
        } else if (result.error) {
          alert("No table with this id: " + queryId);
          // this.props.history.push("/signin");
        }
      })
      .catch(err => {
        console.log(err);
        alert(
          "Error while searching for table with this id: " +
            queryId +
            " Err: " +
            err
        );
        // this.props.history.push("/signin");
      });
  }

  render() {
    const settings = {
      className: "slider variable-width",
      dots: true,
      infinite: true,
      centerMode: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true
    };

    return (
      <div className="summary">
        <Header />
        <MobileHeader />
        {/* <Container> */}
        <div className="inner-summary subpages">
          <div className="sliderarea">
            {/* <Slider {...settings}>
                                <div className="firstslide">
                                    <img src={slider6} className="sumrysliderimg" />
                                </div>
                                <div className="firstslide" >
                                    <img src={slider7} className="sumrysliderimg" />
                                </div>
                            </Slider> */}
            {/* <img src={Solarimage} className="sumrysliderimg" /> */}
            <div>
              <p className='summaryheadingcls'>Enter Table ID</p>
              <p className='sumamrysubheadingtext'>Table id you want to connect with to enjoy available facilities</p>
              <OtpInput
                containerStyle='otpcontainer'
                inputStyle='otpinputcls'
                value={this.state.value}
                onChange={(otp) => this.onChange(otp)}
                numInputs={6}
                isInputNum={false}
              />
              <div className='submitotpbtndiv'>
                <Button onClick={this.tableIdSubmitted}
                className="submitotpbtn">Submit to proceed</Button>
              </div>
            </div>
          </div>
          {/* <hr/> */}
          <ul className="creditblcsec">
            <div className='summarybtncls'>
              <li className="creditblncli">
                <p className="crdtblctxt">
                  <img src={price} className="dolaricn" />
                  {/* <span className="balenceimg"> */}
                  Credit Balance {/* </span>*/}
                </p>
              </li>
              <li>
                <p className="balence">${this.props.userDetail.credit && this.props.userDetail.credit.toFixed(2)}</p>
              </li>
            </div>
            <Button className="addcreditbalancepage" onClick={this.goToCreditPage}>
              <img src={creditcard} className="creditimg" /> Add to Credit
                </Button>
          </ul>
          <div className='buyvoucherimgdiv' onClick={this.gotovoucherpage}>
            <img src={buyvoucher} className='buyvoucherimgcls' />
          </div>
          {/* <div className='buynowsection'>
            <p className='buyvoucherheading'>Buy Voucher</p>
            <p className='buyvouchertxt'>Table id you want to connect with to enjoy available facilities</p>
            <Button className="addcreditbalancepage buyvoucherbtn" onClick={this.gotovoucherpage}>
              Buy Voucher
            </Button>
          </div> */}




          {/* <div className="usefullnk">
            <p className="crdtblctxt">
              {" "}
              <img src={attach} className="dolaricn attachicn" />
              Useful Link
            </p>
            <Grid>
              <Grid.Column computer={8} mobile={8} className="decreasespce">
                <div className="usngvouchrcode" onClick={this.gotovoucherpage}>
                  <img src={vouchericn02} className="voucherimg" />
                  <p className="usngvouchr-txt">Using Voucher</p>
                  <Button className="vouchrbutn">Add Credit</Button>
                </div>
              </Grid.Column>
              <Grid.Column computer={8} mobile={8} className="decreasespce">
                <div
                  className="usngvouchrcode vouchercode2"
                  onClick={this.gotovoucherpage}
                >
                  <img src={vouchericn01} className="voucherimg" />
                  <p className="usngvouchr-txt">Voucher</p>
                  <Button className="vouchrbutn">Buy Now</Button>
                </div>
              </Grid.Column>
            </Grid>
          </div> */}

          {/* <div className="lastusagessec">
            <p className="crdtblctxt">
              <img src={usages} className="dolaricn uaagesicn " />
            
              Last Service Usages{" "}
            </p>
          </div> */}
          {/* {this.props.ordersList.map(x => {
            return (
              <div className="backwhite">
                <ul className="vouchrservcusageshedng">
                  <li>
                    <p className="vouchrsrvctxt">Service Usages</p>
                  </li>
                  <li>
                    <p className="balence vouchr-datetme">
                      {" "}
                      {moment(x.createdDate).format("MM/DD/YYYY")}
                      <span className="vouchrtme">
                        {" "}
                        {moment(x.createdDate).format("hh:mm a")}
                      </span>
                    </p>
                  </li>
                </ul>

                {x.services.map(z => {
                  return (
                    <div className="servceusage vouchrservce">
                      <Grid>
                        <Grid.Column computer={5} mobile={5} tablet={10}>
                          <ul className="srvcicntxt">
                            <li>
                              {" "}
                              <img src={wifi} className="serviceicon" />
                            </li>
                            <li className="wifitxt">
                              <p className="credit_value sapcewifipowre">
                                {" "}
                                {z.serviceName}
                              </p>
                            </li>
                          </ul>
                        </Grid.Column>
                        <Grid.Column computer={8} mobile={7} tablet={10}>
                          <div className="wifipowrndvalue">
                            <p className="duration">
                              Duration: {z.duration / 60} Min
                            </p>
                          </div>
                        </Grid.Column>
                        <Grid.Column computer={3} mobile={4} tablet={10}>
                          <div className="wifipowrndvalue">
                            <p
                              className="moneyvalue"
                              style={{ textAlign: "right" }}
                            >
                              ${z.cost}
                            </p>
                          </div>
                        </Grid.Column>
                      </Grid>
                    </div>
                  );
                })}

                <div className="servceusage totalcostsection vouchrcostsec">
                  <Grid>
                    <Grid.Column computer={5} mobile={5} tablet={10} />
                    <Grid.Column computer={8} mobile={7} tablet={10}>
                      <div className="wifipowrndvalue">
                        <p className="duration">TOTAL</p>
                      </div>
                    </Grid.Column>
                    <Grid.Column computer={3} mobile={4} tablet={10}>
                      <div className="wifipowrndvalue">
                        <p className="moneyvalue vouchrtotlcost">${x.totalCost}</p>
                      </div>
                    </Grid.Column>
                  </Grid>
                </div>
                <div className="usageinfo voucherinfo">
                  <p>
                    Location : <span>{x.tableId.location.address}</span>
                  </p>
                  <p>
                    Table No : <span>{x.tableId.tableNumber}</span>
                  </p>
                  <p>
                    Status : <span>{x.status}</span>
                  </p>
                </div>
              </div>
            );
          })} */}
        </div>
      </div>
    );
  }
}

// export default withRouter( Summary);

const mapStateToProps = state => {
  return state;
};

function mapDispatchToProps(dispatch, state) {
  return {
    actions: bindActionCreators(actioncreators, dispatch)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Summary)
);
