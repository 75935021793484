import React, { Component } from "react";
import "./Home.css";
import "semantic-ui-css/semantic.min.css";
import Header from "./Header";
import Carousel from "./Carousel";
import Service from "./Service";
import Work from "./Work";
import Place from "./Place";
import Footer from "./Footer";
import { Grid, Image, Button, Container } from "semantic-ui-react";
import Copyrightfooter from "./Copyrightfooter";
import HomePage from "./HomePage";
import mapPointer from "../Main/mapPointer";
import { Route, Switch, withRouter,NavLink} from "react-router-dom";
import Profile from "../Main/Profile";
import Payment from "../Main/Payment";
import SelectSettings from "../Main/SelectSettings";
// import SelectServiceDrawer from '../Main/SelectServiceDrawer';
import HistoryTable from "../Main/HistoryTable";
import Editprofile from "../Main/Editprofile";
import SelectServiceDrawer from "../Main/SelectServiceDrawer";
import MobileHeader from "./MobileHeader";
import History from "../Main/History";
import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ClipLoader from "react-spinners/ClipLoader";
import Voucher from "../Main/Voucher";
import Summary from "../Main/Summary";
import FeedbackForm from "../Form/FeedbackForm";
import feedback from '../../Images/feedback-desktop.png';
import AddCreditPage from "../Main/AddCreditPage";
import BuyNowPage from "../Main/BuyNowPage";
import CodeScanner from "../Main/CodeScanner";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
     
    };
  }

  componentDidMount() {
   
    function getQueryStringValue(key) {
      return decodeURIComponent(
        window.location.search.replace(
          new RegExp(
            "^(?:.*[&\\?]" +
              encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") +
              "(?:\\=([^&]*))?)?.*$",
            "i"
          ),
          "$1"
        )
      );
    }

    let queryId = getQueryStringValue("id"); // get tableid from uri
    let pitoken = getQueryStringValue("token");
    let gatewayMac = getQueryStringValue("gatewayMac");
    let clientIp = getQueryStringValue("clientIp");
    let clientMAC = getQueryStringValue("clientMAC");
    console.log(queryId);
    if(clientMAC)
    {
      sessionStorage.setItem("clientMAC", clientMAC);
      sessionStorage.setItem("clientIp", clientIp);
      sessionStorage.setItem("gatewayMac", gatewayMac);
      sessionStorage.setItem("pitoken", pitoken);
    }

    if (queryId) {
      
      const reqValues = {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      };

      fetch(
        process.env.REACT_APP_apiurl + "/table/find?id=" + queryId.toString(),
        reqValues
      )
        .then(result => result.json())
        .then(result => {
          console.log(result);

          if (!result.error) {
            //push to signin, keep tableid
            sessionStorage.setItem("tableId", queryId);

            if (localStorage.getItem("token") === null) {
              this.props.history.push({
                pathname: "/signin",
                from: "Home"
              });
            }
            else{


              this.props.actions.resumeSession(
                localStorage.getItem("token"),
                this.props.history,
                queryId
              );
            }
          } else if (result.error) {
            alert("No table with this id: " + queryId);
            this.props.history.push("/signin");
          }
        })
        .catch(err => {
          console.log(err);
          alert(
            "Error while searching for table with this id: " +
              queryId +
              " Err: " +
              err
          );
          this.props.history.push("/signin");
        });
    } else {    // no tableid in query
   if(sessionStorage.getItem("tableId")===null)
   {
      if (localStorage.getItem("token") === null) {  // no jwt push to signin
        this.props.history.push({ 
          pathname: "/signin"
        });
      }
      else{

        // this.props.actions.getLoggedinUserDetails(this.props.history);

        // this.setState({ loading: false });

        this.props.actions.resumeSession(
          localStorage.getItem("token"),
          this.props.history,
          sessionStorage.getItem("tableId")
        );
      }
    }

    else{

      if (localStorage.getItem("token") === null) {  
      this.props.history.push({
        pathname: "/signin",
        from: "Home"
      });

    }
    else{

      this.props.actions.resumeSession(
        localStorage.getItem("token"),
        this.props.history,
        sessionStorage.getItem("tableId")
      );
    }
  }


    }


  }

  componentWillReceiveProps(newProps) {
    console.log(newProps.selectedTable);
    console.log(this.props.selectedTable, "L64>>>>>>>>>>>>>>>>>");
    console.log(Object.is(newProps.selectedTable, this.props.selectedTable));
    if (
      !Object.is(newProps.userDetail, this.props.userDetail) ||
      this.props.userDetail._id
    ) {
      this.setState({ loading: false });
    }
  }



  render() {
    return (
      <div className="homestart forotherpage">
        {this.state.loading && (
          <div className="sweet-loading">
            <ClipLoader
              // css={override}
              sizeUnit={"px"}
              size={150}
              color={"#123abc"}
              loading={this.state.loading}
            />
          </div>
        )}
        {!this.state.loading && (
          <div>
            <MobileHeader />
            <Header />
          </div>
        )}
        <Switch>
          {/* <Route
          exact
          path={`${this.props.match.url}`}
          component={HomePage}
        /> */}
          <Route
            exact
            path={`${this.props.match.url}/location`}
            component={mapPointer}
          />
          {/* <Route
            exact
            path={`${this.props.match.url}/map`}
            component={googleMap}
          /> */}

          {/* <Route
            exact
            path={`${this.props.match.url}/location`}
            component={mapPointer}
          /> */}

          {/* <Route
            exact
            path={`${this.props.match.url}/5legvT`}
            component={SignIn}
          /> */}
          {/* <Route
          exact
          path={`${this.props.match.url}/selectsettings`}
          component={SelectSettings}
        /> */}
          <Route
            exact
            path={`${this.props.match.url}/info`}
            // sessionStorage.getItem('component')==="summary"?
            component={
              sessionStorage.getItem("tableId")!==null 
                ?  SelectSettings
                : Summary
            }
            // }
          />

          {/* <Route
          exact
          path={`${this.props.match.url}/Payment`}
          component={Payment}
          /> */}
          {/* <Route
          exact
          path={`${this.props.match.url}/selectservice`}
          component={SelectServiceDrawer}
          /> */}
          <Route
            exact
            path={`${this.props.match.url}/historytable`}
            component={HistoryTable}
          />
          <Route
            exact
            path={`${this.props.match.url}/editprofile`}
            component={Editprofile}
          />
          <Route
            exact
            path={`${this.props.match.url}/profile`}
            component={Profile}
          />
           <Route
            exact
            path={`${this.props.match.url}/scanner`}
            component={CodeScanner}
          />
           <Route
            exact
            path={`${this.props.match.url}/buynow`}
            component={BuyNowPage}
          />
           <Route
            exact
            path={`${this.props.match.url}/addcredit`}
            component={AddCreditPage}
          />

          <Route
            exact
            path={`${this.props.match.url}/history`}
            component={History}
          />
          <Route
            exact
            path={`${this.props.match.url}/voucher`}
            component={Voucher}
          />
          <Route
            exact
            path={`${this.props.match.url}/feedback`}
            component={FeedbackForm}
          />
          

          {/* <Route
            exact
            path={`${this.props.match.url}/SignIn/5legvT`}
            component={SignIn}
          /> */}

          {/* <Route
          exact
          path={`${this.props.match.url}/alert`}
          component={Alert}
          /> */}
        </Switch>
        {/* <div className="hidden-xs">
      <Switch>
      <Route
          exact
          path={`${this.props.match.url}/selectservicedrawer`}
          component={SelectServiceDrawer}
          />
      </Switch>
      </div> */}
       {!this.state.loading &&
       <NavLink to="/home/feedback">
       {this.props.location.pathname !== "/home/feedback" ?
        
       
                <div className="menufeedback hidden-xs">
                
                <img src={feedback} className="usericn feedbackimg" />
              
                  <span className="homenav feedbackdespotop">Give Feedback</span>
     
                  </div>
                  :null
                    }
                </NavLink>
       }
      </div>
    );
  }
}

// export default Home;

const mapStateToProps = state => {
  return state;
};

function mapDispatchToProps(dispatch, state) {
  return {
    actions: bindActionCreators(actioncreators, dispatch)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Home)
);
