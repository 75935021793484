
import React, { Component } from 'react';
import './Admin.css';
import 'semantic-ui-css/semantic.min.css'
import { Grid, Image, Button, Container, Checkbox, Form, Icon, Input, Card, Radio, GridColumn, TextArea } from 'semantic-ui-react'
// import { NavLink } from 'react-router-dom';
import {
    DateInput,
    TimeInput,
    DateTimeInput,
    DatesRangeInput
} from 'semantic-ui-calendar-react';
import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, NavLink } from "react-router-dom";
import deleteimg from '../../Images/recyclebin.png';
import { tsObjectKeyword } from '@babel/types';
// import NumberFormat from 'react-number-format';
function onChange(date, dateString) {
    console.log(date, dateString);
}
class AddCompanyForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: '',
            time: '',
            dateTime: '',
            datesRange: '',
            details: {}
        };
    }
    // handleChange = (event, { name, value }) => {
    //     if (this.state.hasOwnProperty(name)) {
    //         this.setState({ [name]: value });
    //     }
    // }

    textInput = (e) => {
        console.log(e.target.name)
        console.log(e.target.value)
        let x = { [e.target.name]: e.target.value }
        this.setState(
            prevState => ({
                details: {
                    ...prevState.details,
                    ...x
                }
            }),
            () => {
                console.log(this.state.details);

            }
        );
    }

    textInputAdmin = (e) => {
        console.log(e.target.name)
        console.log(e.target.value)
        let x = { [e.target.name]: e.target.value }
        this.setState(
            prevState => ({
                adminDetails: {
                    ...prevState.adminDetails,
                    ...x
                }
            }),
            () => {
                console.log(this.state.adminDetails);

            }
        );
    }





    addCompany = () => {

        let obj = { ...this.state.details, ...this.state.adminDetails }

        console.log(this.props.editData)
        if (Object.keys(this.props.editData).length === 0) {

            var regex = /\d+/g;
            var string = this.state.details.companyPhoneNumber;
            var matches = string.match(regex);
            console.log(matches);
            console.log(matches.join(""));

            this.state.details.companyPhoneNumber = matches.join("");



            let email = obj.email;

            delete obj.email
            obj.companyAdminEmail = email;
            console.log("Adding>>>>")

            this.props.actions.createCompany(obj)
            this.props.onClose();
        }
        else if (Object.keys(this.props.editData).length > 0 && this.props.editData._id) {

            console.log("Editing>>>>>")
            // let obj=this.state.details
            delete obj.tableData;
            // delete obj.admin;
            delete obj.__v;
            delete obj._id;
            delete obj.createdDate;
            console.log(obj);

            var regex = /\d+/g;
            var string = this.state.adminDetails.phoneNumber;
            var matches = string.match(regex);
            console.log(matches);
            console.log(matches.join(""));

            this.state.adminDetails.phoneNumber = matches.join("");

            console.log(this.state.adminDetails)
            const reqValues = {
                method: "PUT",
                body: JSON.stringify(this.state.adminDetails),
                headers: {
                    "Content-Type": "application/json",
                    authorization: localStorage.getItem("token")
                }

            };
            console.log(obj)
            fetch(process.env.REACT_APP_apiurl + "/user/update?id=" + this.props.editData.admin._id, reqValues)
                .then(response =>
                    response.json()
                )
                .then(response => {

                    console.log(response);
                    if (!response.error) {
                        // this.props.actions.editCompany(result.result)
                        // this.props.onClose();

                        const reqValues = {
                            method: "PUT",
                            body: JSON.stringify(obj
                                // {
                                // companyName:obj.companyName,
                                // companyAddress: obj.companyAddress,
                                // companyPhoneNumber:obj.companyPhoneNumber,
                                // firstName: obj.admin.firstName,
                                // lastName: obj.admin.lastName,
                                // phoneNumber: obj.admin.phoneNumber,

                                //     url:obj.url
                                // }
                            ),
                            headers: {
                                "Content-Type": "application/json"
                            }

                        };
                        console.log(obj)
                        console.log(this.props.editData)
                        fetch(process.env.REACT_APP_apiurl + "/company/updateCompany?id=" + this.props.editData._id, reqValues)
                            .then(result =>
                                result.json()
                            )
                            .then(result => {

                                result.result.admin = response.result

                                console.log(result);
                                if (!result.error) {
                                    this.props.actions.editCompany(result.result)
                                    this.props.onClose();
                                }
                            })
                            // }

                            .catch(err => {
                                console.log("Error", err);
                            });
                    }
                })
                // }

                .catch(err => {
                    console.log("Error", err);
                });

        };

    }


    componentDidMount() {
        console.log(this.props.editData);
        let obj = { ...this.props.editData }
        //   delete obj.admin;
        // if(obj.admin){
        //     delete obj.admin.password;
        // }
        let adminObj = { ...this.props.editData.admin }
        delete adminObj.password;
        this.setState({
            details: obj,
            adminDetails: adminObj
        })
    }




    render() {

        return (
            <div className="tableliststart adminvoucherform">
                <div style={{ maxWidth: '100%' }} className="tablediv voucherform">
                    <div className="formfieldarea">

                        <Form className="formfield emailfield">
                            {this.props.editData &&
                                <Grid >
                                    <Grid.Column computer={8} className="border-remove">
                                        <Form.Field>
                                            <label className="labelnme">Company Name</label>
                                            <Input
                                                value={this.state.details ? this.state.details.companyName : null}
                                                iconPosition='left' type="text" name="companyName" onChange={(e) => this.textInput(e)} />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column computer={8} className="border-remove">
                                        <Form.Field>
                                            <label className="labelnme">Phone</label>
                                            <Input iconPosition='left'
                                                value={this.state.details ? this.state.details.companyPhoneNumber : null}
                                                type="text" name="companyPhoneNumber" onChange={this.textInput} />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column computer={16} className="border-remove">
                                        <Form.Field>
                                            <label className="labelnme">Address</label>
                                            <TextArea
                                                iconPosition="left"
                                                type="text"
                                                name="companyAddress"
                                                onChange={this.textInput}
                                                value={this.state.details ? this.state.details.companyAddress : null}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column computer={8} className="border-remove">
                                        <Form.Field>
                                            <label className="labelnme">Company Url</label>
                                            <Input iconPosition='left'
                                                value={this.state.details ? this.state.details.url : null}
                                                type="text" name="url" onChange={this.textInput} />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid>
                            }
                            {/* {(this.props.editData).length=== 0 &&  */}
                            {/* <h3 className="tableservc-heding">Admin</h3> */}
                            {/* {Object.keys(this.props.editData).length === 0 && this.props.editData.constructor === Object
                                &&
                                <> */}
                            <h3 className="tableservc-heding">Admin</h3>
                            <Grid>




                                <Grid.Column computer={8} className="border-remove">
                                    <Form.Field className="companyadmin">
                                        <label className="labelnme">First Name</label>
                                        <Input iconPosition='left' type="text" name="firstName"
                                            value={this.state.adminDetails ? this.state.adminDetails.firstName : null}
                                            onChange={this.textInputAdmin} />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column computer={8} className="border-remove">
                                    <Form.Field className="companyadmin">
                                        <label className="labelnme">Last Name</label>
                                        <Input iconPosition='left' type="text" name="lastName"
                                            value={this.state.adminDetails ? this.state.adminDetails.lastName : null}
                                            onChange={this.textInputAdmin} />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column computer={8} className="border-remove">
                                    <Form.Field className="companyadmin">
                                        <label className="labelnme">Phone Number</label>
                                        <Input iconPosition='left' type="text" name="phoneNumber"
                                            value={this.state.adminDetails ? this.state.adminDetails.phoneNumber : null}
                                            onChange={this.textInputAdmin} />
                                        {/* <NumberFormat format="#### #### #### ####"/>  */}
                                        {/* <NumberFormat mask="_"/> */}
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column computer={8} className="border-remove">
                                    <Form.Field className="companyadmin">
                                        <label className="labelnme">User Email</label>
                                        <Input iconPosition='left' type="email" name="email"
                                            value={this.state.adminDetails ? this.state.adminDetails.email : null}

                                            onChange={this.textInputAdmin} />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column computer={8} className="border-remove">
                                    <Form.Field className="companyadmin">
                                        <label className="labelnme">Password</label>
                                        <Input iconPosition='left' type="password" name="password"
                                            //  value={this.props.editData.admin ? this.props.editData.admin.password : null}

                                            onChange={this.textInputAdmin} />
                                    </Form.Field>
                                </Grid.Column>
                                {/* <Grid.Column computer={8} className="addbtnuseradmin">
                                    <div className="adduseradmin">
                                        <Button className="addsrvcbutn" >
                                            Add
                                        </Button>
                                    </div>
                                </Grid.Column> */}

                            </Grid>
                            {/* </>
                            } */}
                            {/* } */}

                            {/* <div className="adminuserlisting">
                                <ul>
                                    <li className="usernameemail">
                                        <Grid>
                                            <Grid.Column computer={4}>
                                                abc@gmail.com
                                            </Grid.Column>
                                            <Grid.Column computer={1}>
                                                <Button type='submit' className="adminuserdelete" title="Delete">
                                                    <img src={deleteimg} className="deleteuserimg" />
                                                </Button>
                                            </Grid.Column>
                                        </Grid>
                                    </li>
                                    <li className="usernameemail">
                                        <Grid>
                                            <Grid.Column computer={4}>
                                                John Doe
                                            </Grid.Column>
                                            <Grid.Column computer={1}>
                                                <Button type='submit' className="adminuserdelete" title="Delete" >
                                                    <img src={deleteimg} className="deleteuserimg"/>
                                                </Button>
                                            </Grid.Column>
                                        </Grid>
                                    </li>
                                </ul>
                            </div> */}
                            <div className="formbutn addeditmodal-formbutn"><Button type='submit' onClick={this.addCompany}>Submit</Button></div>
                        </Form>

                    </div>
                </div>
            </div >
        );
    }
}

// export default AddUserListForm;
const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch, state) {
    return {
        actions: bindActionCreators(actioncreators, dispatch)
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AddCompanyForm)
);