import React from 'react';
import './Main.css';
import 'semantic-ui-css/semantic.min.css'
import { Grid, Image, Button, Container, Checkbox, Form, Icon, Input, Card } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom';

import logo from '../../Images/logo.png'
import scanner from '../../Images/scanner.png'

function QRScanner() {
    return (
        <div className="qrscanner">
            {/* <Container> */}
            <div className="hidden-md">
                {/* <Grid>
                        <Grid.Row columns={8}>
                            <Grid.Column computer={16} mobile={16} // tablet={10}
                            > */}
                <div className="inerscanner">
                    <div className="headerarea">
                        <p>QR Code Scanner</p>
                    </div>
                    <div className="scannerheader">
                        <p className="headertxt">Visit <span className="url">https://www.information.com </span>
                            from your browser or scan the QR code from the table</p>
                    </div>
                    <Card>
                        <div className="cardmidlearea">
                            <img src={scanner} className="scannerimg" />
                        </div>
                    </Card>
                    <div className="scannerbutnarea">
                        <Button>Scan Code</Button>
                    </div>
                </div>

                {/* </Grid.Column>
                        </Grid.Row>
                    </Grid> */}
            </div>
            {/* </Container> */}
            <div className="hidden-xs">
                <div className="logoare">
                    <img src={logo}/>
                </div>
            <div className="cardareadesktop">
            <Container>
                <div>
            {/* <Card> */}
            <Grid>
                    <Grid.Row>
                        <Grid.Column computer={8} >
                            <div className="cardmidlearea">
                                <img src={scanner} className="scannerimg" />
                            </div>
                         
                        </Grid.Column>
                        <Grid.Column computer={8} >
                        {/* <div className="headerarea">
                        <p>QR Code Scanner</p>
                    </div> */}
                    <div className="scannerheader">
                        <p className="headertxt">Visit <span className="url">https://www.information.com </span>
                            from your browser or scan the QR code from the table</p>
                    </div>
                    <div className="scannerbutnarea">
                        <NavLink to="/home/selectsettings">
                        <Button>Scan Code</Button>
                        </NavLink>
                    </div>
                  
            </Grid.Column>
                    </Grid.Row>
                    </Grid>
                    </div>
             {/* </Card>   */}
            </Container>
            </div>
            </div>
        </div>
    );
}

export default QRScanner;
