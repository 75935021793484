import React, { Component } from 'react';
import '../Main/Main.css';
import './Admin.css';
import 'semantic-ui-css/semantic.min.css'
import { Grid, Image, Button, Radio, Container, Checkbox, Form, Icon, Input, Card, Modal } from 'semantic-ui-react'
import { withRouter, NavLink } from 'react-router-dom';
import MaterialTable from 'material-table';
import tableicon from '../../Images/audit.png';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as actioncreators from '../../redux/action';
import AddUserListForm from './AddUserListForm'
import moment, { duration } from 'moment';

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      modalOpen: false,
      size: "default",

    }
  }

  handleOpen = () => this.setState({ modalOpen: true })

  handleClose = () => this.setState({ modalOpen: false })

  componentWillReceiveProps(newProps) {

    if (!Object.is(newProps.usersList, this.props.usersList)) {
      this.setState({ data: newProps.usersList }, () => {
        console.log("Recieving props>>>>>>>")
      })
    }


  }

  componentDidMount() {
    console.log(this.state.data)
    this.props.actions.getUsersList();
  }

  editUsers = (e) => {
    console.log(e)


    var regex = /\d+/g;
    var string = this.state.details.phone;
    var matches = string.match(regex);
    console.log(matches);
    console.log(matches.join(""));

    this.state.details.phone = matches.join("");



    this.setState({ nonmatchingPassword: false })

    this.props.actions.editUserProfile(this.state.data._id, this.props.history, "admin"
    )
    console.log("user created successfully>.......62")

  }

  setStatus = (x,e,name) => {
    console.log(e.target.value)
    console.log(name)
    console.log(x, "l267>>>>>>>>>>>>>")
    // let y={};
    // y = this.props.companyList;
    // y.enabled=this.props.companyList
    let string= "false"
    if(name.checked){
        string="true"
    }
    console.log(string)
    const reqValues = {
        method: "PUT",

        headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token")
        }
    };
    fetch(process.env.REACT_APP_apiurl + "/user/updateUserStatus?id=" + x._id +"&data="+ string, reqValues)
        .then(result =>
            // console.log(result))
            result.json()
        )
        .then(result => {
            console.log(result);

            if (!result.error) {
                this.props.actions.setUsersStatus(result.result)
            }

        })
        .catch(err => {
            console.log("Error", err);
        });
};







  render() {
    var userdata = [
      { name: 'User1', ph_no: '123456789', email: 'sdcd@gmail.com', password: '123456', i_use: 'Adult', p_privilege: 'Credit Card', status: 'Active' },
      { name: 'User2', ph_no: '123456789', email: 'sdcd@gmail.com', password: '123456', i_use: 'Juvenile', p_privilege: 'Prepaid Card', status: 'Inactive' },
    ]
    return (
      <div className="tableliststart">
        <div className="historytablestart">
          <div className="profilepagestart">
            {/* <div className="payment-heading">
              <Grid>
                <Grid.Column computer={8}>
                  <h2 className="headingtxt"><img src={tableicon} />List of Users</h2>
                </Grid.Column>
              </Grid>

            </div> */}
            <div style={{ maxWidth: '100%' }} className="tablediv">
              {/* <Modal trigger={<Button className="adminvouchrbutn"><Icon name="plus" />Add new</Button>}> */}
              <Modal
                open={this.state.modalOpen}
                onClose={this.handleClose}
                trigger={<Button onClick={this.handleOpen} className="adminvouchrbutn">
                  <Icon name="plus" />Add new</Button>} >
                <Modal.Header className="modalheader">Add User <Icon onClick={this.handleClose} name="close" className="closeicon" />
                </Modal.Header>
                <Modal.Content className="addmodal-content">
                  <AddUserListForm    onClose={this.handleClose}/>
                </Modal.Content>
              </Modal>
              <MaterialTable
                title="User List"
                columns={[
                  {
                    title: 'User Name', field: 'name',
                    render: rowData => {
                      return (
                        <div>
                          <p>{rowData.firstName + " " + rowData.lastName}</p>
                        </div>
                      )
                    }
                    // editable:'never'
                    // editComponent: props => (
                    //     <input
                    //         type="radio"
                    //         value={props.value}
                    //         onChange={e => props.onChange(e.target.value)}
                    //     />
                    // )
                  },
                  {
                    title: 'Phone Number',
                    field: 'phoneNumber',
                    editable: 'never',
                    render: rowData => {
                      if(rowData.phoneNumber)
                      {
                      let x = rowData.phoneNumber.replace(/[^\d]+/g, '')
                        .replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3');
                      return (
    
                        <div>
                          <p>{x}</p>
                        </div>
                      )
                      }
                    }

                    // lookup: { 3: 'Activate', 4: 'Deactivate' },
                  },
                  {
                    title: 'Email', field: 'email',
                    // editable:'never' 
                  },
                  {
                    title: 'Date of Birth',
                    field: 'dob',
                    render: rowData => {
                      return (

                        <div>
                          {/* {rowData.employeeList ? ( */}
                          <div className="createdDate">
                            {moment(rowData.dob).format("MM-DD-YYYY")}
                          </div>
                          {/* ) : "05-09-2019 15:25"} */}
                        </div>
                      );
                    }
                  },
                  // { title: 'Internet Use', field: 'i_use',editable:'never' },
                  // {
                  //   title: 'Payment Privilege',
                  //   field: 'p_privilege',
                  //   editable:'never'
                  //   // lookup: { 3: 'Activate', 4: 'Deactivate' },
                  // },
                  {
                    title: 'Status',
                    field: 'enabled',
                    render: x => {
                        return (
                            <div>
                              <Radio toggle defaultChecked={x.enabled}  onChange={(e,name,) => this.setStatus(x,e,name)} />
                                {/* <Radio toggle defaultChecked={rowData.status === "good" ? true : false} /> */}
                            </div>
                        )
                    }
                    // lookup: { 1: 'Activate', 2: 'Deactivate' },
                },
                ]}
                data={this.state.data}
                editable={{
                  // onRowAdd: newData =>
                  //   new Promise((resolve, reject) => {
                  //     setTimeout(() => {
                  //       {
                  //         const data = this.state.data;
                  //         data.push(newData);
                  //         this.setState({ data }, () => resolve());
                  //       }
                  //       resolve()
                  //     }, 1000)
                  //   }),
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      // setTimeout(() => {
                      // {
                      this.editUsers(oldData._id)

                      // const data = this.state.data;
                      // const index = data.indexOf(oldData);
                      // data[index] = newData;
                      // this.setState({ data }, () => resolve());
                      // }
                      resolve()
                    }, 1000),
                  // }),
                  // onRowDelete: oldData =>
                  //   new Promise((resolve, reject) => {
                  //     // setTimeout(() => {
                  //     //   {
                  //     this.props.actions.deleteUser(oldData._id)
                  //     // let data = this.state.data;
                  //     // const index = data.indexOf(oldData);
                  //     // data.splice(index, 1);
                  //     // this.setState({ data }, () => resolve());

                  //     // }
                  //     resolve()
                  //   }, 1000)



                }}
                // onRowClick={(event, rowData, togglePanel) => togglePanel()}
                options={{
                  pageSize: 20,
                  pageSizeOptions: [30, 40, 50],
                  actionsColumnIndex: -1

                }} />



            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default UserList;
const mapStateToProps = state => {
  return state;
};

function mapDispatchToProps(dispatch, state) {
  return {
    actions: bindActionCreators(actioncreators, dispatch)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserList)
);
