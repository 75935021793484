import React, { Component } from "react";
import "./Main.css";
import "semantic-ui-css/semantic.min.css";
import { Button, Modal } from "semantic-ui-react";
import user from "../../Images/avatar.png";
import password from "../../Images/password.png";
import email from "../../Images/close-envelope.png";
import edit from "../../Images/profileedit.png";
import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, NavLink } from "react-router-dom";
import FeedbackForm from "../Form/FeedbackForm";
import DropIn from "braintree-web-drop-in-react";
import back from "../../Images/rightarrow.png";
import Alert from "./Alert";
import PayPalCheckout from "./PayPalPage";

class BuyNowPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      voucherBuyValues: [5, 10, 15, 20, 30],
      modalOpen: false,
      openModal: false,
      orderId: "",
      cost: 0 //Always ParseInt!
    };
  }

  async componentDidMount() {
    console.log(this.props.location);

    // Was needed for generating braintreee token>> Removing as only paypal needed now.
    // const reqValues = {
    //     method: "GET",
    //     headers: {
    //         "authorization": localStorage.getItem("token")
    //     }
    // };
    // const response = await fetch(process.env.REACT_APP_apiurl +
    //     "/payment/gettoken", reqValues);
    // const clientToken = await response.json(); // If returned as JSON string

    // this.setState({
    //     clientToken: clientToken.token
    // });
    // Todo: Remove state: clientToken
  }

  voucherModel = () => {
    this.setState({ openmodel: true });
  };

  openModal = () => {
    this.setState({ modalOpen: true });
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
    this.setState({ openmodel: false });
    this.setState({ showModel: false });
  };

  createorder = x => {
    console.log(this.state.voucherBuyValues);
    let data = {
      type: "voucher",
      tableId: this.props.selectedTable._id,
      orderedBy: this.props.userDetail._id,
      services: [
        {
          cost: x
        }
      ]
    };

    const reqValues = {
      method: "POST",
      body: JSON.stringify(data),

      headers: {
        "Content-Type": "application/json"
      }
    };
    fetch(process.env.REACT_APP_apiurl + "/order/create", reqValues)
      .then(result => result.json())

      .then(result => {
        console.log(result);

        if (!result.error) {
          this.setState({ orderId: result.result._id });
          this.setState({ cost: result.result.totalCost });
          this.openModal();
        }
      })

      .catch(err => {
        console.log(err);
      });
  };

  paymentfunc = async () => {
    console.log(this.state.nonce);
    if (this.state.nonce) {
      let object = {
        orderId: this.state.id,
        nonce: this.state.nonce,
        amount: parseFloat(this.state.cost)
      };
      console.log(object);
      const reqValues = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token")
        },
        body: JSON.stringify(object)
      };
      fetch(process.env.REACT_APP_apiurl + "/payment/paybybraintree", reqValues)
        .then(response => response.json())
        .then(response => {
          console.log(response);
          if (!response.error) {
            this.setState({ nonce: null, payment: false });
            this.buyVoucher();
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      const { nonce } = await this.instance.requestPaymentMethod();
      this.setState({ nonce: nonce });
    }
  };

  buyVoucher = () => {
    let data = {
      value: this.state.cost,
      identifierId:
        "individual" +
        this.props.userDetail._id +
        this.props.location.state.vouchers.length
    };
    const reqValues = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token")
      }
    };
    fetch(process.env.REACT_APP_apiurl + "/voucher/create", reqValues)
      .then(result => result.json())
      .then(result => {
        console.log(result);

        if (!result.error) {
          // this.setState({ value: result.result.value })

          this.setState({ Message: result.message });
          this.setState({
            alertheading: "Voucher Created ",
            alertcontext: "Your Voucher Created successfull!! ",
            alertbutton: "Done",
            imgtype: "success"
          });

          this.voucherModel();
        } else {
          this.setState({
            alertheading: "Voucher Created ",
            alertcontext: "Your Voucher Created successfull!! ",
            alertbutton: "Done",
            imgtype: "error"
          });

          this.voucherModel();
        }
      })
      .catch(err => {
        this.setState({
          alertheading: "Voucher Created ",
          alertcontext: "Your Voucher Created successfull!! ",
          alertbutton: "Done",
          imgtype: "error"
        });

        this.voucherModel();
        console.log(err);
      });
  };

  goBackonCredit = () => {
    this.props.history.push("/home/voucher");
  };
  callBackMsg=(text)=>{
      console.log(text,'L200>>>')
      if(text==='Success')
      {
       this.setState({modalOpen:false, openmodel: true});
    //    this.setState({ Message: result.message });
       this.setState({
        Message:"Voucher created Successfully" ,
         alertheading: "Payment successfull",
         alertcontext: "Your Voucher has been created successfully!!",
         alertbutton: "Ok",
         imgtype: "success"
       });

      }
      else if(text==='rejected'){
        this.setState({modalOpen:false,openmodel: true});

        this.setState({
            alertheading: "Payment failed!",
            alertcontext: "Payment failed!",
            alertbutton: "Done",
            imgtype: "error"
          });
      }
  }
  render() {
    const { modalOpen, openmodel } = this.state;
    return (
      <div className="inner-summary historydiv subpages buynowpagestart">
        <p className="vouchertitlepara">
          <img src={back} className="gobackimg" onClick={this.goBackonCredit} />
          Buy Now
        </p>
        <div className="buynowcard">
          <ul className="buynowcardul flex-wrap">
            {this.state.voucherBuyValues.map(x => {
              return (
                <li className="flex-item" onClick={() => this.createorder(x)}>
                  <div className="buynowcardstart">
                    <div className="buynowamountsec">
                      <p>${x}</p>
                    </div>
                    <p className="expirytext">
                      Expires in <span> 1 year</span>{" "}
                    </p>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        {modalOpen && (
          <Modal
            className="vouchermodal"
            open={modalOpen}
            onClose={this.closeModal}
          >
            <Modal.Content className="voucher-modalcontent">
              {/* <DropIn
                                options={{ authorization: this.state.clientToken }}
                                onInstance={instance => (this.instance = instance)}
                            />

                            <p>Demo Card: 4111 1111 1111 1111</p>
                            <p>Expiry: 09/20</p>
                            <Button className="redeemvouchr-butn" onClick={() => {
                                this.paymentfunc()
                                //   this.findbutnfunction()
                            }}>Pay</Button> */}

              <PayPalCheckout
                cost={this.state.cost}
                orderId={this.state.orderId}
                success={this.callBackMsg}
                createVoucher={'true'}
              />
            </Modal.Content>
          </Modal>
        )}
        {this.state.Message &&
          this.state.Message === "Voucher created Successfully" && (
            <Modal open={openmodel} onClose={this.closeModal}>
              <Modal.Content>
                <Alert
                  alertheading={this.state.alertheading}
                  alertcontext={this.state.alertcontext}
                  alertbutton={this.state.alertbutton}
                  imgtype={this.state.imgtype}
                  // butnfunction={this.closeModal}
                  butnfunction={() => {
                    this.closeModal();
                    this.goBackonCredit();
                  }}
                />
              </Modal.Content>
            </Modal>
          )}
      </div>
    );
  }
}

// export default Profile;

const mapStateToProps = state => {
  return state;
};
function mapDispatchToProps(dispatch, state) {
  return {
    action: bindActionCreators(actioncreators, dispatch)
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BuyNowPage)
);
