import React, { Component } from "react";
import "../Main/Main.css";
import "./Admin.css";
import "semantic-ui-css/semantic.min.css";
import {
  Grid,
  Image,
  Button,
  Container,
  Checkbox,
  Form,
  Icon,
  Input,
  Card,
  Radio,
  GridColumn,
  Modal
} from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import MaterialTable from "material-table";
import tableicon from "../../Images/audit.png";
import VoucherCreationForm from "./VoucherCreationForm";
import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
var moment = require('moment');

class AdminVoucherList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      modalOpen: false
    };
  }
  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });

  componentDidMount() {
    this.props.actions.getVouchers();   //>> add a refresh button

    // const reqValues = {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     authorization: localStorage.getItem("token")
    //   }
    // };
    // fetch(process.env.REACT_APP_apiurl + "/voucher/findAll", reqValues)
    //   .then(result => result.json())
    //   .then(result => {
    //     console.log(result);
    //     //   dispatch(usersList(result.result));
    //     if (result.error) {
    //       alert("Error while searching for vouchers -" + result.message);
    //     } else {
    //       this.setState({ data: result.result });
    //     }
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   });
  }

  componentWillReceiveProps(newProps) {

    if (!Object.is(newProps.voucherList, this.props.voucherList)) {
      this.setState({ data: newProps.voucherList }, () => {
        console.log("Recieving props>>>>>>>")
      })
    }


  }

  render() {
    var tableData = [
      {
        name: "Table1",
        sno: "T1234",
        gps: "41.40338, 2.17403.",
        c_status: "Active",
        b_status: "22%"
      },
      {
        name: "Table2",
        sno: "T1245",
        gps: "41.40338, 2.17403.",
        c_status: "Inactive",
        b_status: "44%"
      }
    ];
    return (
      <div className="tableliststart adminvoucherlist">
        <div className="historytablestart">
          <div className="profilepagestart">
            <div style={{ maxWidth: "100%" }} className="tablediv">
              <Modal
                open={this.state.modalOpen}
                onClose={this.handleClose}
                trigger={
                  <Button onClick={this.handleOpen} className="adminvouchrbutn adminvoucherupdate">
                    <Icon name="plus" />
                    Add new
                  </Button>
                }
              >
                <Modal.Header className="modalheader">
                  Add new voucher{" "}
                  <Icon
                    onClick={this.handleClose}
                    name="close"
                    className="closeicon"
                  />
                </Modal.Header>

                <Modal.Content className="addmodal-content">
                  <VoucherCreationForm  onClose={this.handleClose}/>
                </Modal.Content>
              </Modal>
              <MaterialTable
                title="Voucher"
                columns={[
                  {
                    title: "Voucher Code",
                    field: "redeemId"
                  },
                  {
                    title: "Valid up to",
                    field: "expiry",
                    render: rowData => {
                        return (
                          <div>
                           {
                               rowData.expiry?moment(rowData.expiry).format("MM-DD-YYYY"):"Not supplied"
                           }
                          </div>
                        );
                      }
                  },
                  {
                    title: "Amount Of The Voucher",
                    field: "value"
                    // render: rowData => {
                    //     return(
                    //     <div>
                    //       <Radio toggle  defaultChecked= {rowData.status==="good"?true:false} />
                    //     </div>
                    //     )
                    // }
                    // lookup: { 1: 'Activate', 2: 'Deactivate' },
                  },
                  {
                    title: "Identifier",
                    field: "identifierId"
                  },
                  // {
                  //     title: 'Battery Status',
                  //     field: 'b_status',

                  // },

                  {
                    title: "Name of the Purchaser",
                    field: "neme",
                    render: rowData => {
                        return (
                          <div>
                           {
                               rowData.createdBy.firstName+" "+rowData.createdBy.lastName
                           }
                          </div>
                        );
                      }
                  }
                ]}
                 data={this.state.data}
                // editable={{
                //     onRowAdd: newData =>
                //         new Promise((resolve, reject) => {
                //             setTimeout(() => {
                //                 {
                //                     const data = this.state.data;
                //                     data.push(newData);
                //                     this.setState({ data }, () => resolve());
                //                 }
                //                 resolve()
                //             }, 1000)
                //         }),
                //     onRowUpdate: (newData, oldData) =>
                //         new Promise((resolve, reject) => {
                //             setTimeout(() => {
                //                 {
                //                     const data = this.state.data;
                //                     const index = data.indexOf(oldData);
                //                     data[index] = newData;
                //                     this.setState({ data }, () => resolve());
                //                 }
                //                 resolve()
                //             }, 1000)
                //         }),
                //     onRowDelete: oldData =>
                //         new Promise((resolve, reject) => {
                //             setTimeout(() => {
                //                 {
                //                     let data = this.state.data;
                //                     const index = data.indexOf(oldData);
                //                     data.splice(index, 1);
                //                     this.setState({ data }, () => resolve());
                //                 }
                //                 resolve()
                //             }, 1000)
                //         }),

                // }}
                // actions={[
                //     {
                //       icon: ()=><Icon name='redo' />                                      ,
                //       tooltip: 'Port 1>60s ',
                //       onClick: (event, rowData) => {this.test1(rowData)}
                //       alert("You saved " + rowData.name)
                //     },
                //     {
                //         icon: ()=><Icon name='play' />                                      ,
                //         tooltip: 'Port 2-7>start ',
                //         onClick: (event, rowData) => {this.start7(rowData)}
                //         alert("You saved " + rowData.name)
                //       },
                //       {
                //         icon: ()=><Icon name='stop' />                                      ,
                //         tooltip: 'Port 2-7>stop ',
                //         onClick: (event, rowData) => {this.stop7(rowData)}
                //         alert("You saved " + rowData.name)
                //       },
                //   ]}
                // onRowClick={(event, rowData, togglePanel) => togglePanel()}
                // options={{
                //     actionsColumnIndex: -1,
                // }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default AdminVoucherList;

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch, state) {
    return {
        actions: bindActionCreators(actioncreators, dispatch)
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AdminVoucherList)
);
