
import React, { Component } from "react";
import "./Main.css";
import { PayPalButton } from "react-paypal-button-v2";


class PayPalCheckout extends Component {

  state = { amount: null, currency: "USD"}


  componentDidMount() {
      console.log(this.props.cost, this.props.orderId)
      console.log(process.env.REACT_APP_Client_ID,'L14>')
  }

  render() {
    return (
<div>
  <PayPalButton
       
       options={{clientId:process.env.REACT_APP_Client_ID}}
        amount={this.props.cost}
        // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
        onSuccess={async (details, data) => {
          // alert("Transaction completed by " + details.payer.name.given_name);
                
          console.log(details,'L25>>')
          // OPTIONAL: Call your server to save the transaction

          if(this.props.orderId)
          {
          const reqValues = {
            method: "POST",
            body: JSON.stringify({orderId:this.props.orderId, transactionId: data.orderID,
                                 amount:this.props.cost, paymentMethod: 'paypal' ,
                                 status: 'paid',createVoucher:this.props.createVoucher?this.props.createVoucher:null  }),
            headers: {
              "Content-Type": "application/json",
              authorization: localStorage.getItem("token")
            }
          };
          var result = await fetch(process.env.REACT_APP_apiurl + "/payment/createPaypalPayment",
          reqValues).then(x=>x.json())

          console.log(result,'L40>>')
            
      //     let voucherData = {
      //       value: this.props.cost,
      //       identifierId: "individual" + this.props.userDetail._id
      //   }
      //   const VoucherreqValues = {
      //       method: "POST",
      //       body: JSON.stringify(voucherData),
      //       headers: {
      //           "Content-Type": "application/json",
      //           "authorization": localStorage.getItem("token")
      //       }
      //   };
      //  var voucherData1= await fetch(process.env.REACT_APP_apiurl + "/voucher/create", VoucherreqValues)
      //       .then(result => result.json())   
      //       console.log(voucherData1,'L57>>')
      // alert('Transaction completed- Success!')
         if(!result.error)
         {
          this.props.success('Success')
         }
         else{
          this.props.success('rejected')
         }
        }
        else{
          //call api to directly increase the credit
          const reqValues = {
            method: "PUT",
            // body: JSON.stringify({value:this.props.cost }),
            headers: {
              "Content-Type": "application/json",
              authorization: localStorage.getItem("token")
            }
          };
          var creditResult = await fetch(process.env.REACT_APP_apiurl + "/voucher/increaseCredit/?value="+this.props.cost,
          reqValues).then(x=>x.json())

          console.log(creditResult,'L40>>')
          if(!creditResult.error)
          {
            this.props.success('Success')
          }
          else{
            this.props.success('rejected')
          }
        }

        }}
      />
      </div>
    );
  }
}
export default PayPalCheckout;
