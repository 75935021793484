import React, { Component } from "react";
import "./Main.css";
import { Button, Card } from "semantic-ui-react";

import rightarrow from "../../Images/rightarrow.png";
import Slider from "react-rangeslider";
import solidwifi from "../../Images/solidwifi.png";
import solidplug from "../../Images/solidplug.png";
import MobileHeader from "../Home/MobileHeader";
import Header from "../Home/Header";
import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, NavLink } from "react-router-dom";

import moment from 'moment';
class SelectServiceDrawer extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      horizontal: 10,
      vertical: {},
      // vertical1: 50
      // vertical: 50,
      // vertical1: 50,
      services: []
      // vertical2: 50
    };
  }

  handleChangeVertical = (e, x) => {
    // console.log(value, ">>>>>>>>>>>>>L40");
    // this.setState({
    //   vertical: value
    // });
    if (e > 14) {
      console.log(e, x._id, ">>>>>>>>>>>>>L33");
      console.log(x._id, "L34?????????>>>>>>>>>>>>>");

      let data = this.state.services.map(z => {
        return {
          serviceName: z.serviceName === x.name ? x.name : z.serviceName,
          duration: z.serviceName === x.name ? (e * 60) : z.duration,  //15*60 seconds
          // durationinNumber: 5,
          cost: z.serviceName === x.name ? (x.cost * e / 15) : z.cost
        };
      });

      this.setState({ services: data }, () => { console.log(this.state.services) });


      this.setState(
        prevState => {
          let vertical = { ...prevState.vertical };

          vertical[x._id] = e;

          return { vertical };
        },
        () => console.log(this.state.vertical)
      );
    }
  };

  componentDidMount() {
    console.log(moment().utc().hours(),"L66>>")
    console.log(this.props.location, "L41>>>>>.");
    console.log(this.props.location.data, "L42>>>>>>>");
    if (this.props.location && this.props.location.data) {
      console.log(this.props.location.data);
      let data = this.props.location.data.map(x => {
        return {
          serviceName: x.name,
          duration: 900,  //15*60 seconds
          // durationinNumber: 5,
          cost: x.cost
        };
      });

      this.setState({ services: data }, () => { console.log(this.state.services) });
    } else {
      this.props.history.push("/Home/info");
    }
  }

  // handleChangeVertical1 = value => {
  //   this.setState({
  //     vertical1: value
  //   });
  // };

  solidImage = name => {
    var selectedObj = this.props.servicesList.find(x => {
      return x.name.toLowerCase() === name.toLowerCase();
    });

    return selectedObj.solid;
  };

  createOrder = () => {
    let data = {
      tableId: this.props.selectedTable._id,
      orderedBy: this.props.userDetail._id,
      services: this.state.services
    };
    // console.log(data);   \\//\\//\\//\\//\\//\\//
    //      \/  \/  \/  \/  \/  \/  
    this.props.actions.createOrder(data, this.props.history);
    //  this.props.history.push("/payment");
  };

  // handleChangeVertical = value => {

  //   console.log(value, ">>>>>>>>>>>>>L40");
  //   this.setState({
  //     vertical: value
  //   });
  // };

  getCost = (id, cost, name) => {
    //per 15 minutes
    console.log(id, cost, name)
    var x = (cost * this.state.vertical[id]) / 15;

    //  let arr=this.props.location.data

    // let array = arr.map(z=>{ 
    //   //  if(z.serviceName===name)
    //   //  {
    //   //   console.log(name,z,"L111")
    //   // /  console.log(z.serviceName===name,this.state.vertical[id],"L112")
    //   //   console.log(x,"L113")
    //     let data=  {serviceName: z.serviceName,
    //     duration: z.serviceName===name?(this.state.vertical[id]*60):z.duration ,  //15*60 seconds
    //     // durationinNumber: 5,
    //     cost: z.serviceName===name?x:z.cost
    //     }
    //     // console.log(data,"L119")

    //     return data;

    //   //  }
    //  })
    //          console.log(array)
    //          this.setState({services:array})
    return x.toFixed(2);
  };

  findUnitPrice = (id, arr) => {
    console.log(id, arr, "L109>>>>>")
    var object = arr.find(x => {
      return x._id === id;
    });

    console.log(object, "L114>>>>>>")

    return object.cost ? object.cost : 0;
  };
  getTotalPrice = obj => {
    var total = 0;
    if (this.props.location && this.props.location.data) {
      for (let i = 0; i < this.props.location.data.length; i++) {
        total += this.props.location.data[i].cost;
      }
      if (Object.keys(this.state.vertical).length > 0) {
        //   //Return initial price
        //   //
        //   for (let i = 0; i < this.props.location.data.length; i++) {
        //     total += this.props.location.data[i].cost;
        //   }
        // } else {
        console.log(obj);
        // /total += this.props.location.data[i].cost;
        Object.keys(obj).forEach((key) => {
          //find price per 15 minute from  array this.props.location.data return cost

          var unitPrice = this.findUnitPrice(key, this.props.location.data);
          // var unitPrice=  this.props.location.data.find(x => {
          //   return x._id === key;
          // }).cost
          console.log(key, obj[key]);

          total += (unitPrice * ((obj[key] / 15) - 1));
        });
      }
    }
    return total.toFixed(2);
  };

  render() {
    const { vertical } = this.state;

    const verticalLabels = {
      // 0: "5min",
      0: "0min",
      15: "15min",
      30: "30min",
      45: "45min",
      60: "60min",
      75: "75min",
      90: "90 min"
    };

    // const vertical2Labels = {
    //     0: '0',
    //     60: '1hr',
    //     90: '2hr',
    //     100: ''
    // }

    // const formatkg = value => value + ' kg'
    const formatPc = p => p + " min";
    return (
      <div className="selectservicedrawer">
        <div className="hidden-md">
          <MobileHeader />
        </div>
        <div className="hidden-xs">
          <Header />
        </div>
        <div className="subpages">
          <NavLink to="/home">
            <img src={rightarrow} className="hidden-md arrwicn" />
          </NavLink>
          <div>
            <div className="selectbox settingcard">
              <div className="chrging-tmendcost">
                <Card className="changercard">
                  <div className="sliderinercard">
                    <div className="sliderpart">
                      {this.props.location &&
                        this.props.location.data &&
                        this.props.location.data.map((x, idx) => {
                          return (
                            <div key={x._id} className="slidergroup">
                              <div className="firstslider">
                                <div className="chargerslider">
                                  <Slider
                                    min={0}
                                    max={90}
                                    step={15}
                                    value={
                                      this.state.vertical[x._id]
                                        ? this.state.vertical[x._id]
                                        : 15
                                    }
                                    // value={this.state.services[idx]? this.state.services[idx].durationinNumber : 5}
                                    orientation="vertical"
                                    labels={verticalLabels}
                                    handleLabel={
                                      vertical[x._id] ? vertical[x._id] : 50
                                    }
                                    format={formatPc}
                                    // onChange={(e)=>this.handleChangeVertical(e,x._id)}
                                    onChange={val =>
                                      this.handleChangeVertical(val, x)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="sliderinfocard">

                                <div className="chrgerinfo">
                                  <div className="chrgericnarea">
                                    <p className="chargrwifitxt">
                                      <img
                                        src={this.solidImage(x.name)}
                                        className="wifiicn"
                                        alt={x.name}
                                      />{" "}
                                      {x.name}
                                    </p>
                                  </div>
                                  <div className="costselect">
                                    <p className="costslcttxt">Cost Selection</p>
                                    <h3 className="acostamnt">
                                      $
                                    {this.state.vertical[x._id]
                                        ? this.getCost(x._id, x.cost, x.name)
                                        : (x.cost).toFixed(2)}
                                    </h3>
                                    <p className="totalcost">
                                      (${(x.cost).toFixed(2)}*
                                    {this.state.vertical[x._id]
                                        ? this.state.vertical[x._id]
                                        : 15}{" "}
                                      min)
                                  </p>
                                  </div>
                                </div>
                              </div>

                            </div>
                          );
                        })}
                    </div>

                  </div>
                </Card>
              </div>

              <div className="servcdrawrbutn">
                <div className="totalprice">
                  <p className="pricetext">
                    {" "}
                    ${this.getTotalPrice(this.state.vertical)}{" "}
                    <span>(Total Price)</span>
                  </p>
                </div>
                <div className="paymentbtnwidth">
                  <Button
                    className="totalprice paymentbutn"
                    onClick={() => this.createOrder()}
                  >
                    <p className="paymnttxt">Payment</p>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return state;
};

function mapDispatchToProps(dispatch, state) {
  return {
    actions: bindActionCreators(actioncreators, dispatch)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SelectServiceDrawer)
);
