import React, { Component } from "react";
import "./Form.css";
import "semantic-ui-css/semantic.min.css";
import { Grid, Image, Button, Container, Form, Input } from "semantic-ui-react";

import Shadecharger from "../../Images/Shadecharger.png";
import logo from "../../Images/bluelogo.png";
import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, NavLink } from "react-router-dom";
import { isDEV } from "../../services/sharedService";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      details: {
        email: "demo@shadecharger.net",
        password: "demo"
      },
      nonmatchingPassword: false,
      showWrongPasswordMessage: false,
      loading: false,
      token: "token",
      gatewayMac: "gatewayMac",
      clientIp: "clientIp",
      clientMAC: "clientMAC"
    };
  }

  textInput = e => {
    //     isDEV() && console.log(e.target.name);
    // isDEV() && console.log(e.target.value)
    let x = { [e.target.name]: e.target.value };
    this.setState(
      prevState => ({
        details: {
          ...prevState.details,
          ...x
        }
      })
      // () => {
      //   isDEV() && console.log(this.state.details);
      // }
    );
  };

  forgtPswrd = () => {
    this.props.history.push("/forgotpassword");
  };

  enterLoading = () => {
    this.setState({ loading: true });
  };

  componentWillReceiveProps(newProps) {
    isDEV() && console.log("new");
    if (!Object.is(newProps.errorMessage, this.props.errorMessage)) {
      this.setState({
        showWrongPasswordMessage: true
      });
    }
  }

  componentDidMount() {
    function getQueryStringValue(key) {
      return decodeURIComponent(
        window.location.search.replace(
          new RegExp(
            "^(?:.*[&\\?]" +
            encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") +
            "(?:\\=([^&]*))?)?.*$",
            "i"
          ),
          "$1"
        )
      );
    }

    let token = getQueryStringValue("token");
    let gatewayMac = getQueryStringValue("gatewayMac");
    let clientIp = getQueryStringValue("clientIp");
    let clientMAC = getQueryStringValue("clientMAC");
    isDEV() && console.log(token, "tok", gatewayMac, "gatewayMac");
    isDEV() && console.log(clientIp, "clientIp", clientMAC, "clientMAC");
    if (token) {

      localStorage.setItem('token', token);
      localStorage.setItem('gatewayMac', token);
      localStorage.setItem('clientIp', token);
      localStorage.setItem('clientMAC', token);
      this.setState({

        token: token,
        gatewayMac: gatewayMac,
        clientIp: clientIp,
        clientMAC: clientMAC


      })
    }
  }

  onLoggedIn = e => {
    isDEV() && console.log(e);
    // this.setState({ nonmatchingPassword: false })
    // this.setState(prevState => ({ show: !prevState.show }), () => { isDEV() && console.log(this.state.show) });
    if (
      this.state.details.email &&
      this.state.details.password &&
      this.state.details.email != "" &&
      this.state.details.password != ""
    ) {
      //  this.setState(prevState => ({ show: !prevState.show,
      //           showWrongPasswordMessage:!prevState.showWrongPasswordMessage
      //          }), () => { isDEV() && console.log(this.state.show) });
      // //   this.setState({ nonmatchingPassword: true })
      // }

      // else {
      this.setState({ showWrongPasswordMessage: false });
      this.props.actions.signIn(
        this.state.details.email,
        this.state.details.password,
        this.props.history
      );
      isDEV() && console.log("user loggingin.......75");
    }
  };

  render() {
    // const { getFieldDecorator } = this.props.form;
    return (
      <div className="signupform mobileviewclass signinform">
        <Container>
          <div className="logoare">
            <NavLink to="/home">
              <img src={logo} />
            </NavLink>
          </div>
          <div className="sub-inerfrm signin-subfrm">
            <Grid>
              <Grid.Row columns={8}>
                <Grid.Column
                  computer={6}
                  mobile={0}
                  tablet={6}
                  className="hidden-xs"
                >
                  <div className="loginformimg">
                    <Image src={Shadecharger} />
                    {/* <NavLink to="/home">
                                        <div className="bacbutnarea">
                                            <p className="homeicnarea">
                                        <img src={homeicn} className="homeicn"/>
                                        </p>
                                        <p className="backhomelink"> Back to home</p>
                                        </div>
                                        </NavLink> */}
                  </div>


                </Grid.Column>

                <Grid.Column
                  computer={10}
                  mobile={16}
                // tablet={10}
                >
                  <div className="formfieldarea">
                    <div className="frm-heading hidden-xs">
                      <h2>Sign in</h2>
                      <p className="fornewusr">
                        New user?{" "}
                        <NavLink to="/signup">
                          <span className="signuplink">Sign up</span>
                        </NavLink>
                      </p>
                      {/* <p>{this.state.gatewayMac}</p>
                  <p>{this.state.token}</p>
                  <p>{this.state.clientIp}</p>
                  <p>{this.state.clientMAC}</p> */}
                    </div>
                    {/* {this.state.showWrongPasswordMessage && (
                      <p className="incorrect">
                        Incorrect email or password, Please try again.
                      </p>
                    )} */}
                    {/* <div> */}
                  {this.props.errorMessage && 
                   <p className="incorrect"> {this.props.errorMessage.message} </p>
                   }
                    {/* </div> */}
                    <Form className="formfield emailfield">
                    
                      <Form.Field>
                        <label className="labelnme">Email</label>
                        <Input
                          iconPosition="left"
                          onChange={this.textInput}
                          name={"email"}
                          defaultValue="demo@shadecharger.net"
                        />
                      </Form.Field>

                      <Form.Field style={{ position: "relative" }}>
                        <label className="labelnme">Password</label>
                        <Input
                          iconPosition="left"
                          input={"password"}
                          onChange={this.textInput}
                          name={"password"}
                          defaultValue="demo"
                        />
                        <span className="passwordinfo">
                          The password is "demo"
                        </span>
                        {/* </Input> */}
                      </Form.Field>
                      {/* <NavLink to="/forgotpassword"> */}

                      <p className="frgtpswrdtxt">
                        <span onClick={this.forgtPswrd}>Forgot Password</span>
                      </p>
                      {/* </NavLink> */}
                      <div className="formbutn">
                        {/* <NavLink to="/home"> */}
                        <Button onClick={this.onLoggedIn} type="submit">
                          Sign In
                        </Button>
                        {/* </NavLink> */}
                      </div>
                      <div className="hidden-md">
                        <p className="fornewusr">
                          New user?{" "}
                          <NavLink to="/signup">
                            <span className="signuplink">Sign up</span>
                          </NavLink>
                        </p>
                        {/* <NavLink to="/home">
                                                <p className="backhomelink"><img src={homeicn} className="homeicn"/> Back to home</p>
                                            </NavLink> */}
                      </div>
                    </Form>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Container>
      </div>
    );
  }
}
// export default SignIn;
// const WrappedSignIn = Form.create({ name: "horizontal_signin" })(SignIn);

const mapStateToProps = state => {
  return state;
};

function mapDispatchToProps(dispatch, state) {
  return {
    actions: bindActionCreators(actioncreators, dispatch)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SignIn)
);
