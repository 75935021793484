
import React, { Component } from 'react';
import './Main.css';
import success from '../../Images/success.png'
import { Button } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import error from '../../Images/error.png'
import warning from '../../Images/warning.png'

type Props = {
  alertcontext: string, alerticon: string, alerticoncls: string, alertcontntclass: string, alertbutton: string,
  alertbutncls: string, imgtype: string, alertheading:string, alertheaderlass:string, butnFunction:Function,
}

class Alert extends Component<Props, State>{
  static propTypes = {
    alertcontext: PropTypes.string, alerticon: PropTypes.string, alerticoncls: PropTypes.string, alertheading:PropTypes.string,
    alertcontntclass: PropTypes.string, alertbutton: PropTypes.string, alertbutncls: PropTypes.string, imgtype: PropTypes.string,
    alertheaderlass:PropTypes.string, butnfunction:PropTypes.func,
  }
  render() {
    return (
      <div className="alertarea">
        {/* <div className="alert-inerarea"> */}
        <div className={'alertcontent' + ' ' + this.props.alertcontntclass}>
          <div className="alertimgarea">
            {this.props.imgtype === "error" ?
              <img src={error} className="alerticon" /> :
              this.props.imgtype === "success" ?
              <img src={success} className="alerticon" />:
              (this.props.imgtype === "warning" ?
              <img src={warning} className="alerticon" />
              :null)
            }
          </div>
          
            <h3 className="alertheader">{this.props.alertheading}</h3>
          <p className="alertcontextline">{this.props.alertcontext}</p>
          <div className="alertbutnarea"><Button onClick={this.props.butnfunction} className={'alertbutton' + ' ' + this.props.alertbutncls}>
            {this.props.alertbutton}</Button></div>
        </div>
        {/* </div> */}
      </div>
    );
  }
}

export default Alert;
