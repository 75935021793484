
// import * as moment from 'moment';
function tableDetails(tableNumber) {
  console.log(tableNumber, ">>>>>>L3in action")

  return new Promise(function (resolve, reject) {
    if (tableNumber) {
      const reqValues = {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      };
      fetch(process.env.REACT_APP_apiurl + "/table/find?id=" + tableNumber.toString(), reqValues)
        .then(result => result.json())
        .then(result => {

          console.log(result)

          resolve(result)

        })
        .catch(err => {
          console.log(err);
          reject(err)
        });
    }
    else {
      reject({ error: true, message: "No table id" })
    }
  })
}


// export function editProfile( values,id) {
//   console.log(values);
//   return (dispatch, getState) => {
//     const reqValues = {
//       method: "PUT",
//       body: JSON.stringify({
//         // enabled:"superadmin",
//         firstName: values.firstName,
//         lastName: values.lastName,
//         email: values.email,
//         phone: values.phone,
//         dob: values.dob



//       }),
//       headers: {
//         "Content-Type": "application/json"
//       }
//     };
//     fetch(process.env.REACT_APP_apiurl + "/user/updateuser?id=" + id, reqValues)
//       .then(result => result.json())

//       .then(result => {
//         console.log(result);
//      // dispatch in userdetail for superadmin

//      dispatch(userDetail(result.result));
//       })
//       .catch(err => {
//         console.log("Error", err);
//       });
//   };
// }


export function createTable(result) {
  console.log("in action 868", result)
  return (dispatch, getState) => {
    console.log(result);
    console.log(getState());
    let arr = [...getState().tableList];

    console.log(arr)

    let newArr = [...arr, result.result]
    console.log(newArr)
    
    dispatch(tableList(newArr))

    // dispatch(tableList(result.result))
  }
}



export function editTable(result) {
  console.log("in action 868", result)
  return (dispatch, getState) => {
    console.log(result);
    console.log(getState());

    var index = getState().tableList.findIndex(x => x._id === result._id);

    let list = [...getState().tableList];
    console.log(list[index]);
    list[index] = result;
    // let arr = [...getState().tableList];

    // console.log(arr)

    // let newArr = [...arr, result.result]
    // console.log(newArr)
    // dispatch(tableList(newArr))
    console.log(list)
    dispatch(tableList(list))
  }
}



export function selectedCompanyByAdmin(id) {

  return (dispatch, getState) => {

    console.log(getState());


    let arr = getState().tableList;

   var filteredCompanyList= arr.filter(x=>{
      return x.companyId===id
    })

 
    dispatch(tableList(filteredCompanyList))


    let companies= getState().companyList;

    var selectedCompany=companies.find(x=>{
      return x._id===id
    })
    dispatch(selectedCompanyAdmin(selectedCompany))
  }
}


function selectedCompanyAdmin(data) {
  return {
    type: "SELECTED_COMPANY_BY_ADMIN",
    data: data
  };
}



export function getTableDetails(location) {
  // console.log(location)
  return (dispatch, getState) => {
    const reqValues = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    };
    fetch(process.env.REACT_APP_apiurl + "/table/find?id=" + sessionStorage.getItem("tableId"), reqValues)
      .then(result => result.json())
      .then(result => {
        console.log("get table details");
        console.log(result, getState());
        dispatch(selectedTable(result.result));
        // dispatch(workerReport(result))
      })
      .catch(err => {
        // console.log(err);
        return err;
      });
  };
};

export function getTableList() {

  return (dispatch, getState) => {
    const reqValues = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    };
    fetch(process.env.REACT_APP_apiurl + "/table/findAll", reqValues)
      .then(result => result.json())
      .then(result => {
        console.log(result);
        dispatch(tableList(result.result));
      })
      .catch(err => {
        console.log(err);
      });
  };
}

// export function createTable(values) {
//   return (dispatch, getState) => {
//     console.log(values);
//     const reqValues = {
//       method: "POST",
//       body: JSON.stringify(values
//         // tableNumber: values.tableNumber,
//         // network: values.network,
//         // services: values.services,
//         // location: values.location,
//         // status: values.status,
//         // createdDate: values.createdDate

//       ),
//       headers: {
//         "Content-Type": "application/json"
//       }
//     };

//     fetch(process.env.REACT_APP_apiurl + "/table/create", reqValues)

//       .then(result => result.json())
//       .then(result => {
//         console.log(result);
//         console.log(getState());
//         let arr = [...getState().tableList];

//         console.log(arr)

//         let newArr = [...arr, result.result]
//         console.log(newArr)
//         dispatch(tableList(newArr))

//       })
//       .catch(err => {
//         console.log(err);
//       });
//   };
// }
// export function deleteTable(id) {
//   console.log(id);
//   return (dispatch, getState) => {
//     const reqValues = {
//       method: "PUT"
//     };
//     fetch(process.env.REACT_APP_apiurl + "/table/delete?id=" + id, reqValues)
//       .then(result => result.json())

//       .then(result => {
//         console.log(result);
//         console.log(getState().tableList);

//         var index = getState().tableList.findIndex(x => x._id === id);
//         console.log(index, typeof (index))
//         //Immutability!!
//         let list = [...getState().tableList];
//         console.log(list, "list", typeof (list))
//         list.splice(index, 1);
//         console.log(list, "list", Array.isArray(list));
//         // console.log(getState());
//         dispatch(tableList(list));
//       })
//       .catch(err => {
//         console.log("Error", err);
//       });
//   };
// }



function tableList(data) {
  return {
    type: "TABLE_LIST",
    data: data
  };
}

// export function getTableDetailschecktabledetails(location) {
//   // console.log(location)
//   return (dispatch, getState) => {
//     const reqValues = {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json"
//       }
//     };
//     fetch(process.env.REACT_APP_apiurl + "/table/find?id=" + "siu8iD", reqValues)
//       .then(result => result.json())
//       .then(result => {
//         console.log("Image update done?? //from action");
//         console.log(result, getState());
//         dispatch(selectedTable(result.result));
//         // dispatch(workerReport(result))
//       })
//       .catch(err => {
//         console.log(err);
//       });
//   };
// }


export function saveTableData(data, location) {
  console.log(data);
  return (dispatch) => {
    
       dispatch(selectedTable(data));

       location.push("/Home/info");

  };
}


function selectedTable(data) {
  return {
    type: "SELECTED_TABLE",
    data: data
  };
}

// import { userDetail } from "./reducers/userDetail";


////// Adding Pratibha   //////////////

// export function editUser(x, details, location) {
//   console.log(x, details, "edit profile in action");

//   return (dispatch, getState) => {
//     const reqValues = {
//       method: "PUT",
//       body: JSON.stringify({
//         // id: details._id,
//         firstName: details.firstName,
//         lastName: details.lastName,
//         email: details.email,
//         phone: details.phone,
//         dob: details.dob
//       }),
//       headers: {
//         "Content-Type": "application/json",
//       }
//     };
//     fetch(process.env.REACT_APP_apiurl + "/user/update?id=" + x, reqValues)
//       .then(result =>
//         // console.log(result))
//         result.json()
//       )
//       .then(result => {
//         console.log(result);
//         // console.log(getState());
//         if (result.message === "User Profile updated Successfully") {
//           dispatch(usersList(result.result));
//           // location.push("/home/Profile");
//         }
//       })
//       .catch(err => {
//         console.log("Error", err);
//       });
//   };
// }





export function createUser(values, location, from) {
  return (dispatch, getState) => {
    console.log(values);
    const reqValues = {
      method: "POST",
      body: JSON.stringify({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        dob: values.dob,
        // address: values.address,
        password: values.password,
        // credit: values.credit,
        // location: values.location,
        // profileImage: values.profileImage,
        // createdDate: values.createdDate,
        // updatedDate: values.updatedDate,
        confirmPassword: values.confirmPassword,

      }),
      headers: {
        "Content-Type": "application/json"
      }
    };

    fetch(process.env.REACT_APP_apiurl + "/user/create", reqValues)
      .then(result => result.json())
      .then(result => {
        console.log(result);

        if (
          result.message &&
          result.message === "Successfully Created The User Profile"
        ) {

          // // console.log()
          // location.push({
          //   pathname: "/Home",
          //   from: "SignUp",
          //   data: values,
          // })

          if (from === "admin") {
            let arr = [...getState().usersList]
            arr.push(result.result)
            dispatch(usersList(arr))
          }
          else {
            localStorage.setItem('token', result["jwt-Token"]);

            dispatch(userDetail(result.result));

            if (sessionStorage.getItem("tableId")) {
              checkTestUsage(sessionStorage.getItem("tableId"), result.result._id, dispatch);

              tableDetails(sessionStorage.getItem("tableId")).then(x => {
                console.log(x);
                if (!x.error) {
                  dispatch(selectedTable(x.result));
                  location.push("/Home/info")
                }
                else {
                  location.push("/summary")
                }
              })
            }
            else {
              location.push("/summary")
            }
          }
        }

      })
      .catch(err => {
        console.log(err);
      });
  };
}


function userDetail(data) {
  console.log(data);
  return {
    type: "LOGGED_USER_DETAILS",
    data: data
  };
}

export function signIn(email, password, location) {
  console.log(email, password);
  return dispatch => {
    //authentication function
    const reqValues = {
      method: "POST",
      body: JSON.stringify({
        email: email,
        password: password
      }),

      headers: {
        "Content-Type": "application/json"
      }
    };
    fetch(
      process.env.REACT_APP_apiurl +
      "/user/login",
      reqValues
    )
      .then(
        result =>

          result.json()

      )

      .then(result => {
        console.log(result);
        if (result.message && result.message === "Log in Successfully!") {



          localStorage.setItem('token', result["jwt-Token"]);
          localStorage.setItem('role', result.result.role);
          dispatch(userDetail(result.result));

          if (result.result.role === "admin") {
            location.push("/adminmain");
          }
          else if (result.result.role === "companyAdmin") {
            console.log("companyAdmin,L442>>>")
            location.push("/adminmain");
          }
          else {
            if (sessionStorage.getItem("tableId")) {
              checkTestUsage(sessionStorage.getItem("tableId"), result.result._id, dispatch);

              tableDetails(sessionStorage.getItem("tableId")).then(x => {
                console.log(x);
                if (!x.error) {
                  dispatch(selectedTable(x.result));
                  location.push("/Home/info")
                }
                else {
                  location.push("/summary")
                }
              })
            }
            else {
              location.push("/summary")
            }

          }
          // location.push("/Home");

        }
        else {
          let data = {
            error: result.error,
            time: Date.now(),
            message: result.message
          }
          dispatch(errorMessage(data))
        }
        // else dispatch toast> wrong user email password
      })
      .catch(err => {
        console.log(err);
        // if (err.message === " Already applied to this job!") {
        //   dispatch(errorMessage(err.message));
        // } else {
        //   dispatch(errorMessage(" Already applied to this job!"));
        // }
      });
  }
}

export function errorMessage(data) {
  return {
    type: "error_message",
    data: data
  };
}

function checkTestUsage(tableId, userId, dispatch) {
  console.log("fcgvhbjklmjhvbnm,");
  fetch(process.env.REACT_APP_apiurl + '/testcharge?userId=' + userId + '&tableId=' + tableId).then(response =>
    // console.log(response)
    response.json()
  ).then(data => {
    if (data && !data.error) {
      dispatch(testChargeMessage(true));
      console.log(data);
    }
    else
      dispatch(testChargeMessage(null));
  }).catch(err => {
    console.log(err);
  })

}

export function stopTestChargeMessage() {
  return dispatch => {
    dispatch(testChargeMessage(null));
  }
}

function testChargeMessage(data) {
  return {
    type: "test_charge_message",
    data: data
  };
}

export function resumeSession(token, location, tableId) {
  console.log(token, "in resume session", tableId);
  return dispatch => {
    //authentication function
    const reqValues = {
      method: "GET",

      headers: {
        "Content-Type": "application/json",
        "authorization": localStorage.getItem("token")
      }
    };
    fetch(
      process.env.REACT_APP_apiurl +
      "/user/resume",
      reqValues
    )
      .then(
        result =>

          result.json()

      )

      .then(result => {
        console.log(result);
        if (!result.error) {


          dispatch(userDetail(result.result));

          if (result.result.role === "admin") {
            // localStorage.setItem("userId", result.result._id);
            location.push("/adminmain");
          }
          else if (result.result.role === "companyAdmin") {
            // localStorage.setItem("userId", result.result._id);
            location.push("/adminmain");
          }
          else {
            // if(tableId){
            tableDetails(tableId).then(x => {
              console.log(x);
              if (!x.error) {
                sessionStorage.setItem("tableId", tableId)
                dispatch(selectedTable(x.result));
                location.push("/Home/info");

              }
              else {
                location.push("/Home/info");
              }

            })
            // }
            // else{
            //   location.push("/summary");
            // }


          }
          // location.push("/Home");

        }
        else {
          location.push('/signin')
        }
        
      })
      .catch(err => {
        console.log(err);
      });
  }
}


export function getLoggedinUserDetails(location) {

  return dispatch => {
    //authentication function
    const reqValues = {
      method: "GET",

      headers: {
        "Content-Type": "application/json",
        "authorization": localStorage.getItem("token")
      }
    };
    fetch(
      process.env.REACT_APP_apiurl +
      "/user/resume",
      reqValues
    )
      .then(
        result =>

          result.json()

      )

      .then(result => {
        console.log(result);
        if (!result.error) {


          dispatch(userDetail(result.result));



        }
        else {
          location.push("/signin")
          let data = {
            error: result.error,
            time: Date.now(),
            message: result.message
          }
          dispatch(errorMessage(data))
        
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
}
// getLoggedinUserDetails

export function getUsersList(token) {

  return (dispatch, getState) => {
    const reqValues = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "authorization": localStorage.getItem('token')
      }
    };
    fetch(process.env.REACT_APP_apiurl + "/user/getAll", reqValues)
      .then(result => result.json())
      .then(result => {
        console.log(result);
        dispatch(usersList(result.result));
      })
      .catch(err => {
        console.log(err);
      });
  };
}

export function deleteUser(id) {
  console.log(id);
  return (dispatch, getState) => {
    const reqValues = {
      method: "PUT"
    };
    fetch(process.env.REACT_APP_apiurl + "/user/delete?id=" + id, reqValues)
      .then(result => result.json())

      .then(result => {
        console.log(result);
        console.log(getState().usersList);

        var index = getState().usersList.findIndex(x => x._id === id);
        console.log(index, typeof (index))
        //Immutability!!
        let list = [...getState().usersList];
        console.log(list, "list", typeof (list))
        list.splice(index, 1);
        console.log(list, "list", Array.isArray(list));
        // console.log(getState());
        dispatch(usersList(list));
      })
      .catch(err => {
        console.log("Error", err);
      });
  };
}





export function userDetailDispatch(data) {

  return (dispatch, getState) => {


    dispatch(userDetail(data));
  };
}


export function usersList(data) {
  return {
    type: "USERS_LIST",
    data: data
  };
}


export function editUserProfile(x, details, location, from) {
  console.log(x, details, "edit profile in action");

  return (dispatch, getState) => {
    const reqValues = {
      method: "PUT",
      body: JSON.stringify({
        // id: details._id,
        firstName: details.firstName,
        lastName: details.lastName,
        email: details.email,
        password: details.password,
        dob: details.dob
      }),
      headers: {
        "Content-Type": "application/json",
      }
    };
    fetch(process.env.REACT_APP_apiurl + "/user/update?id=" + x, reqValues)
      .then(result =>
        // console.log(result))
        result.json()
      )
      .then(result => {
        console.log(result);
        // console.log(getState());
        if (result.message === "User Profile updated Successfully") {
          // dispatch(userDetail(result.result));
          if (from === "admin") {
            let arr = [...getState().usersList]
            arr.push(result.result)
            dispatch(usersList(arr))
          }
          else {
            location.push("/home/Profile");
            dispatch(userDetail(result.result));
          }
          // }
          //   location.push("/home/Profile");
        }
      })
      .catch(err => {
        console.log("Error", err);
      });
  };
}






/* 
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                      ORDER Related Functions START
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  */

 

export function createOrder(data, location) {

  console.log(data, "L386>>>>>>creating order data in action")

  return dispatch => {

    const reqValues = {
      method: "POST",
      body: JSON.stringify(data),

      headers: {
        "Content-Type": "application/json"
      }
    };
    fetch(
      process.env.REACT_APP_apiurl +
      "/order/create",
      reqValues
    )
      .then(
        result =>

          result.json()

      )

      .then(result => {
        console.log(result);
        if (result && result.result) {

          let obj = result.result;
          obj.total = result.total;
          dispatch(currentOrder(obj));
          location.push("/payment")

        }
      })
      .catch(err => {
        console.log(err);
      });
  }
}

export function startService(id) {


  return dispatch => {

    const reqValues = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    };
    fetch(
      process.env.REACT_APP_apiurl +
      "/control?order=" + id,
      reqValues
    )
      .then(
        result =>

          result.json()

      )

      .then(result => {
        console.log(result);
        if (result && result.result) {
          // dispatch(currentOrder(result.result));

        }
      })
      .catch(err => {
        console.log(err);
      });
  }
}


function currentOrder(data) {

  return {
    type: "CURRENT_ORDER",
    data: data
  };
}



export function getOrderHistory(numberofOrders, numberofPage) {

  let nPerPage = numberofOrders || 3,
    numberofPages = numberofPage || 1;
  return (dispatch, getState) => {
    const reqValues = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "authorization": localStorage.getItem('token')
      }
    };
    fetch(process.env.REACT_APP_apiurl + "/order/findbyuser?nPerPage=" + nPerPage + "&pageNumber=" + numberofPages, reqValues)
      .then(result => result.json())
      .then(result => {
        console.log(result);
        dispatch(ordersList(result.result));
      })
      .catch(err => {
        console.log(err);
      });
  };
}


function ordersList(data) {
  return {
    type: "ORDER_LIST",
    data: data
  };
}






/* 
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                      ORDER Related Functions END
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  */


export function getPaymentList() {

  return (dispatch, getState) => {
    const reqValues = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    };
    fetch(process.env.REACT_APP_apiurl + "/payment/findAll", reqValues)
      .then(result => result.json())
      .then(result => {
        console.log(result);
        dispatch(paymentList(result.result));
      })
      .catch(err => {
        console.log(err);
      });
  };
}

function paymentList(data) {
  return {
    type: "PAYMENTS_LIST",
    data: data
  };
}
export function deletePayment(id) {
  console.log(id);
  return (dispatch, getState) => {
    const reqValues = {
      method: "PUT"
    };
    fetch(process.env.REACT_APP_apiurl + "/payment/delete?id=" + id, reqValues)
      .then(result => result.json())

      .then(result => {
        console.log(result);
        console.log(getState().paymentList);

        var index = getState().paymentList.findIndex(x => x._id === id);
        console.log(index, typeof (index))
        //Immutability!!
        let list = [...getState().paymentList];
        console.log(list, "list", typeof (list))
        list.splice(index, 1);
        console.log(list, "list", Array.isArray(list));
        // console.log(getState());
        dispatch(paymentList(list));
      })
      .catch(err => {
        console.log("Error", err);
      });
  };
}

function voucherList(data) {
  return {
    type: "VOUCHER_LIST",
    data: data
  }
}

export function createVoucher(values) {
  return (dispatch, getState) => {
    console.log(values);
    const reqValues = {
      method: "POST",
      body: JSON.stringify(
        values
        //   {
        //   identifierId: values.identifierId,
        //   expiry: values.expiry,
        //   value: values.value
        //   // voucherno: values.voucherno

        // }
      ),
      headers: {
        "Content-Type": "application/json",
        "authorization": localStorage.getItem('token')
      }
    };

    fetch(process.env.REACT_APP_apiurl + "/voucher/create", reqValues)
      .then(result => result.json())
      .then(result => {
        console.log(result);

        if (
          result.message &&
          result.message === "Voucher created Successfully"
        ) {
          // console.log(getState());
          let arr = [...getState().voucherList];

          // console.log(arr)

          let newArr = [...arr, result.result]
          // console.log(newArr)
          // dispatch(voucherList(newArr))

          dispatch(voucherList(newArr));
        }


      })
      .catch(err => {
        console.log(err);
      });
  };
}

export function getVouchers() {
  console.log("voucherList in action>>>>>>>..")
  return (dispatch, getState) => {
    
    const reqValues = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token")
      }
    };
    fetch(process.env.REACT_APP_apiurl + "/voucher/findAll", reqValues)
      .then(result => result.json())
      .then(result => {
        console.log(result);
        //   dispatch(usersList(result.result));
        if (result.error) {
          alert("Error while searching for vouchers -" + result.message);
        } else {
          // this.setState({ data: result.result });
          dispatch(voucherList(result.result))
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
}








////////////////////////////////// edit Pratibha for Company Functionallity//////////////////////////////////////////
export function findTableListByCompany(id) {
  console.log(id, "1072 >>>>>>>>>>>in action");
  return (dispatch, getState) => {
    const reqValues = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",

      }
    };
    fetch(process.env.REACT_APP_apiurl + "/table/findByCompany?companyId=" + id, reqValues)

      .then(result => result.json())
      .then(result => {
        console.log(result);

        // let posts = getState().tableList;
        // if (!result.error) {
        //   posts.push(result.result)
        //   dispatch(tableList(posts))
    
        // }

        if (!result.error) {
          dispatch(tableList(result.result));
        }

      })
      .catch(err => {

        console.log(err);
      })
  };

}


function companyList(data) {
  return {
    type: "COMPANY_LIST",
    data: data
  }
}

export function getCompanyList() {

  return (dispatch, getState) => {
    const reqValues = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token")
      }
    };
    fetch(process.env.REACT_APP_apiurl + "/company/getAll", reqValues)
      .then(result => result.json())
      .then(result => {
        console.log(result);
        // //   dispatch(usersList(result.result));
        if (!result.error) {

          dispatch(companyList(result.result))

        }
      })
      .catch(err => {
        console.log(err);
      });
  };
}


export function createCompany(details) {
  console.log(details,"<<<<1188 in action")
  return (dispatch, getState) => {
    const reqValues = {
      method: "POST",
      body: JSON.stringify(details),
      headers: {
        "Content-Type": "application/json"
      }
    };
    fetch(process.env.REACT_APP_apiurl + "/company/create", reqValues)
      .then(result => result.json())
      .then(result => {

        console.log(result)
        // console.log(details,"<<<<< in action 1202")
        if (!result.error) {
          console.log(getState().companyList);
          let arr = [...getState().companyList];

          console.log(arr)

          let x = result.result.company;
          x.admin = {};
          x.admin.firstName = result.result.companyAdmin.firstName;
          x.admin.lastName = result.result.companyAdmin.lastName;
          x.admin.email = result.result.companyAdmin.email;
          x.admin.phoneNumber = result.result.companyAdmin.phoneNumber;
          // x.admin.email = result.result.companyAdmin.email;
          // let x = result.result.admin;
          // x = {};
          // x.firstName = result.result.admin.firstName;
          // x.lastName  = result.result.admin.lastName;

          let newArr = [...arr,x]
          console.log(newArr)
          dispatch(companyList(newArr))
          // location.push("/adminmain")

        // }
      }
      })
      .catch(err => {
        console.log(err)
      });
  };
}
// export function deleteCompany(id) {
//   console.log(id);
//   return (dispatch, getState) => {
//     const reqValues = {
//       method: "PUT"
//     };
//     fetch(process.env.REACT_APP_apiurl + "/company/delete?id=" + id, reqValues)
//       .then(result => result.json())

//       .then(result => {
//         console.log(result);
//         console.log(getState().companyList);

//         var index = getState().tableList.findIndex(x => x._id === id);
//         console.log(index, typeof (index))
//         //Immutability!!
//         let list = [...getState().companyList];
//         console.log(list, "list", typeof (list))
//         list.splice(index, 1);
//         console.log(list, "list", Array.isArray(list));
//         // console.log(getState());
//         dispatch(companyList(list));
//       })
//       .catch(err => {
//         console.log("Error", err);
//       });
//   };
// }


export function editCompany(details) {

  return (dispatch, getState) => {
    console.log(details);
    console.log(getState());

    var index = getState().companyList.findIndex(x => x._id === details._id);

    let list = [...getState().companyList];
    console.log(list[index]);
    list[index] = details;

    console.log(list)
    dispatch(companyList(list))
  }
}

export function getAllPaidOrders(){

  return (dispatch, getState) => {
    const reqValues = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token")
      }
    };
    fetch(process.env.REACT_APP_apiurl +  "/order/get", reqValues)
      .then(result => result.json())
      .then(result => {
        console.log(result);
        // //   dispatch(usersList(result.result));
        if (!result.error) {

          dispatch(ordersList(result.result))

        }
      })
      .catch(err => {
        console.log(err);
      });
  };


}

//To get order by particular company id
export function getParticularCompanyOrder(id){

  console.log("id>>>>>>>>>>>>>>>"+id)

  return (dispatch, getState) => {
    const reqValues = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // authorization: localStorage.getItem("token")
      }
    };
    fetch(process.env.REACT_APP_apiurl +  "/order/findByCompany?companyId="+id, reqValues)
      .then(result => result.json())
      .then(result => {
        // console.log("get particular company order"+JSON.stringify(result));
        // //   dispatch(usersList(result.result));
        if (!result.error) {

          dispatch(orderParticularCompanyList(result.result))

        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  function orderParticularCompanyList(data){

    console.log(data)
    return {
      type: "ORDER_PARTICULAR_COMPANY_LIST",
      data: data
    }
  
  }

}

// Action for selecting company data
export function storeCompanySelectionData(data) {

  console.log("in action 868", data)
  return (dispatch, getState) => {

    dispatch(saveSelectedCompanyData(data))
  
  };

  //To send type to reducers
  function saveSelectedCompanyData(data){

    console.log(data)
    return {
      type: "STORE_SELECTED_COMPANY_DATA",
      data: data
    }
  
  }
}
////////////////////////////////////////// add Pratibha for update status/////////////////////////
export function setCompanyStatus(result) {

  return (dispatch, getState) => {
    console.log(result);
    console.log(getState());

    var index = getState().companyList.findIndex(x => x._id === result._id);

    let list = [...getState().companyList];
    console.log(list[index]);
    list[index] = result;

    console.log(list)
    dispatch(companyList(list))
  }
}

export function setUsersStatus(result) {

  return (dispatch, getState) => {
    console.log(result);
    console.log(getState());

    var index = getState().usersList.findIndex(x => x._id === result._id);

    let list = [...getState().usersList];
    console.log(list[index]);
    list[index] = result;

    console.log(list)
    dispatch(usersList(list))
  }
}

export function setTablesStatus(result) {

  return (dispatch, getState) => {
    console.log(result);
    console.log(getState());

    var index = getState().tableList.findIndex(x => x._id === result._id);

    let list = [...getState().tableList];
    console.log(list[index]);
    list[index] = result;

    console.log(list)
    dispatch(tableList(list))
  }
}



///////////////////////////////////////////////////////////////////////////////////////////////

/////////////////////////adding Pratibha for feedBack /////////////////////////////////////////



function feedBacklist(data){
  console.log(data)
  return {
    type: "FEEDBACKS_LIST",
    data: data
  }

}


export function createFeedBack(result) {

  return (dispatch, getState) => {
    console.log(result);
    let postfeedbacks = getState().feedBacklist;
    if (result) {
      postfeedbacks.push(result)
      dispatch(feedBacklist(postfeedbacks))

    }
   
    // dispatch(feedBacklist(result))
  }
}



export function getAllFeedBacks(){

  return (dispatch, getState) => {
    const reqValues = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        
      }
    };
    fetch(process.env.REACT_APP_apiurl +  "/feedBack/getFeedBacks", reqValues)
      .then(result => result.json())
      .then(result => {
        console.log(result);
        console.log(getState().feedBacklist);
        // //   dispatch(usersList(result.result));
        if (!result.error) {

          dispatch(feedBacklist(result.result))

        }
      })
      .catch(err => {
        console.log(err);
      });
  };


}


export function updateFeedback(result) {
  console.log(result);
  return dispatch => {
    dispatch(feedBacklist(result));
  }
}


////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////Adding Pratibha for GET voucher By User ///////////////////////////////

export function getVoucherByUser () {

  return (dispatch, getState) => {
    const reqValues = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token")
      }
    };
    fetch(process.env.REACT_APP_apiurl +  "/voucher/findByUser", reqValues)
      .then(result => result.json())
      .then(result => {
        console.log(result);
        console.log(getState().voucherList);
        
        if (!result.error) {

          dispatch(voucherList(result.result))

        }
      })
      .catch(err => {
        console.log(err);
      });
  };


}


////////////////////////////////////////////////////////////////////////////////////////////////////

