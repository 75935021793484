import React, { Component } from 'react';
import '../Home/Home.css';
import './Admin.css';
import 'semantic-ui-css/semantic.min.css';
import { Grid, Image, Button, Dropdown, Menu } from 'semantic-ui-react'
import { withRouter, NavLink } from 'react-router-dom';
import logo from '../../Images/bluelogo.png';
import menubar from '../../Images/menu.png'
import user from '../../Images/user.png'
// import { bindActionCreators } from 'redux';
// import { connect } from 'tls';
// import {connect}  from 'react-redux';
// import * as actioncreateors from '../../redux/action';
import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";




class AdminHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTop: true,
            open: false,
            disabled: false
        }

    }

    disable = () => {
        this.setState({
            disabled: true
        })
    }

    logout = () => {
        localStorage.clear();
        sessionStorage.clear();
        console.log("Logging ouT!!!!!!!!!!!!")
        this.props.history.push("/signin")
    }


    menuOpen = () => {
        this.setState({ open: !this.state.open });
    }
    componentDidMount() {
        document.addEventListener('scroll', () => {
            const isTop = window.scrollY < 100;
            if (isTop !== this.state.isTop) {
                this.setState({ isTop })
            }
        });
    }
    render() {

        const text1 = [
            <div>
                <NavLink to='/home/profile'>
                    <p>Profile</p>
                </NavLink>
            </div>
        ]
        const text2 = [
            <div onClick={this.logout}>
                {/* <NavLink> */}
                <p>Logout</p>
                {/* </NavLink> */}
            </div>
        ]
        const options = [
            { key: 1, text: text1, value: 1 },
            { key: 2, text: text2, value: 2 },
            // { key: 3, text: 'Choice 3', value: 3 },
        ]
        const { user } = this.state;
        // let backgroudcolor = this.state.isTop ? '#00000066' : 'white';
        let backgroudcolor = this.state.isTop ? 'white' : 'white';
        // let color = this.state.isTop ? '#e4e4e4' : 'black';
        let color = this.state.isTop ? 'black' : 'black';
        let boxshadow = this.state.isTop ? 'rgba(0, 0, 0, 0.13) 0px 5px 15px 0px' : 'rgba(0, 0, 0, 0.13) 0px 5px 15px 0px';
        // let bordercolor = this.state.isTop ? 'white' : 'black';
        let bordercolor = this.state.isTop ? 'black' : 'black';

        return (
            <div className="headerstart adminheader" style={{ top: '0', color: color, boxShadow: boxshadow }}>
                <Grid>
                    <Grid.Column mobile={16} tablet={8} computer={3}>
                        <div className="headerlogo">
                            <NavLink to="/home"> <img src={logo} className="headerlogoimg" /></NavLink>
                            <img src={menubar} className="hidden-md menuimg" onClick={this.menuOpen.bind(this)} />
                        </div>
                    </Grid.Column>
                    <Grid.Column mobile={0} tablet={8} computer={13} className="hidden-xs">
                        <div className="navitems hidden-xs">
                            <ul className="navullist" style={{ color: color }}>
                                {this.props.userDetail.role === "admin" &&
                                    <NavLink to="/adminmain">
                                        <li className="homenav">Company List</li>
                                    </NavLink>
                                }
                               {this.props.userDetail.role === "admin" ?
                                <NavLink to="/adminmain/tablelist">
                                <li className="homenav">Table List</li>
                            </NavLink>:
                                <NavLink to="/adminmain">
                                    <li className="homenav">Table List</li>
                                </NavLink>
                               }
                                {/* <NavLink to="/home/profile">
                                    <li className="homenav">Profile</li>
                                    </NavLink> */}
                                {this.props.userDetail.role === "admin" &&
                                    <NavLink to="/adminmain/userlist">
                                        <li className="locationnav">User List</li>
                                    </NavLink>
                                }

                                {this.props.userDetail.role === "admin" ?
                                    <NavLink to="/adminmain/orderList">
                                        <li className="locationnav">Order History</li>
                                    </NavLink>:
                                    <NavLink to="/adminmain/orderList">
                                    <li className="homenav">Order History</li>
                                </NavLink>
                                }


                                {this.props.userDetail.role === "admin" &&
                                    <NavLink to="/adminmain/paymentlist">
                                        <li className="locationnav">Payment History</li>
                                    </NavLink>
                                }
                                {this.props.userDetail.role === "admin" &&
                                    <NavLink to="/adminmain/voucherlist">
                                        <li className="locationnav">Voucher</li>
                                    </NavLink>
                                }
                                 {this.props.userDetail.role === "admin" &&
                                <NavLink to="/adminmain/feedbacklist">
                                    <li className="homenav">Feedback</li>
                                </NavLink>
                                 }
                                <li className="navbtn dropdwnbutnarea">
                                    <Menu compact>
                                        <div className="drpdwn-inrarea">
                                            <div className="usrimgarea">
                                                <img src={user} className="usericn" />
                                            </div>
                                            <Dropdown text={this.props.userDetail && (this.props.userDetail.firstName + " " + this.props.userDetail.lastName)} options={options} simple item className="dropdwnbutn" />
                                        </div>
                                    </Menu>
                                </li>
                            </ul>
                        </div>
                       
                    </Grid.Column>
                    <div className="hidden-md">
                            {this.state.open === true ?
                                <div className="mobilenavitems">
                                    <ul className="navullist" style={{ color: color }}>
                                        <NavLink to="/adminmain">
                                            <li className="homenav" onClick ={() => {this.setState({ open: false });}}>Company List</li>
                                        </NavLink>
                                        <NavLink to="/adminmain/tablelist">
                                            <li className="homenav" onClick ={() => {this.setState({ open: false });}}>Table List</li>
                                        </NavLink>
                                        {/* <NavLink to="/home/profile">
                                    <li className="homenav">Profile</li>
                                    </NavLink> */}
                                        <NavLink to="/adminmain/userlist">
                                            <li className="locationnav" onClick ={() => {this.setState({ open: false });}}>User List</li>
                                        </NavLink>
                                        <NavLink to="/adminmain/paymentlist">
                                            <li className="locationnav" onClick ={() => {this.setState({ open: false });}}>Payment History</li>
                                        </NavLink>
                                        <NavLink to="/adminmain/voucherlist">
                                            <li className="locationnav" onClick ={() => {this.setState({ open: false });}}>Voucher</li>
                                        </NavLink>
                                        <NavLink to="/adminmain/feedbacklist">
                                            <li className="homenav" onClick ={() => {this.setState({ open: false });}}>Feedback</li>
                                        </NavLink>
                                        <NavLink to="/home/profile">
                                            <li className="homenav" onClick ={() => {this.setState({ open: false });}}>Profile</li>
                                        </NavLink>
                                        <NavLink to="/signin">
                                            <li className="locationnav">Logout</li>
                                        </NavLink>
                                    </ul>
                                </div>
                                : null}
                        </div>

                </Grid>
            </div>
        );
    }
}

// export default AdminHeader;

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch, state) {
    return {
        actions: bindActionCreators(actioncreators, dispatch)
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AdminHeader)
);
