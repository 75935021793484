import React, { Component } from 'react';
import '../Main/Main.css';
import './Admin.css';
import 'semantic-ui-css/semantic.min.css'
import { Grid, Image, Button, Container, Checkbox, Form, Icon, Input, Card } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom';
import MaterialTable from 'material-table';

class Adminsettings extends Component {
    render() {
        return (
            <div className="adminsettingstart">
            
            <div className="settingsheadin">
            <h3>Settings</h3>
            </div>
            <Container>
            <Grid>
                <Grid.Column computer={8} mobile={16}>
                    <div className="settingsdiv">
                    <Grid.Column computer={8} mobile={8}>
                        <div className="settingstext">
                        <p>Table Name : <span>Table 1</span></p>
                       
                        </div>
                    </Grid.Column>        
                    <Grid.Column computer={8} mobile={8}>
                        <div className="settingstext">
                        <p>Site Number : <span>T1234</span></p>
                        </div>
                    </Grid.Column> 
                    <Grid.Column computer={8} mobile={8}>
                        <div className="settingstext">
                        <p>GPS Longitude & Latitude : <span>41.40338, 2.17403.</span></p>
                        </div>
                    </Grid.Column>  
                    <Grid.Column computer={8} mobile={8}>
                        <div className="settingstext">
                        <p>Current Status : <span>Active</span></p>
                        </div>
                    </Grid.Column>      
                    <Grid.Column computer={8} mobile={8}>
                        <div className="settingstext">
                        <p>Battery Status : <span>Deactive</span></p>
                        </div>
                    </Grid.Column><Grid.Column computer={8} mobile={8}>
                        <div className="settingstext mb0">
                        <p>Outlet serial numbers : <span>1) 123445,</span> <span>2) 123445,</span> <span>3) 123445</span></p>
                        </div>
                    </Grid.Column>
                             
                    </div>
                    </Grid.Column>

                    
                <Grid.Column computer={8} mobile={16}>

                    <div className="settingsdiv">

                    <div className="servicehead">
                    <h3>Services</h3>
                    </div>
                    <Grid.Column computer={8} mobile={8}>
                       
                        
                       
                        
                    </Grid.Column>        
                                   
                    </div>
                    </Grid.Column>

                    </Grid>
                    
                    

                   
                    </Container>
                    
            </div>
        );
    }
}

export default Adminsettings;