import React, { Component } from 'react';
import '../Main/Main.css';
import './Admin.css';
import 'semantic-ui-css/semantic.min.css'
import { Image, Button, Container, Checkbox, Form, Icon, Input, Card, Modal, TextArea } from 'semantic-ui-react'
import { withRouter, NavLink } from 'react-router-dom';
// import {, NavLink } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Grid, Row, Col } from 'react-flexbox-grid';
import LeftFeedbackList from './LeftFeedbackList';
import FeedbackDetails from './FeedbackdetailsSection';
import send from '../../Images/send-button.png';
import rightarrow from '../../Images/rightarrow.png';
import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";


class FeedbackList extends Component {
    constructor(props) {
        super(props);
    this.state = {
        modalOpen: false,
        data: {},
    }
    }
    // state = { modalOpen: false }

    handleOpen = () => this.setState({ modalOpen: true })

    handleClose = () => this.setState({ modalOpen: false })

    handleOpenDesktopView=(id)=>{

        console.log(id)

        this.setState(prevState => ({
            data: {
                ...prevState.data,
                id,
            }
        }),
            () => {
                console.log(this.state.data);
            }
        );
    }









    
    render() {
        return (
            <div className="feedbacklisting">
                <Grid fluid>
                    <Row>
                        <Col xs className="leftsidefeedbacklist">
                        <div className="hidden-xs">
                        <LeftFeedbackList  handleClick={this.handleOpenDesktopView}/>
                        </div>
                        <div className="hidden-md">
                        <LeftFeedbackList handleClick={this.handleOpen} />
                        </div>
                            
                        </Col>
                        <Col xs className="detailsfeedback hidden-xs">
                            <FeedbackDetails idData={this.state.data.id}/>
                        </Col>
                        <Modal
                            open={this.state.modalOpen}
                            onClose={this.handleClose}
                            basic
                            size='small'>
                            <Modal.Header><span onClick={this.handleClose}><img src={rightarrow} className="rightarrowimg"/><span className="backtext">Back</span></span></Modal.Header>
                            <Modal.Content image scrolling>
                                <FeedbackDetails />
                            </Modal.Content>
                            <Modal.Actions>
                                <div className="commentsection">
                                    <Form className="commentform">
                                        <Form.Field>
                                            <TextArea placeholder='Type.....' rows={2} />
                                        </Form.Field>
                                        <Button className="commentsendbtn"><img src={send} className="sendbtnimg" /></Button>
                                    </Form>
                                </div>
                            </Modal.Actions>
                        </Modal>
                    </Row>
                </Grid>
            </div>
        );
    }
}

// export default FeedbackList;

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch, state) {
    return {
        actions: bindActionCreators(actioncreators, dispatch)
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(FeedbackList)
);
