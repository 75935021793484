
import React, { Component } from "react";
import "./Main.css";
import "semantic-ui-css/semantic.min.css";
import { Grid, Button, Container, Modal, Icon } from "semantic-ui-react";
import { NavLink, withRouter } from "react-router-dom";
import location from "../../Images/pin.png";
import wifi from "../../Images/wifistatus.png";
import solarpanel from "../../Images/table.png";
import user from "../../Images/user.png";
import wifi2 from "../../Images/selectsettings/wifiwhite.png";
import charger from "../../Images/Group130.png";
import wifi1 from "../../Images/wifi2.png";
import charger1 from "../../Images/Path64.png";
import Countdown from "react-countdown-now";
import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Alert from "./Alert";
import tableimg from "../../Images/slider06.jpg";
import solarenergy from "../../Images/solar-energy.png";
import batteryicon00 from "../../Images/batteryicon00.png"
import moment from 'moment';

import { checkTableStatus } from "../../services/sharedService";




const dropdownhead = [
    <div className="usrimgarea">
        <img src={user} className="usericn" />
    </div>
];
const text1 = [
    <div>
        <NavLink to="/home/profile">
            <p>Profile</p>
        </NavLink>
    </div>
];
const text2 = [
    <div>
        <NavLink to="/signin">
            <p>Logout</p>
        </NavLink>
    </div>
];

const options = [
    { key: 1, text: text1, value: 1 },
    { key: 2, text: text2, value: 2 }
    // { key: 3, text: 'Choice 3', value: 3 },
];

class SelectSettings extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            horizontal: 80,
            chargerhorizontal: 10,
            lighthorizontal: 20,
            // color: false,
            color: {},
            toggle: false,
            selectedServices: [],
            disableUseServiceButton: true,
            showCountdown: false,
            activeServices: {},
            showInUse: false,
            modalOpen: false,
            alertheading: "",
            alertcontext: "",
            alertbutton: "",
            imgtype: "",

        };
    }

    componentDidMount() {
        console.log(moment().utc().hours(),"L66>>")
        console.log(moment().utc().minutes(),"L66>>")
        // if()
        console.log(this.props);
        // this.setState({selectedTable:this.props.selectedTable})
        if (this.props.selectedTable._id) {
            console.log("inside componentDidMount if condition");

            this.setState({ selectedTable: this.props.selectedTable });
            this.props.actions.getLoggedinUserDetails(this.props.history);
            const reqValues = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            };
            fetch(
                process.env.REACT_APP_apiurl +
                "/order/findActiveOrder?userId=" +
                this.props.userDetail._id +
                "&tableId=" +
                this.props.selectedTable._id,
                reqValues
            )
                .then(result => result.json())
                .then(result => {
                    console.log(result, "L91>>>>>>>>");

                    if (result.error) {
                        this.setState({ disableUseServiceButton: false }); // No active service
                    } else {
                        //    showCountdown\
                        var obj = {};
                        // var inUse={};
                        for (let i = 0; i < result.result.services.length; i++) {
                            // if(result.result.orderedBy!=this.props.userDetail._id)
                            // {
                            // inUse[result.result.services[i].serviceName] = true;
                            // }
                            obj[result.result.services[i].serviceName] =
                                result.result.services[i].endTime;
                        }
                        this.setState({
                            activeServices: obj,
                            currentOrder: result.result
                        }, () => {
                            if (result.result.orderedBy === this.props.userDetail._id) {
                                this.setState({ showCountdown: true });
                            } else {
                                this.setState({ showCountdown: true, showInUse: true });
                            }
                        });

                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }
    componentWillReceiveProps(newProps) {
        console.log(newProps.selectedTable);
        console.log(this.props.selectedTable, "L91>>>>>>>>>>>>>>>>>");
        console.log(Object.is(newProps.selectedTable, this.props.selectedTable));
        if (
            newProps.selectedTable._id &&
            !Object.is(newProps.selectedTable, this.props.selectedTable)
        ) {
            console.log("inside componentWillReceiveProps if condition");
            // &&!this.props.selectedTable._id&&this.state.disableUseServiceButton
            this.setState({ selectedTable: newProps.selectedTable }, () =>
                console.log(this.state.selectedTable, "L79>>>>>>>>>")
            );

            const reqValues = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            };
            fetch(
                process.env.REACT_APP_apiurl +
                "/order/findActiveOrder?userId=" +
                this.props.userDetail._id +
                "&tableId=" +
                newProps.selectedTable._id,
                reqValues
            )
                .then(result => result.json())
                .then(result => {
                    console.log(result, "L91>>>>>>>>");

                    if (result.error) {
                        this.setState({ disableUseServiceButton: false }); // No active service
                    } else {
                        //    showCountdown\
                        var obj = {};
                        // var inUse={};
                        for (let i = 0; i < result.result.services.length; i++) {
                            // if(result.result.orderedBy!=this.props.userDetail._id)
                            // {
                            // inUse[result.result.services[i].serviceName] = true;
                            // }
                            obj[result.result.services[i].serviceName] =
                                result.result.services[i].endTime;
                        }
                        this.setState({
                            activeServices: obj,
                            currentOrder: result.result
                        }, () => {
                            if (result.result.orderedBy === this.props.userDetail._id) {
                                this.setState({ showCountdown: true });
                            } else {
                                this.setState({ showCountdown: true, showInUse: true });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }

    randomClick = x => {
        console.log(x);
        this.setState(
            prevState => {
                let color = { ...prevState.color };
                color[x._id] = prevState.color[x._id] ? !prevState.color[x._id] : true;
                return { color };
            },
            () => {
                if (!this.state.color[x._id]) {
                    var newArr = this.state.selectedServices.filter(z => {
                        return z._id !== x._id;
                    });
                    this.setState({ selectedServices: newArr }, () => {
                        console.log(this.state.selectedServices, "L94>>>>>>");
                    });
                } else if (this.state.color[x._id]) {
                    this.setState(
                        prevState => ({
                            selectedServices: [...prevState.selectedServices, x]
                        }),
                        () => {
                            console.log(this.state.selectedServices, "L102>>>>>>");
                        }
                    );
                }
            }
        );
    };

    selectedImg = name => {
        var selectedObj = this.props.servicesList.find(x => {
            return x.name.toLowerCase() === name.toLowerCase();
        });

        console.log(selectedObj.selected, "l98>>>>>>>>>>");
        return selectedObj.selected;
    };

    defaultImg = name => {
        var selectedObj = this.props.servicesList.find(x => {
            return x.name.toLowerCase() === name.toLowerCase();
        });

        //   console.log(selectedObj.selected,"l98>>>>>>>>>>")
        return selectedObj.default;
    };

    selectOrder = async () => {
        this.props.actions.getTableDetails();
        let tableStatus = await checkTableStatus(sessionStorage.getItem('tableId'),15);
        if(!tableStatus)
        {
        this.setState({
            modalOpen: true,
            alertheading: "Error",
            alertcontext: `This table's services are inactive or will be inactive in 15 mins. We request you to not use the services now and come back later.`,
            alertbutton: "Close",
            imgtype: "error",
            selectedServices: []
        });
        return;
    }
    
        for (const key of Object.keys(this.state.color)) {
            // console.log(key, obj[key]);
            if (this.state.color[key] === true) { //checks if any service has been selected

           //
        //         console.log(moment().utc().hours(),"L66>>")
        // console.log(moment().utc().minutes(),"L66>>")
        // //
        //            var currentUTChours=moment().utc().hours();
        //            var currentUTCminutes=moment().utc().minutes();
        //         if((this.state.selectedTable.blackoutStart.hours===currentUTChours &&
        //            ( this.state.selectedTable.blackoutStart.minutes<=currentUTCminutes || this.state.selectedTable.blackoutStart.minutes<= currentUTCminutes + 15))||
        //             (this.state.selectedTable.blackoutEnd.hours===currentUTChours&&this.state.selectedTable.blackoutEnd.minutes>=currentUTCminutes )
        //             ||(
        //                 this.state.selectedTable.blackoutStart.hours<currentUTChours && 
        //                 this.state.selectedTable.blackoutEnd.hours>currentUTChours
        //             )){

        //                 // alert("This table's services are currently inactive!!")
        //                 this.setState({
        //                     modalOpen: true,
        //                     alertheading: "Error",
        //                     alertcontext: `This table's services are inactive or will be inactive in 15 mins. We request you to not use the services now and come back later.`,
        //                     alertbutton: "Close",
        //                     imgtype: "error"
        //                 });
        //                 return;
        //         } // checks for blackout timings
       
        //         else
        //        {
                this.props.history.push({
                    pathname: "/selectservicedrawerv2",
                    data: this.state.selectedServices
                });
                return;
            // }
            }
        }

        alert("Choose a service first to proceed further!");
    };

    findRemTime = x => {
        console.log(this.state.activeServices);

        if (this.state.activeServices[x]) {
            console.log(this.state.activeServices[x]);

            //   let extraTime = this.state.activeServices[x] - Date.now();

            //   let t = extraTime / 15;

            //   let time = Date.now() + t;

            //   return time;
            return this.state.activeServices[x];
        }
    };
    activateButton = () => {
        this.setState({ disableUseServiceButton: false, showCountdown: false, showInUse: false });
    };

    terminateService = () => {
        console.log(this.state.currentOrder)
        this.setState({
            modalOpen: true,
            alertheading: "Warning",
            alertcontext: "Your current active services will stop without any reimbursement. Use stop services button to continue or click on 'x' to abort",
            alertbutton: "Stop services",
            imgtype: "warning"
        });


    }


    stopServices = () => {
        const reqValues = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "authorization": localStorage.getItem('token')
            }
        };
        fetch(
            process.env.REACT_APP_apiurl +
            "/order/terminate?id=" + this.state.currentOrder._id,
            reqValues
        )
            .then(
                result =>

                    result.json()

            )

            .then(result => {
                console.log(result);

                if (!result.error) {
                    this.setState({
                        modalOpen: true,
                        alertheading: "Success",
                        alertcontext: "Services stopped successfully!",
                        alertbutton: "Close",
                        imgtype: "Success",
                        disableUseServiceButton: false,
                        showCountdown: false,
                        activeServices: {},
                        showInUse: false,

                    });
                }
                else {
                    //Alert - failed to terminate the order

                    this.setState({
                        modalOpen: true,
                        alertheading: "Error",
                        alertcontext: "Could not services currently, please try again.",
                        alertbutton: "Close",
                        imgtype: "error"
                    });
                }
            })
            .catch(err => {
                console.log(err);
            });
    }
    findbutnfunction = () => {
        console.log("Findbutton function!!");

        if (this.state.alertbutton === "Close") {

            this.closeModal();
        } else if (this.state.alertbutton === "Stop services") {
            this.stopServices();
        }
    };

    closeModal = () => this.setState({ modalOpen: false });
    render() {
        const Completionist = () => <span className="completetime">Complete</span>;
        const renderer = ({ hours, minutes, seconds, completed }) => {
            console.log(hours, minutes, seconds, completed);
            if (completed) {
                // Render a completed state
                // this.setState({disableUseServiceButton:false})
                this.activateButton();
                return <Completionist />;
            } else {
                // Render a countdown
                if (this.state.showInUse) {
                    return (
                        <div className="inusetext">
                            <p>In Use  <span>
                                {hours > 0 ? (hours > 9 ? hours : 0 + "" + hours) : null}   {minutes > 9 ? minutes : 0 + "" + minutes}:{seconds > 9 ? seconds : 0 + "" + seconds}
                            </span></p>
                        </div>
                    );
                } else {
                    return (
                        <span className="activebal">
                            {/* <div class="checkmark_stem"></div>
                    <div class="checkmark_kick"></div> */}
                            {hours > 0 ? (hours > 9 ? hours : 0 + "" + hours) + ":" : null}{minutes > 9 ? minutes : 0 + "" + minutes}:{seconds > 9 ? seconds : 0 + "" + seconds}
                        </span>
                    );
                }
            }
        };

        let backgroundImage =
            this.state.color === true
                ? "linear-gradient(to bottom, #f1ae3e, #f3a242, #f39647, #f18b4d, #ee8053)"
                : null;
        let borderRadius = this.state.color === true ? "10px" : "10px";
        let color = this.state.color === true ? "white" : "#475965";
        let backgroundImage1 =
            this.state.color1 === true
                ? "linear-gradient(to bottom, #f1ae3e, #f3a242, #f39647, #f18b4d, #ee8053)"
                : null;
        let borderRadius1 = this.state.color1 === true ? "10px" : "10px";
        let color1 = this.state.color1 === true ? "white" : "#475965";
        return (
            <div className="selctsettings">
                <div className="selectsettingsstart selectsetngpage">
                    <div className="selectsettingsheader">
                        <div className="selectsettngcards">
                            {/* When the processing is start , div will be shown  start*/}

                            {/* When the processing is start , div will be shown  start*/}
                            <Container>
                                <div className="selectedtableheadertext">
                                    <div className="selectheading2 slcthsrvchedng">
                                        <h3>
                                            Location and status of the selected ShadeCharger table
                    </h3>
                                    </div>
                                </div>
                            </Container>
                            <div className="selectbox selectsinglecard selectingtablediv">
                                <div className="selectinnerheader locationheader">
                                    {/* <div className="heading1">
                                        <p className="cardblc"><span className="fontbold">Location</span>and  <span className="fontbold">  status </span>    of the selected shadeCharger table</p>
                                    </div> */}
                                    {/* <div className="innerdetailscard">
                                        <div className="selctlocation">
                                            <img src={location} className="locationimg" />
                                            <p>{this.state.selectedTable && this.state.selectedTable.location.address}</p>
                                        </div>
                                        <div className="selctlocation borderdiv ">
                                            <img src={solarpanel} className="panelimg" />
                                            <p>{this.state.selectedTable && this.state.selectedTable.tableNumber}</p>
                                        </div>
                                        <div className="selctlocation">
                                            <img src={wifi} className="wifiimg" />
                                            <p className="wifistatusonline">{this.state.selectedTable && this.state.selectedTable.status}</p>
                                        </div>
                                    </div> */}
                                    <div className="tabledetailssecheader">
                                        {/* <Grid> */}
                                        {/* <Grid.Column computer={6} mobile={8} style={{ padding: '13px 14px 4px' }}> */}
                                        <div className="selectedtableimg">
                                            {/* <img src={tableimg} className="selectedimg" /> */}
                                            <img src={this.props.selectedTable.imageId ? process.env.REACT_APP_apiurl + "/getimages/" + this.props.selectedTable.imageId : null} className="tableplaceholderimg" />
                                        </div>
                                        {/* </Grid.Column>
                                        <Grid.Column computer={10} mobile={8} style={{ paddingTop: 0, paddingBottom: 0 }}> */}
                                        <div className="rightsidedetailes">
                                            <div className="selectedtableinfo">
                                                <div className="locationimgdiv">
                                                    <img src={location} className="locationimg" />
                                                </div>

                                                <p>
                                                    {this.state.selectedTable &&
                                                        this.state.selectedTable.location.address}
                                                </p>
                                            </div>
                                            <div className="selectedtableinfo">
                                                <div className="locationimgdiv">
                                                    <img src={solarpanel} className="panelimg" />
                                                </div>
                                                <p>
                                                    {this.state.selectedTable &&
                                                        this.state.selectedTable.tableNumber}
                                                </p>
                                            </div>
                                            <div className="selectedtableinfo">
                                                <div className="locationimgdiv">
                                                    <img src={batteryicon00} className="wifiimg" />
                                                </div>
                                                <p>
                                                {this.state.selectedTable &&
                                                        this.state.selectedTable.status ==="Fully charged"? 
                                                <p className="wifistatusonline">
                                                    {this.state.selectedTable &&
                                                        this.state.selectedTable.status}
                                                </p>
                                                :
                                                this.state.selectedTable &&
                                                        this.state.selectedTable.status ==="Discharged"? 

                                                 <p className="wifistatusoffline">{this.state.selectedTable &&
                                                    this.state.selectedTable.status}</p>
                                                    :
                                                    // this.state.selectedTable &&
                                                    // this.state.selectedTable.status ==="good"? 

                                                 <p className="wifistatushalf"> {this.state.selectedTable &&
                                                    this.state.selectedTable.status}</p> 
                                                    // :
                                                    // null

                                                    }
                                                    </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* </Grid.Column> */}
                                    {/* </Grid> */}
                                </div>
                            </div>
                            <div className="servicesettingselectingcard">
                                <div className="serviceselectioncard">

                                    <div>
                                        <div className="selectheading2 slcthsrvchedng selectservicecardhead">
                                            <h3>Select resources at this table for use</h3>
                                        </div>

                                    </div>

                                    <div className="paddinglr servicecardgroup">
                                        {this.state.selectedTable &&
                                            this.state.selectedTable.services.map(x => {
                                                return (
                                                    <div key={x._id}>
                                                        <div className="serviceindividualcard">
                                                            <div
                                                                className="innerbox"
                                                                style={{
                                                                    backgroundImage:
                                                                        this.state.color[x._id] === true
                                                                            ? "linear-gradient(to bottom, #f1ae3e, #f3a242, #f39647, #f18b4d, #ee8053)"
                                                                            : null,
                                                                    borderRadius: "10px"
                                                                }}
                                                                onClick={() => this.randomClick(x)}
                                                            >
                                                                <ul>
                                                                    <li>
                                                                        {" "}
                                                                        <span
                                                                            style={{
                                                                                color:
                                                                                    this.state.color[x._id] === true
                                                                                        ? "white"
                                                                                        : "#475965"
                                                                            }}
                                                                            className="servicecardname"
                                                                        >
                                                                            {x.name}
                                                                            {/* {this.state.color === true ?
                                                            <img src={check} className="checkimg" /> :
                                                            <div className="checkimg borderedcircle">
                                                            </div>
                                                        } */}
                                                                        </span>
                                                                    </li>
                                                                    {/* <li style={{ color: this.state.color[x._id] === true ? 'white' : '#475965' , float:'right' }}>
                                                            
                                                            {this.state.inUse[x.name] && <span className="activebal"> In Use </span>
                                                     }
                                                         </li> */}
                                                                    <li
                                                                        style={{
                                                                            color:
                                                                                this.state.color[x._id] === true
                                                                                    ? "white"
                                                                                    : "#475965"
                                                                        }}
                                                                    >
                                                                        {this.state.showCountdown &&
                                                                            this.state.activeServices[x.name] && (
                                                                                <Countdown
                                                                                    date={this.findRemTime(x.name)}
                                                                                    renderer={({
                                                                                        hours,
                                                                                        minutes,
                                                                                        seconds,
                                                                                        completed
                                                                                    }) =>
                                                                                        renderer({
                                                                                            hours,
                                                                                            minutes,
                                                                                            seconds,
                                                                                            completed
                                                                                        })
                                                                                    }
                                                                                >
                                                                                    {/* <Completionist /> */}
                                                                                    {/* date={Date.now() + 30000} */}
                                                                                </Countdown>
                                                                            )}
                                                                    </li>
                                                                </ul>
                                                                {/* {this.state.showInUse &&
                                    this.state.activeServices[x.name] && (
                                      <div className="inusetext">
                                        <p>In Use</p>
                                      </div>
                                    )} */}
                                                                {this.state.color[x._id] === true ? (
                                                                    <img
                                                                        src={this.selectedImg(x.name)}
                                                                        className="innerwifiimg wifiimg1"
                                                                        alt={x.name}
                                                                    />
                                                                ) : (
                                                                        <img
                                                                            src={this.defaultImg(x.name)}
                                                                            className="innerwifiimg"
                                                                            alt={x.name}
                                                                        />
                                                                    )}

                                                                <p
                                                                    className="cost"
                                                                    style={{
                                                                        color:
                                                                            this.state.color[x._id] === true
                                                                                ? "white"
                                                                                : "#475965"
                                                                    }}
                                                                >
                                                                    ${(x.cost).toFixed(2)}
                                                                </p>
                                                                <p
                                                                    className="permin"
                                                                    style={{
                                                                        color:
                                                                            this.state.color[x._id] === true
                                                                                ? "white"
                                                                                : "#475965"
                                                                    }}
                                                                >
                                                                    per 15 min
                                  </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}

                                    </div>
                                    <div className="belowselctservicepart">
                                        {this.state.showCountdown && this.state.showInUse === false &&
                                            <div className="timingarea">
                                                <ul className="timingclock">
                                                    <li>
                                                        {" "}
                                                        <div class="timeanimation">
                                                            <div class="circle">
                                                                <div class="hour" />
                                                            </div>
                                                            <div class="circle2">
                                                                <div class="hour" />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="servctxtline">
                                                        <p className="servcestxt">Click here to terminate your session</p>
                                                    </li>
                                                </ul>
                                                <Button className="serviceclosebtn" onClick={this.terminateService}>Close</Button>
                                            </div>
                                        }
                                        <div className="selectbox selectsinglecard creditblnccard">
                                            <div className="selectinnerheader">
                                                <div className="heading1">
                                                    <p className="cardblc">Credit balance</p>
                                                    <p className="credit_value creditblc">
                                                        $
                            {this.props.userDetail.credit &&
                                                            this.props.userDetail.credit.toFixed(2)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="selectbtn">
                                            <Button
                                                disabled={this.state.disableUseServiceButton}
                                                onClick={this.selectOrder}
                                            >
                                                press to Specify Minutes to Purchase
                      </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </Container> */}
                    </div>
                </div>

                <Modal
                    open={this.props.testChargeMessage}
                    onClose={() => this.props.actions.stopTestChargeMessage()}
                >
                    <Modal.Content className="paymentsms-content">
                        <Modal.Content>
                            <Alert
                                alertheading={"Success"}
                                alertcontext={
                                    "You have 5 minutes of test power. Buy more to keep using Shade Charger"
                                }
                                alertbutton={"Close"}
                                imgtype={"success"}
                                butnfunction={() => this.props.actions.stopTestChargeMessage()}
                            />
                            {/*this.closeModal */}
                        </Modal.Content>
                    </Modal.Content>
                </Modal>
                <Modal open={this.state.modalOpen} onClose={this.closeModal}>
                    <Modal.Content className="paymentsms-content">
                        <Modal.Content>
                            <div className="closecrossbtn">
                                <Icon onClick={this.closeModal} name="close" />
                            </div>
                            <Alert
                                alertheading={this.state.alertheading}
                                alertcontext={this.state.alertcontext}
                                alertbutton={this.state.alertbutton}
                                imgtype={this.state.imgtype}
                                butnfunction={() => this.findbutnfunction()}
                            />
                            {/*this.closeModal */}
                        </Modal.Content>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

// export default SelectSettings;

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch, state) {
    return {
        actions: bindActionCreators(actioncreators, dispatch)
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SelectSettings)
);