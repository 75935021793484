import React, { Component } from 'react';
import './Main.css';
import 'semantic-ui-css/semantic.min.css'
import { Grid, Image, Button, Container, Checkbox, Form, Icon, Input, Card } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom';
import MaterialTable from 'material-table'
class HistoryTable extends Component {
    render() {
        return (
            <div className="historytablestart">
                <div className="profilepagestart">
                <div className="profile-heading">
                <h2>History Table</h2>
                </div>
                    <div style={{ maxWidth: '100%' }}>
                        <MaterialTable
                            columns={[
                                { title: 'Date', field: 'date' },
                                { title: 'Location', field: 'location' },
                                { title: 'Phone Number', field: 'phoneno', type: 'numeric' },
                                { title: 'Item Purchase', field: 'itempurchase', type: 'numeric' },
                                { title: 'Minutes', field: 'minutes',},
                                { title: 'Sale($)', field: 'sale', type: 'numeric' }
                            ]}
                            data={[{ date: '28-05-2019',location:'123 Sans Serif bxjk',phoneno:1243456,itempurchase:6,minutes:'30min',sale:3 },
                            { date: '28-05-2019',location:'123 Sans Serif bxjk',phoneno:1243456,itempurchase:6,minutes:'30min',sale:3 }
                        ]}
                            title=""
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default HistoryTable;
