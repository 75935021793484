import React from 'react';
import './Home.css';
import 'semantic-ui-css/semantic.min.css'
import Header from './Header';
import Carousel from './Carousel';
import Service from './Service';
import Work from './Work';
import Place from './Place';
import Footer from './Footer';
import { Grid, Image, Button, Container } from 'semantic-ui-react'
import Copyrightfooter from './Copyrightfooter';

function Home() {
  return (
    <div className="homestart">
      <Carousel />
      <Service />
      <Work />
      <div className="startsec">
        <Container>
          <p className="facilitytext">Enjoy the charging and hotspot facility</p>
          <Button className="facilitystartbtn">START NOW</Button>
        </Container>
      </div>
      <Place />
      <Footer />
     <Copyrightfooter/>
    </div>
  );
}

export default Home;
