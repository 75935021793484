import React, { Component } from 'react';
import '../Main/Main.css';
import './Admin.css';
import 'semantic-ui-css/semantic.min.css'
import { Grid, Image, Button, Container, Radio, Checkbox, Form, Icon, Input, Card, Modal } from 'semantic-ui-react'
import { withRouter, NavLink } from 'react-router-dom';
import MaterialTable from "material-table";
import tableicon from '../../Images/audit.png';
import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AddPaymentForm from './AddPaymentForm'
import AddTableListForm from './AddTableListForm'


var moment = require('moment');
class PaymentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      // columns: [
      //   {
      //     title: 'Date', field: 'date',
      // editComponent: props => (
      //     <input
      //         type="radio"
      //         value={props.value}
      //         onChange={e => props.onChange(e.target.value)}
      //     />
      // )
      // },
      // {
      //   title: 'User Name', field: 'u_name',
      // editComponent: props => (
      //     <input
      //         type="radio"
      //         value={props.value}
      //         onChange={e => props.onChange(e.target.value)}
      //     />
      // )
      // },
      // {
      //   title: 'Payment Type',
      //   field: 'p_type',
      // lookup: { 3: 'Activate', 4: 'Deactivate' },
      // },
      // {
      //   title: 'Card Number/Voucher Code',
      //   field: 'c_no',
      // lookup: { 3: 'Activate', 4: 'Deactivate' },
      // },
      // { title: 'Amount($)', field: 'amount' },
      // {
      //   title: 'Status',
      //   field: 'status',
      // lookup: { 1: 'Activate', 2: 'Deactivate' },
      //   },
      // ],
      // data: [
      //   { date: '28/2/2019', u_name: 'User1', p_type: 'Credit Card', c_no: '1122w323re34', amount: '234', status: 'Complete' },
      //   { date: '2/3/2019', u_name: 'User2', p_type: 'Prepaid Card', c_no: '1122w323re34', amount: '234', status: 'Pending' },
      // ]
    }
  }

  componentWillReceiveProps(newProps) {
    if (!Object.is(newProps.paymentList, this.props.paymentList)) {
      this.setState({ data: newProps.paymentList }, () =>
        console.log("Receiving newProps.>>>>>>>62"))
    }
  }

  componentDidMount() {
    this.props.actions.getPaymentList();
  }



  render() {

    var paymentData = [
      { date: '28/2/2019', u_name: 'User1', p_type: 'Credit Card', c_no: '1122w323re34', amount: '234', status: 'Complete' },
      { date: '2/3/2019', u_name: 'User2', p_type: 'Prepaid Card', c_no: '1122w323re34', amount: '234', status: 'Pending' },
    ]
    // },

    return (
      <div className="tableliststart">
        <div className="historytablestart">
          <div className="profilepagestart">
            {/* <div className="payment-heading">
              <Grid>
                <Grid.Column computer={8}>
                  <h2 className="headingtxt"><img src={tableicon} />Payment History</h2>
                </Grid.Column>
              </Grid>
            </div> */}



            <div style={{ maxWidth: '100%' }} className="tablediv">
              {/* <Modal trigger={<Button className="adminvouchrbutn"><Icon name="plus" />Add new</Button>}>
                <Modal.Header>Add Payment</Modal.Header>
                <Modal.Content className="addmodal-content">
                  <AddTableListForm />
                </Modal.Content>
              </Modal> */}
              <MaterialTable
                title="Payment"
                columns={[
                  {
                    title: 'Date', field: 'date',

                    render: rowData => {
                      return (
                        <div>
                          {rowData.createdDate && <div className="createdDate">
                            {moment(rowData.createdDate).format("MM-DD-YYYY")}
                          </div>}
                        </div>

                      );
                    }
                  },
                  {
                    title: 'User Name', field: 'u_name',
                    render: rowData => {
                      return (
                        <div>
                          {rowData.userId.firstName + "  " + rowData.userId.lastName}

                        </div>

                      );
                    }



                  },
                  // {
                  //     title: 'Payment Type',
                  //     field: 'p_type',
                  //     // lookup: { 3: 'Activate', 4: 'Deactivate' },
                  //   },

                  // {
                  //     title: 'Card Number/Voucher Code',
                  //     field: 'c_no',
                  //     // lookup: { 3: 'Activate', 4: 'Deactivate' },
                  //   },
                  { title: 'Amount($)', field: 'amount' },
                  {
                    title: 'Status',
                    field: 'status',
                    // editComponent: props => (
                    //   <div>
                    //     <Radio toggle />
                    //   </div>
                    // ),
                    render: rowData => {
                      return (
                        <div>
                          <Radio toggle defaultChecked={rowData.status === "good" ? true : false} />
                        </div>
                      )
                    }
                    // lookup: { 1: 'Activate', 2: 'Deactivate' },
                  },
                ]
                }
                data={this.state.data}
                // editable={{
                //   onRowUpdate: (newData, oldData) =>
                //     new Promise((resolve, reject) => {
                //       setTimeout(() => {
                //         {
                //           const data = this.state.data;
                //           const index = data.indexOf(oldData);
                //           data[index] = newData;
                //           this.setState({ data }, () => resolve());
                //         }
                //         resolve()
                //       }, 1000)
                //     }),
                //   onRowDelete: oldData =>
                //     new Promise((resolve, reject) => {
                //       this.props.actions.deletePayment(oldData._id)
                //       // setTimeout(() => {
                //       //   {
                //       //     let data = this.state.data;
                //       //     const index = data.indexOf(oldData);
                //       //     data.splice(index, 1);
                //       //     this.setState({ data }, () => resolve());
                //       //   }
                //         resolve()
                //       }, 1000)
                //     // }),

                // }}
                // onRowClick={(event, rowData, togglePanel) => togglePanel()}
                options={{
                  pageSize: 20,
                  pageSizeOptions: [30, 40, 50],
                  actionsColumnIndex: -1

                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default PaymentList;
const mapStateToProps = state => {
  return state;
};

function mapDispatchToProps(dispatch, state) {
  return {
    actions: bindActionCreators(actioncreators, dispatch)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PaymentList)
);