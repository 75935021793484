import React, { Component } from "react";
import "./Form.css";
import "semantic-ui-css/semantic.min.css";
import {
  Grid,
  Image,
  Button,
  Container,
  Checkbox,
  Form,
  Icon,
  Input
} from "semantic-ui-react";
// import Header from './Header';
import { NavLink, withRouter } from "react-router-dom";
import Solarimage from "../../Images/Solarimage.jpg";
import slide2 from "../../Images/slide2.png";
import Shadecharger from "../../Images/Shadecharger.png";
import logo from "../../Images/bluelogo.png";
import homeicn from "../../Images/homeicn.png";
import {
  DateInput,
} from 'semantic-ui-calendar-react';

import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import { withRouter } from "react-router-dom";
var moment = require('moment');
// moment.utc('2016-01-01T23:35:01');
// moment.utc()
// var m = moment('2013-10-06Z');
// var m = moment.utc().format();
// m.format(); // => "2013-10-05T20:00:00-04:00"
// m.utc().format(); 
// var utcDate1 = new Date(Date.UTC(96, 1, 2, 3, 4, 5));


class SignUp extends Component {


  constructor(props) {
    super(props);
    this.state = {
      details: {},
      date: '',
      dob: '',
      time: '',
      dateTime: '',
      datesRange: '',
      nonmatchingPassword: false,
      show: false
    }
  }
  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  }
  handleChange = (event, { name, value }) => {


    if (this.state.hasOwnProperty(name)) {
      console.log(event, { name, value })
      let x = { [name]: value }
      this.setState({ [name]: value });
      this.setState(
        prevState => ({
          details: {
            ...prevState.details,
            ...x
          }
        }),
        () => {
          console.log(this.state.details);

        }
      );
    }

  }

  textInput = (e) => {
    console.log(e.target.name)
    console.log(e.target.value)
    let x = { [e.target.name]: e.target.value }
    this.setState(
      prevState => ({
        details: {
          ...prevState.details,
          ...x
        }
      }),
      () => {
        console.log(this.state.details);
      }
    );
  }


  createUsers = (e) => {
    console.log(e)
    this.setState({ nonmatchingPassword: false })
    this.setState(prevState => ({ show: !prevState.show }), () => { console.log(this.state.show) });
    if (this.state.details.password !== this.state.details.confirmPassword) {

      this.setState({ nonmatchingPassword: true })
    }

    else {
      this.props.actions.createUser(this.state.details, this.props.history)
      console.log("user created successfully>.......62", )
    }
  }

  render() {

    return (
      <div className="signupform registerfrm mobileviewclass">
        <Container>
          <div className="logoare">
            <NavLink to="/home">
              <img src={logo} />
            </NavLink>
          </div>
          <div className="sub-inerfrm">
            <Grid>
              <Grid.Row columns={8}>
                <Grid.Column
                  computer={10}
                  mobile={16}
                // tablet={10}
                >
                  <div className="formfieldarea">
                    <div className="frm-heading hidden-xs">
                      <h2>Sign up</h2>
                      <p className="fornewusr">
                        Already Registered?{" "}
                        <NavLink to="/signin">
                        <span className="signuplink">Sign In</span>
                        </NavLink>
                      </p>
                    </div>
                    <Form className="formfield emailfield">
                      <Form.Field>
                        {/* <label>Name</label> */}
                        <label className="labelnme">First Name</label>
                        <Input iconPosition="left" name={"firstName"} onChange={this.textInput} />
                      </Form.Field>
                      <Form.Field>
                        {/* <label>Name</label> */}
                        <label className="labelnme">Last Name</label>
                        <Input iconPosition="left" name={"lastName"} onChange={this.textInput} />
                      </Form.Field>

                      <Form.Field className="datepickerfield">
                        <label className="labelnme">Date Of Birth</label>
                        {/* <DateInput
                          name={"dob"}
                          // placeholder="Date"
                          value={this.state.dob}
                          // iconPosition="left"

                          onChange={this.handleChange}
                        /> */}
                        <Input iconPosition='left'  value={this.state.dob} type="date" name={"dob"} onChange={this.handleChange} />

                      </Form.Field>

                      <Form.Field>
                        <label className="labelnme">Email</label>
                        <Input iconPosition="left" autocomplete="off" name={"email"} onChange={this.textInput} />
                      </Form.Field>

                      <Form.Field>
                        <label className="labelnme">Password</label>
                        <Input iconPosition="left" type="password" name={"password"} onChange={this.textInput} />
                      </Form.Field>
                      <Form.Field className="errorfield">
                        <label className="labelnme">Confirm Password</label>
                        <Input iconPosition="left" type="password" name={"confirmPassword"} onChange={this.textInput} />
                        {this.state.nonmatchingPassword === true && <p className="errorpaswrd">Incorrect Password....!!!  </p>}
                      </Form.Field>
                      {/* <NavLink to="#"><p className="frgtpswrdtxt">Forgot Password</p></NavLink> */}
                      <Form.Field>
                        <div className="formbutn">
                          {/* <NavLink to="/signin"> */}
                          {/* <NavLink to ="/Home"> */}
                          <Button type="submit"
                            disabled={!this.state.details.firstName ||
                              !this.state.details.lastName || !this.state.details.email ||
                              !this.state.details.password || !this.state.details.confirmPassword}
                            onClick={this.createUsers} >Sign Up</Button>
                          {/* </NavLink> */}
                        </div>
                      </Form.Field>
                      <div className="hidden-md">
                        <p className="fornewusr">
                          Already registered?{" "}
                          <NavLink to="/signin">
                            <span className="signuplink">Sign In</span>
                          </NavLink>
                        </p>
                        {/* <NavLink to="/home">
                                  <p className="backhomelink"><img src={homeicn} className="homeicn"/> Back to home</p>
                        </NavLink> */}
                      </div>
                    </Form>
                  </div>
                </Grid.Column>

                <Grid.Column
                  computer={6}
                  mobile={0}
                  tablet={6}
                  className="hidden-xs"
                >
                  <div className="loginformimg signupfrmimg">
                    <Image src={Shadecharger} />
                  </div>
                  <div className="backhomeline">
                    {/* <NavLink to="/home">
                                        <div className="bacbutnarea signupbackbutn">
                                            <p className="homeicnarea">
                                        <img src={homeicn} className="homeicn"/>
                                        </p>
                                        <p className="backhomelink"> Back to home</p>
                                        </div>
                                </NavLink> */}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Container>
      </div>
    );
  }
}

// export default SignUp;
// const WrappedSignUp = Form.create({ name: "horizontal_login" })(SignUp);

const mapStateToProps = state => {
  return state;
};

function mapDispatchToProps(dispatch, state) {
  return {
    actions: bindActionCreators(actioncreators, dispatch)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SignUp)
);


// export default withRouter(
//   connect()(SignUp)
// );
