import React, { Component } from 'react';
import '../Main/Main.css';
import './Admin.css';
import 'semantic-ui-css/semantic.min.css'
import { Grid, Image, Button, Radio, Container, Checkbox, Form, Icon, Input, Card, Select,Modal } from 'semantic-ui-react'
import { withRouter, NavLink } from 'react-router-dom';
import MaterialTable from 'material-table';
import tableicon from '../../Images/audit.png';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as actioncreators from '../../redux/action';
import AddUserListForm from './AddUserListForm'
import moment, { duration } from 'moment';

class OrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      modalOpen: false,
      size: "default",

      //var for company list data
      companyListData:[{ key: "b", value: "b", text: "Loading.." }]

    }
  }

  handleOpen = () => this.setState({ modalOpen: true })

  handleClose = () => this.setState({ modalOpen: false })

  componentWillReceiveProps(newProps) {

    console.log("new props out>>>>>>>>>>>>>>>>",newProps.storeselectedcompany,this.props.storeselectedcompany)

    //call to get particalar comapany order
    if(!Object.is(newProps.storeselectedcompany,this.props.storeselectedcompany)){
      console.log("new props>>>>>>>>>>>>>>>>")
      this.props.actions.getParticularCompanyOrder(newProps.storeselectedcompany.value)
    }

    //To receive user list
    // if (!Object.is(newProps.usersList, this.props.usersList)) {
    //   this.setState({ data: newProps.usersList }, () => {
    //     console.log("Recieving props user list>>>>>>>")
    //   })
    // }

    //To receive order list
    if (!Object.is(newProps.ordersList, this.props.ordersList)) {
      this.setState({ data: newProps.ordersList }, () => {
        console.log("Recieving props order list>>>>>>>", newProps.ordersList)
        console.log(this.state.data)
      })
    }

    //To receive company list
    if(!Object.is(newProps.companyList, this.props.companyList)) {

      console.log(newProps.companyList,"company list")
      var arr = newProps.companyList.map(x=> {
          var obj={};
          obj.key= x._id ;
             obj.value= x._id ;
            obj.text= x.companyName 
          return obj;
      })

      console.log(arr)
      this.setState({ companyListData: arr }, () => {
          console.log("companyListDatas>>>>>>>", this.state.companyListData)
      })
  }

  if(!Object.is(newProps.companyorderlist,this.props.companyorderlist)){}
    console.log(newProps.companyorderlist,"particular company order list")
    this.setState({ data: newProps.companyorderlist }, () => {
      console.log("Recieving props particular company order list>>>>>>>")
    })

  }

  componentDidMount() {
    console.log(this.state.data)
    console.log("user details>>>>>>>>>>>>"+this.props.userDetail.companyId)
    // this.props.actions.getUsersList();
    // this.props.actions.getAllPaidOrders();

    //if role i.e ADMIN
    if (this.props.userDetail.role === "admin") {
      // this.props.actions.getUsersList();
      this.props.actions.getCompanyList()
      // this.props.actions.getAllPaidOrders();
      if(this.props.storeselectedcompany)
      this.props.actions.getParticularCompanyOrder(this.props.storeselectedcompany.value)

    }
    else {
      if (this.props.userDetail.role === "companyAdmin") {
       
        const reqValues = {
          method: "GET",
          // body: JSON.stringify(tableData),
          headers: {
            "Content-Type": "application/json",
            // authorization: localStorage.getItem("token")
          }
        };
    
        fetch(process.env.REACT_APP_apiurl + "/company/getCompanybyUserId?id=" + this.props.userDetail._id, reqValues)
          .then(result => result.json())
          .then(result => {
            console.log(result);
    
            if (!result.error) {
              this.props.actions.getParticularCompanyOrder(result.result._id
              )
              // this.setState({companyid: result.result._id})
              // companyobj.companyId=result.result._id

              // console.log(companyobj, "L30")
              // this.setState({ formData: companyobj }, () => { console.log(this.state.formData) })
            }
            console.log(this.state.companyid)
          })
          .catch(err => {
            console.log(err);
          });
        // this.props.actions.getParticularCompanyOrder(this.props.userDetail.companyId)
      }
    }
  }

  
  //Call when select partic
  handleChange=(e,data)=>{
    console.log("order list company select>>>>"+data)
    console.log("order list company data value>>>>>>>"+data.value)

    var tempCompanyData = this.state.companyListData.find(x => {
      return x.value === data.value
    })
  
    this.props.actions.storeCompanySelectionData(tempCompanyData)

   
  }

  render() {
    var userdata = [
      { name: 'User1', ph_no: '123456789', email: 'sdcd@gmail.com', password: '123456', i_use: 'Adult', p_privilege: 'Credit Card', status: 'Active' },
      { name: 'User2', ph_no: '123456789', email: 'sdcd@gmail.com', password: '123456', i_use: 'Juvenile', p_privilege: 'Prepaid Card', status: 'Inactive' },
    ]
    return (
      <div className="tableliststart">
        <div className="historytablestart">
          <div className="profilepagestart">
{this.props.userDetail.role === "admin" &&
              <Form.Field className="companyselect">
                            <label className="labelcompany">
                                Select Company :
                                      </label>
                            <Select className="companyinputselect" placeholder="Select Company"
                            onChange={(e,data)=>this.handleChange(e,data)}
                                 options={this.state.companyListData} defaultValue = {this.props.storeselectedcompany?this.props.storeselectedcompany.value:null}
                            // })}
                            />
                            

                            {/* </Select> */}
                        </Form.Field>}
           
            <div style={{ maxWidth: '100%' }} className="tablediv">
             
              <MaterialTable
                title="Order List"
                columns={[
                  {
                    title: 'Firstname', field: 'orderedBy.firstName',

                    // render: rowData => {
                    //   return (
                    //     <div>
                    //       {rowData.orderedBy && rowData.orderedBy.firstName}
                    //     </div>
                    //   )
                    // },

                  },
                  {
                    title: 'Lastname', field: 'orderedBy.lastName',

                    // render: rowData => {
                    //   return (
                    //     <div>
                    //       {rowData.orderedBy && rowData.orderedBy.lastName}
                    //     </div>
                    //   )
                    // },
                  },

                  {
                    title: 'Date', field: 'createdDate',
                    filtering: false,
                    render: rowData => {
                      return (

                        <div>
                        
                          <div className="createdDate">
                            {moment(rowData.createdDate).format("MM-DD-YYYY")}
                          </div>
                          
                        </div>
                      );
                    }

                  },

                  {
                    title: 'Table Number',
                    field: 'tableNumber',
                    render: rowData => {
                      return (
                        <div>
                          {rowData.tableId && rowData.tableId.tableNumber}
                        </div>
                      )
                    },
                    filtering: false,
                    editable: 'never'
                    // lookup: { 3: 'Activate', 4: 'Deactivate' },
                  },
                  
                  {
                    title: 'Total Cost', field: "totalCost",
                   
                    filtering: false,
                  },

                ]}
                data={this.state.data}

                options={{
                  filtering: true,
                  // search: true,
                  pageSize: 20,
                  pageSizeOptions: [30, 40, 50],
                  // actionsColumnIndex: -1

                }}
              // components={{
              //   AddUserListForm: props => <AddUserListForm {...props} /> 
              // }} 
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default UserList;
const mapStateToProps = state => {
  return state;
};

function mapDispatchToProps(dispatch, state) {
  return {
    actions: bindActionCreators(actioncreators, dispatch)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OrderList)
);
