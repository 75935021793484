import React, { Component } from 'react';
import './Main.css';
import 'semantic-ui-css/semantic.min.css'
import { Grid, Image, Button, Container, Checkbox, Form, Icon, Input, Card, } from 'semantic-ui-react'
// import {withRouter, NavLink } from 'react-router-dom';
import user from '../../Images/avatar.png';
import password from '../../Images/password.png';
import email from '../../Images/close-envelope.png';
import edit from '../../Images/profileedit.png';
import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, NavLink } from "react-router-dom";
import FeedbackForm from '../Form/FeedbackForm';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            details: {},
            dob: '',
            time: '',
            dateTime: '',
            datesRange: '',
            nonmatchingPassword: false,
            show: false

        }
    }
    componentDidMount() {
        console.log(this.props.userDetail)
        // let x = {
        //     firstName: this.props.userDetails.firstName,
        //     lastName: this.props.userDetails.lastName
        // }
        // this.setState({
        //     details: { ...x }
        // }, () => { console.log(this.state.details) })
    } 
    handleSubmit = e => {
        e.preventDefault();
        console.log(e);
        console.log(this.props)
        if(this.props.userDetail._id)
        {
          console.log("update admin")
          this.props.form.validateFields(
            // ["name", "email"],
            (err, values) => {
              if (!err) {
             
               
                  console.log("update  profile",values)
                  this.props.actions.editUserProfile(this.state.details, this.props.history);
     
              }
            }
          );
    
    
    
        // }
        // else{
        // this.props.form.validateFields(
        //   ["name", "phone", "address", "email"],
        //   (err, values) => {
        //     if (!err) {
           
        //       console.log(values, this.props.selectedcompanydata);
        //       this.props.actions.editfromprofile(this.props.selectedcompanydata._id, values)
        //     }
          }
        
      }
      



    onEdit = (e) => {
        console.log(e);
        this.props.actions.editUserProfile(this.state.details, this.props.history);
    };



    handleChange = (event, { name, value }) => {
        if (this.state.hasOwnProperty(name)) {
            console.log(event, { name, value })
            let x = { [name]: value }
            this.setState({ [name]: value });
            this.setState(
                prevState => ({
                    details: {
                        ...prevState.details,
                        ...x
                    }
                }),
                () => {
                    console.log(this.state.details);

                }
            );
        }

    }


    
    textInput = (e) => {
        console.log(e.target.name)
        console.log(e.target.value)
        let x = { [e.target.name]: e.target.value }
        this.setState(
            prevState => ({
                details: {
                    ...prevState.details,
                    ...x
                }
            }),
            () => {
                console.log(this.state.details);

            }
        );
    }




    render() {
        return (
            <div className="profilepagestart subpages forfeedback">
                <div className="registerfrm">
                    <Container>
                        <div className="profile-heading hidden-xs">
                            <h2>Edit Profile</h2>
                        </div>

                        <div className="sub-inerfrm">


                            <div className="profilepage">
                                <Grid>
                                    <Grid.Column computer={5} mobile={16}>
                                        <div className="profilediv">
                                            <img src={user} className="profileimg" />
                                            <div className="profilemain">
                                                <p className="profileinput">Name</p>
                                                <p className="profiletext">{this.props.userDetail.firstName+"  "+ this.props.userDetail.lastName}</p>
                                            </div>
                                        </div>

                                    </Grid.Column>
                                    <Grid.Column computer={6} mobile={16}>

                                        <div className="profilediv">
                                            <img src={email} className="profileimg" />
                                            <div className="profilemain">
                                                <p className="profileinput">Email</p>
                                                <p className="profiletext">{this.props.userDetail.email}</p>
                                            </div>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column computer={5} mobile={16}>
                                        <div className="profilediv">
                                            <img src={password} className="profileimg" />
                                            <div className="profilemain password">
                                                <p className="profileinput">Date of Birth</p>
                                                <p className="profiletext">{this.props.userDetail.dob}</p>
                                            </div>
                                        </div>
                                    </Grid.Column>
                                </Grid>
                                <NavLink to="/home/editprofile">
                                    <div className="editbutton">
                                        <img src={edit} className="editimg" />
                                        <span className="profilebtntext">Edit</span>
                                    </div>
                                </NavLink>
                            </div>




                        </div>
                    </Container>
                </div>

            </div>
        );
    }
}

// export default Profile;

const mapStateToProps = state => {
    return state;
}
function mapDispatchToProps(dispatch, state) {
    return {
        action: bindActionCreators(actioncreators, dispatch)
    }
}
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Profile)
);

