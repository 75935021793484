import React, { Component } from "react";
import "./Main.css";
import { Button, Card, Modal, Icon } from "semantic-ui-react";
import rightarrow from "../../Images/rightarrow.png";
import Slider from "react-rangeslider";
import solidwifi from "../../Images/solidwifi.png";
import solidplug from "../../Images/solidplug.png";
import MobileHeader from "../Home/MobileHeader";
import Header from "../Home/Header";
import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, NavLink } from "react-router-dom";
import feedback from '../../Images/feedback-desktop.png';
import { checkTableStatus,checkServiceTimeAgainstTable } from "../../services/sharedService";
import Alert from "./Alert";

class SelectServiceDrawerv2 extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            horizontal: {},
            // vertical1: 50
            // vertical: 50,
            // vertical1: 50,
            services: [],
            modalOpen: false,
            alertheading: "",
            alertcontext: "",
            alertbutton: "",
            imgtype: "",
            // vertical2: 50
        };
    }

    handleChangeHorizontal = (e, x) => {
        // console.log(value, ">>>>>>>>>>>>>L40");
        // this.setState({
        //   vertical: value
        // });
        if (e > 14) {
            console.log(e, x._id, ">>>>>>>>>>>>>L33");
            console.log(x._id, "L34?????????>>>>>>>>>>>>>");

            if(checkServiceTimeAgainstTable(this.props.selectedTable,e))
            {
            let data = this.state.services.map(z => {
                return {
                    serviceName: z.serviceName === x.name ? x.name : z.serviceName,
                    duration: z.serviceName === x.name ? (e * 60) : z.duration,  //15*60 seconds
                    // durationinNumber: 5,
                    cost: z.serviceName === x.name ? (x.cost * e / 15) : z.cost
                };
            });

            this.setState({ services: data }, () => { console.log(this.state.services) });


            this.setState(
                prevState => {
                    let horizontal = { ...prevState.horizontal };

                    horizontal[x._id] = e;

                    return { horizontal };
                },
                () => console.log(this.state.horizontal)
            );
        }
        else{
            this.setState({
                modalOpen: true,
                alertheading: "Error",
                alertcontext: `This table's services are inactive or will be inactive in ${e} mins. We request you to select a lower time interval or use the services later.`,
                alertbutton: "Close",
                imgtype: "error"
            });
        }
    }
    };

    closeModal = () => this.setState({ modalOpen: false });

    componentDidMount() {
        console.log(this.props.location, "L41>>>>>.");
        console.log(this.props.location.data, "L42>>>>>>>");
        if (this.props.location && this.props.location.data) {
            console.log("HERE!!!!!!!")
            console.log(this.props.location.data);
            let data = this.props.location.data.map(x => {
                return {
                    serviceName: x.name,
                    duration: 900,  //15*60 seconds
                    // durationinNumber: 5,
                    cost: x.cost
                };
            });

            this.setState({ services: data }, () => { console.log(this.state.services) });
        } else {
            console.log("NOT  HERE!!!!!!!")
            this.props.history.push("/Home/info");
        }
    }

    // handleChangeVertical1 = value => {
    //   this.setState({
    //     vertical1: value
    //   });
    // };

    solidImage = name => {
        var selectedObj = this.props.servicesList.find(x => {
            return x.name.toLowerCase() === name.toLowerCase();
        });

        return selectedObj.solid;
    };

    createOrder = async () => {
        this.props.actions.getTableDetails();
        let tableStatus = await checkTableStatus(sessionStorage.getItem('tableId'),Math.max.apply(Math,this.state.services.map(function(o) { return o.duration/60; })));
        if(!tableStatus)
        {
        this.setState({
            modalOpen: true,
            alertheading: "Error",
            alertcontext: `This table's services are inactive or will be inactive in 15 mins. We request you to not use the services now and come back later.`,
            alertbutton: "Close",
            imgtype: "error",
        });
        return;
    }
        
        let data = {
            tableId: this.props.selectedTable._id,
            orderedBy: this.props.userDetail._id,
            services: this.state.services
        };
        // console.log(data);   \\//\\//\\//\\//\\//\\//
        //      \/  \/  \/  \/  \/  \/  
        this.props.actions.createOrder(data, this.props.history);
        //  this.props.history.push("/payment");
    };
    getCost = (id, cost, name) => {
        //per 15 minutes
        console.log(id, cost, name)
        var x = (cost * this.state.horizontal[id]) / 15;
        return x.toFixed(2);
    };

    findUnitPrice = (id, arr) => {
        console.log(id, arr, "L109>>>>>")
        var object = arr.find(x => {
            return x._id === id;
        });

        console.log(object, "L114>>>>>>")

        return object.cost ? object.cost : 0;
    };
    getTotalPrice = obj => {
        var total = 0;
        if (this.props.location && this.props.location.data) {
            for (let i = 0; i < this.props.location.data.length; i++) {
                total += this.props.location.data[i].cost;
            }
            if (Object.keys(this.state.horizontal).length > 0) {
                console.log(obj);
                Object.keys(obj).forEach((key) => {

                    var unitPrice = this.findUnitPrice(key, this.props.location.data);
                    console.log(key, obj[key]);

                    total += (unitPrice * ((obj[key] / 15) - 1));
                });
            }
        }
        return total.toFixed(2);
    };

    render() {
        const { horizontal } = this.state;

        const horizontalLabels = {
            // 0: "5min",
            0: "15 MIN",
            // 15: "15min",
            // 30: "30min",
            // 45: "45min",
            // 60: "60min",
            // 75: "75min",
            90: "90 MIN"
        };
        const formatPc = p => p + " min";
        return (
            <div className="selectservicedrawer">
                <div className="hidden-md">
                    <MobileHeader />
                </div>
                <div className="hidden-xs">
                    <Header />
                </div>
                <div className="subpages">
                    <NavLink to="/home">
                        <img src={rightarrow} className="hidden-md arrwicn" />
                    </NavLink>
                    <div>
                        <div className="selectbox settingcard">
                            <div className="chrging-tmendcost">
                                <Card className="changercard newslidercard">
                                    {this.props.location &&
                                        this.props.location.data &&
                                        this.props.location.data.map((x, idx) => {
                                            return (
                                                <div className="horizontalsliderversion">
                                                    <div className="newservicesliderdetails">
                                                        <div className="newserviceinfo">
                                                            <p className="newservicetitle">Per 15 min</p>
                                                            <p className="newservicedetail"> ${(x.cost).toFixed(2)}</p>
                                                        </div>
                                                        <div className="newserviceicon">
                                                            <img
                                                                src={this.solidImage(x.name)}
                                                                className="newserviceicon"
                                                                alt={x.name}
                                                            />
                                                            <p className="newservicedetail">{x.name}</p>
                                                        </div>
                                                        <div className="newserviceselectinfo">
                                                            <p className="newservicetitle">Cost Selection</p>
                                                            <p className="newservicedetail">  {this.state.horizontal[x._id]
                                                                ? this.getCost(x._id, x.cost, x.name)
                                                                : (x.cost).toFixed(2)}</p>
                                                        </div>
                                                    </div>
                                                    <Slider
                                                        min={15}
                                                        max={90}
                                                        step={15}
                                                        value={
                                                            this.state.horizontal[x._id]
                                                                ? this.state.horizontal[x._id]
                                                                : 15
                                                        }
                                                        orientation="horizontal"
                                                        labels={horizontalLabels}
                                                        handleLabel={
                                                            <div className="textinsidenob">
                                                                <p className="timeno">
                                                                    {horizontal[x._id] ? horizontal[x._id] :
                                                                        15}
                                                                </p>
                                                                <p>
                                                                    MIN
                                                                   </p>
                                                            </div>
                                                        }
                                                        format={formatPc}
                                                        onChange={val =>
                                                            this.handleChangeHorizontal(val, x)
                                                        }
                                                        tooltip={false}
                                                    />
                                                    {horizontal[x._id] >= 15 ?
                                                        <div className="activerangefull15">
                                                        </div> :
                                                        <div className="inactiverangefull15">
                                                        </div>}
                                                    {horizontal[x._id] >= 30 ?
                                                        <div className="activerangefull30">
                                                        </div> :
                                                        <div className="inactiverangefull30">
                                                        </div>}
                                                    {horizontal[x._id] >= 45 ?
                                                        <div className="activerangefull45">
                                                        </div> :
                                                        <div className="inactiverangefull45">
                                                        </div>}
                                                    {horizontal[x._id] >= 60 ?
                                                        <div className="activerangefull60">
                                                        </div> :
                                                        <div className="inactiverangefull60">
                                                        </div>}
                                                    {horizontal[x._id] >= 75 ?
                                                        <div className="activerangefull75">
                                                        </div> :
                                                        <div className="inactiverangefull75">
                                                        </div>}
                                                    {horizontal[x._id] >= 90 ?
                                                        <div className="activerangefull90">
                                                        </div> :
                                                        <div className="inactiverangefull90">
                                                        </div>}
                                                </div>
                                            );
                                        })}
                                </Card>
                            </div>

                            <div className="servcdrawrbutn">
                                <div className="totalprice">
                                    <p className="pricetext">
                                        {" "}
                                        ${this.getTotalPrice(this.state.horizontal)}{" "}
                                        <span>(Total Price)</span>
                                    </p>
                                </div>
                                <div className="paymentbtnwidth">
                                    <Button
                                        className="totalprice paymentbutn"
                                        onClick={() => this.createOrder()}
                                    >
                                        <p className="paymnttxt">Payment</p>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal open={this.state.modalOpen} onClose={this.closeModal}>
                    <Modal.Content className="paymentsms-content">
                        <Modal.Content>
                            <div className="closecrossbtn">
                                <Icon onClick={this.closeModal} name="close" />
                            </div>
                            <Alert
                                alertheading={this.state.alertheading}
                                alertcontext={this.state.alertcontext}
                                alertbutton={this.state.alertbutton}
                                imgtype={this.state.imgtype}
                                butnfunction={() => this.closeModal()}
                            />
                            {/*this.closeModal */}
                        </Modal.Content>
                    </Modal.Content>
                    </Modal>
                    {!this.state.loading &&
                        <NavLink to="/home/feedback">
                            <div className="menufeedback hidden-xs">
                                <img src={feedback} className="usericn feedbackimg" />
                                <span className="homenav feedbackdespotop">Give Feedback</span>
                            </div>
                        </NavLink>
                    }
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch, state) {
    return {
        actions: bindActionCreators(actioncreators, dispatch)
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SelectServiceDrawerv2)
);
