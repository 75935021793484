

import wifiSelected from '../../Images/selectsettings/wifiwhite.png' //white; bg=yellow; selected state,
import chargerFree from '../../Images/Group130.png'; //yellow; bg=white; free state,
import wifiFree from '../../Images/wifi2.png';  //yellow; bg=white; free state,
import chargerSelected from '../../Images/Path64.png'; //white; bg=yellow; selected state,
import lightselected from '../../Images/light_white.png';
import lightfree from '../../Images/lightcard.png'
import solidwifi from '../../Images//solidwifi.png';
import newwifi from '../../Images/wifi_.png';
import newpower from '../../Images/Power_.png'
import newlight from '../../Images/Group-467.png'
import solidplug from '../../Images//solidplug.png';
import wifi from "../../Images/wifistatus.png";
import plug from "../../Images/plugcolor.png";
import phone from '../../Images/phone-call_01_.png';
import whitePhone from '../../Images/phone-call_02_.png';
import newphone from '../../Images/phone-call.png';
import orangeSpeaker from '../../Images/Speaker.png';
import whiteSpeaker from '../../Images/Speaker-02.png';
import orangeUsb from '../../Images/usborange.png';
import whiteUsb from '../../Images/usbwhite.png';


var data = [
  { name: "Wi-Fi", small: wifi, selected: wifiSelected, default: wifiFree, solid: newwifi },
  {
    name: "Light", small: plug, selected: lightselected, default: lightfree, solid: newlight
  },
  {
    name: "Power", small: plug, selected: chargerSelected, default: chargerFree, solid: newpower
  },
  {
    name: "Phone", small: phone, selected: whitePhone, default: phone, solid: newphone
  },
  {
    name: "Bluetooth Speaker", small: orangeSpeaker, selected: whiteSpeaker, default: orangeSpeaker, solid: orangeSpeaker
  },
  {
    name: "USB Power", small: orangeUsb, selected: whiteUsb, default: orangeUsb, solid: orangeUsb
  }
];







export function servicesList(state = data, action) {
  switch (action.type) {
    case 'SERVICES_LIST':
      console.log(action.data);
      return action.data
    default:
      return state
  }
}