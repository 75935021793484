import React, { Component } from 'react';
import './Modal.css';
import { Grid, Image, Button, Dropdown, Menu, Header, Icon, Modal } from 'semantic-ui-react'
import PropTypes from 'prop-types';
import logout from '../../Images/logout.png'

type Props = { ConfirmationModal: String, modalcontnt: string, modalheadr: string,
    modalbutnclass: string,  btntxt: string, contentclass:string, modalbutnclass1:string, btntxt1:string,
    modalhederclass: string,handleOpen:Function,handleClose:Function,modalOpen:Boolean, modalbutton:string, functn:Function, modalshowbtn:string, modalshwbutncls:string, modalcontntimg:string, modalshowtext:string, } 

class ConfirmationModal extends Component<Props, State> {
    static propTypes = {handleOpen:PropTypes.func,handleClose:PropTypes.func,modalOpen:PropTypes.bool, ConfirmationModal: PropTypes.string, modalcontnt: PropTypes.string,btntxt1:PropTypes.string, modalbutnclass1:PropTypes.string, 
        modalheadr: PropTypes.string, modalbutnclass:PropTypes.string, btntxt:PropTypes.string, 
        contentclass: PropTypes.string, modalhederclass: PropTypes.string, modalbutton: PropTypes.string , functn: PropTypes.func, 
        modalshowbtn: PropTypes.string, modalshwbutncls: PropTypes.string, modalcontntimg: PropTypes.string, modalshowtext: PropTypes.string,  }

    render() {
        return (
            <div className="confrmmodal">
                <Modal className={this.props.ConfirmationModal}  open={this.props.modalOpen}
        onClose={this.props.handleClose} trigger={<Button onClick={this.props.handleOpen} className={this.props.modalshwbutncls}> <img src={this.props.modalshowbtn}/>{this.props.modalshowtext} </Button>} closeIcon>
                    <Header className={this.props.modalhederclass}><img src={this.props.modalcontntimg} className="modalcontnticn"/><span>{this.props.modalheadr}</span></Header>
                    <Modal.Content className={this.props.contentclass}>
                        <p>{this.props.modalcontnt}</p>
                    </Modal.Content>
                    <Modal.Actions className={this.props.modalbutton}>
                        <Button className={this.props.modalbutnclass} onClick={this.props.handleClose} >{this.props.btntxt}</Button>
                        <Button className={this.props.modalbutnclass1} onClick={this.props.functn}>{this.props.btntxt1}</Button>
                        {/* <Button color='red'>
                            <Icon name='remove' /> No
                         </Button>
                        <Button color='green'>
                            <Icon name='checkmark' /> Yes
                          </Button> */}
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

export default ConfirmationModal;
