// Notification

import React, { Component } from 'react';
import './Main.css';
import checked from '../../Images/checked-symbol.png'
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import error from '../../Images/error.png'
import { Grid, Button, Form, Modal, GridColumn, Segment, Portal } from 'semantic-ui-react'

type Props = {
  portaltext: string,handleClose:Function,open:Boolean,doneFunction:Function, donebutn:string, closebutn:string,
}

class Notification extends Component<Props, State>{
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     open: false,
  //   }
  // }
  // handleClose = () => this.setState({ open: false })
  // handleOpen = () => this.setState({ open: true })

  static propTypes = {
    portaltext: PropTypes.string,handleClose:PropTypes.func,open:PropTypes.bool,doneFunction:PropTypes.func, donebutn:PropTypes.string,
    closebutn: PropTypes.string,
  }
  render() {
    // const { open } = this.state
    return (
      <div className="notification">
        <Portal onClose={this.props.handleClose} open={this.props.open}>
          <Segment
            style={{
              // left: '40%',
              position: 'fixed',
              // top: '15%',
              zIndex: 1000,
              width: '25%',
            }}
          >
            <p>{this.props.portaltext}</p>

            {/* <p>Your current credit balance is: 25, which is less than order cost 95</p> */}
            <Button
              content={this.props.closebutn}
              negative
              onClick={this.props.handleClose}
            />
            <Button
              content={this.props.donebutn}
              // negative
              onClick={this.props.doneFunction}
            />
            {/* <Modal trigger={<Button>Done</Button>}>
              <Modal.Content>
                <Alert alertheading="Payment Successful"
                  alertcontext="Your payment was successful! You can now continue using ShadeCharger."
                  alertbutton="Done" imgtype="success" />
              </Modal.Content>
            </Modal> */}
          </Segment>
        </Portal>
      </div>
    );
  }
}

export default Notification;
