import React, { Component } from "react";
import "./LandingPage.css";
import "semantic-ui-css/semantic.min.css";
import { Grid, Image, Button, Container, Form, Input } from "semantic-ui-react";

import Shadecharger from "../../Images/Shadecharger.png";
import logo from "../../Images/bluelogo.png";
import logoformobile from "../../Images/logo-2.png";
import tick from '../../Images/tick.svg';
import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, NavLink } from "react-router-dom";
import { isDEV } from "../../services/sharedService";

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }






  render() {
    // const { getFieldDecorator } = this.props.form;
    return (
      <div className="signupform mobileviewclass landingpage">
      <div className='overlaylanding hidden-md'></div>
        <Container>

          <div className='landingpagelogodiv hidden-xs'>
            <NavLink to="/home">
              <img src={logo} className='landinglogoimg' />
            </NavLink>
          </div>
          <div className='landingpagelogodiv hidden-md'>
            <NavLink to="/home">
              <img src={logoformobile} className='landinglogoimg' />
            </NavLink>
          </div>

          <div className="toplogosec">
            <p > Welcome!</p>
          </div>
          <h3 className="powersecheading">Remote Power For A Connected World</h3>
          <div className="sublandingpage">
            <div className='landingpagetext'>
              <ul className="powersecpoints">
                <li>
                  <img src={tick} className='landingtickcls' />
                  Solar Power For Picnic Tables
                          </li>
                <li>
                  <img src={tick} className='landingtickcls' />
                  110V Ac Power & USB Outlets
                          </li>
                <li>
                  <img src={tick} className='landingtickcls' />
                  Wifi Internet Hotspot
                          </li>
                <li>
                  <img src={tick} className='landingtickcls' />
                  Blutooth Controlled Speakers
                          </li>

              </ul>
              <ul className="powersecpoints">
                <li>
                  <img src={tick} className='landingtickcls' />
                 Automatic Security Lights
                        </li>
                <li>
                  <img src={tick} className='landingtickcls' />
                  Rugged Concrete Picnic </li>
                <li>
                  <img src={tick} className='landingtickcls' />
                 Attractive Round Cafe Table</li>
                <li>
                  <img src={tick} className='landingtickcls' />
                  911 Call Box Capability
                        </li>

              </ul>
            </div>

            <div className="formbutn forlndnpage">
              <NavLink to="/signin">
                <Button type="submit" className='landingloginbutton'>
                  Login Now
                        </Button>
              </NavLink>
              <NavLink to='/signup'>
                <Button type="submit">
                  Create Account
                        </Button>
              </NavLink>
            </div>
          </div>
        </Container>
        
      </div>
    );
  }
}
// export default SignIn;
// const WrappedSignIn = Form.create({ name: "horizontal_signin" })(SignIn);

const mapStateToProps = state => {
  return state;
};

function mapDispatchToProps(dispatch, state) {
  return {
    actions: bindActionCreators(actioncreators, dispatch)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LandingPage)
);
