import React, { Component } from 'react';
import './Admin.css';
import 'semantic-ui-css/semantic.min.css'
import AdminHeader from './AdminHeader';
import { Grid, Image, Button, Container } from 'semantic-ui-react'
import { Route, Switch, withRouter } from "react-router-dom";
import TableList from './TableList';
import OrderList from './OrderList';
import UserList from './UserList';
import PaymentList from './PaymentList';
import Adminsettings from './Adminsettings';
import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AdminVoucherList from './AdminVoucherList';
import VoucherCreationForm from './VoucherCreationForm';
import CompanyList from './CompanyList';
import FeedbackList from './FeedbackList';

class Adminmain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false
        }

    }


    componentDidMount() {
        // this.props.actions.getTableList();
        // this.props.actions.getPaymentList();
        // this.props.actions.getUsersList();
        console.log(this.props.userDetail)
        if (this.props.userDetail.role !== "admin" && this.props.userDetail.role !== "companyAdmin") {
            console.log("pushing out>>>>>>")
            this.props.history.push("/")
        }

        // if (this.props.userDetail.role === "companyAdmin") {
        //     //   if  (this.props.storeselectedcompany){
        //         console.log(this.props.tableList)
        //         console.log(this.props.userDetail._id)
        //         this.props.actions.findTableListByCompany(this.props.userDetail._id)
        //     // }
        // }
       
// console.log(this.props.getTableList)

    }

    // componentWillReceiveProps(newProps) {

    //     if (!Object.is(newProps.tableList, this.props.tableList)) {

       
    //         this.setState({ data: newProps.tableList }, () => {
    //             console.log("Recieving props>>>>>>>", newProps.tableList)
    //         })
    //     }



    // }


    render() {
        return (
            <div className="adminmainstart">
                <AdminHeader />
                <Switch>
                    {/* {this.props.userDetail.role=== "companyAdmin" && this.state.disabled ===true && */}
                    {this.props.userDetail.role === "admin" &&
                        <Route
                            exact
                            path={`${this.props.match.url}`}
                            component={CompanyList}
                        />
                    }
                    {this.props.userDetail.role === "admin" &&
                        <Route
                            exact
                            path={`${this.props.match.url}/userlist`}
                            component={UserList}
                        />
                    }  {this.props.userDetail.role === "admin" &&
                        <Route
                            exact
                            path={`${this.props.match.url}/orderList`}
                            component={OrderList}
                        />
                    }
                    {this.props.userDetail.role === "admin" &&
                        <Route
                            exact
                            path={`${this.props.match.url}/paymentlist`}
                            component={PaymentList}
                        />
                    }
                    {this.props.userDetail.role === "admin" &&
                        <Route
                            exact
                            path={`${this.props.match.url}/adminsettings`}
                            component={Adminsettings}
                        />
                    }
                    {this.props.userDetail.role === "admin" &&
                        <Route
                            exact
                            path={`${this.props.match.url}/voucherlist`}
                            component={AdminVoucherList}
                        />
                    }
                    {this.props.userDetail.role === "admin" &&
                        <Route
                            exact
                            path={`${this.props.match.url}/feedbacklist`}
                            component={FeedbackList}
                        />
                    }
                     {this.props.userDetail.role === "admin" ?
                    <Route
                        exact
                        // path={`${this.props.match.url}`}
                        path={`${this.props.match.url}/tablelist`}
                        component={TableList}
                    />:
                    <Route
                        exact
                        path={`${this.props.match.url}`}
                        // path={`${this.props.match.url}/tablelist`}
                        component={TableList}
                    />}
                    {/* <Route
                        exact
                        path={`${this.props.match.url}/vouchercreation`}
                        component={VoucherCreationForm}
                    /> */}
                        {this.props.userDetail.role === "admin" ?
                    <Route
                        exact
                        // path={`${this.props.match.url}`}
                        path={`${this.props.match.url}/OrderList`}
                        component={OrderList}
                    />:
                    <Route
                        exact
                        path={`${this.props.match.url}`}
                        path={`${this.props.match.url}/OrderList`}
                        component={OrderList}
                    />}

                </Switch>
            </div>
        );
    }
}

// export default Adminmain;

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch, state) {
    return {
        actions: bindActionCreators(actioncreators, dispatch)
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Adminmain)
);

