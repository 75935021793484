

export function companyList(state = [], action) {
    switch (action.type) {
        case  "COMPANY_LIST":
            console.log(action.data);
            return action.data
        default:
            return state
    }
}