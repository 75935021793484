import React, { Component } from "react";
import "./Form.css";
import "semantic-ui-css/semantic.min.css";
import { Grid, Image, Button, Container, Form, Input, TextArea } from "semantic-ui-react";
import { withRouter, NavLink } from "react-router-dom";
import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import closeicon from '../../Images/close.png';
// import { withRouter, NavLink } from 'react-router-dom';

class FeedbackForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            details: {},
            replies: [],
            showmsg: false

        }
    }



    textInput = e => {

        let x = { [e.target.name]: e.target.value };
        this.setState(
            prevState => ({
                details: {
                    ...prevState.details,
                    ...x
                }
            })
            //   () => {
            //     console.log(this.state.details)
            //   })
        );

    };
// componentDidMount(){
//     console.log(this.pprop.selectedTable)
// }

changeMsgState=()=>{
    this.setState(
        prevState => ({
            showmsg: 
            !prevState.showmsg
          
        }),()=>{console.log(this.state.showmsg)})
       
}


    createFeedBack = (e) => {
        console.log(e)
        // let x ={};
        // x = this.props.tableList;

        console.log(this.props.selectedTable,"l48")
        const reqValues = {
            method: "POST",
            body: JSON.stringify({
                createdBy:  this.props.userDetail._id,
                title: this.state.details.title,
                description: this.state.details.description,
                // replies: x,
                companyId: this.props.companyList._id,
                tableNumber: this.props.selectedTable.tableNumber,

                }),

            headers: {
                "Content-Type": "application/json"
            }
        };
        fetch(process.env.REACT_APP_apiurl + "/feedBack/createfeedBack", reqValues )
            
            .then(
                result =>

                    result.json()

            )

            .then(result => {
                console.log(result);
                // this.props.onClose();
                
                if (!result.error ) {

                    this.props.actions.createFeedBack(result.result)
                      
                    console.log("feedBack created successfully>.......45", )
                    this.setState({title: "", description: "", message: "Feedback Submitted Successfully"},
                    ()=>{
                        // this.setState({showmsg:true});
                // console.log(this.state.message)
                this.changeMsgState();
                setTimeout(this.changeMsgState,5000)
                
            })

                     
                    // this.props.form.resetFields();
                
                }
               
            })
            
            .catch(err => {
                console.log(err);
            });
    }
    

    componentDidMount(){
        console.log("HERE!!!!!!!!!!!EREH")
    }


    goBack=()=>{

        this.props.history.go(-1);

    }


render() {

    return (
        <div className="feedbackpage">
            <div className="signupform signinform feedbacksection">
                <div className="profile-heading feedbackheading hidden-md">
                    <h2>Give feedback</h2>
                </div>
                <div className="sub-inerfrm signin-subfrm">
                <div className="close-feedback">
                <img src={closeicon} className="closeicon" alt="close" onClick={this.goBack}/>
                </div>
                {this.state.showmsg===true?
                <p className= "success" > {this.state.message}</p>:null}
                    <Container>
                        <Form className="formfield">
                        {this.props.selectedTable.tableNumber &&
                        <div className="feedbackformfld titlefild">
                                <label className="labelnme">Table Id:  {this.props.selectedTable.tableNumber}</label>
                                </div>}
                            <Form.Field className="feedbackformfld titlefild">
                                <label className="labelnme">Title</label>
                                <Input iconPosition='left' name="title" onChange={this.textInput} type="text"
                                value={this.state.title}>
                                </Input>

                            </Form.Field>
                            <Form.Field className="deatils-textarea feedbackformfld">
                                <label className="labelnme">Your Comment</label>
                                <TextArea name="description" type="text" onChange={this.textInput} 
                                value={this.state.description}/>
                            </Form.Field>
                            {/* {this.props.selectedTable.tableNumber &&
                            <Form.Field className="deatils-textarea feedbackformfld">
                                <label className="labelnme">Table Number</label>
                                <TextArea name="description" type="text" onChange={this.textInput} />
                            </Form.Field>
                            } */}
                            <div className="formbutn feedbackbtn">
                                <Button type="submit" onClick={this.createFeedBack}>
                                    Submit
                                </Button>
                                
                            </div>
                        </Form>
                    </Container>
                </div>
            </div>
        </div>
    );
}
}

// export default FeedbackForm;


const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch, state) {
    return {
        actions: bindActionCreators(actioncreators, dispatch)
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(FeedbackForm)
);
