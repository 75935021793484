import React, { Component } from 'react';
import '../Main/Main.css';
import './Admin.css';
import 'semantic-ui-css/semantic.min.css'
import { Image, Button, Container, Checkbox, Form, Icon, Input, Card } from 'semantic-ui-react'
// import { NavLink } from 'react-router-dom';
import MaterialTable from 'material-table';
import filter from '../../Images/filter.png';
import { withRouter, NavLink } from "react-router-dom";
import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
var moment = require("moment");
class LeftFeedbackList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // details: {},
            // replies: []

        }
    }

    componentDidMount (){
        this.props.actions.getAllFeedBacks()
        console.log(this.props.feedBacklist);
    }


    clickfeedBack=(x)=>{
        console.log(x._id,"L49>>>>>>")
        // this.setState({selectedfeedback:x})
        this.props.handleClick(x._id)
    }

 


    render() {
        // var arr= this.props.feedBacklist;
        // console.log(arr)
        // console.log(arr.indexOf(arr._id));

        return (
            <div className="leftfeedbacksec">
                <p className="leftsidetitlesec">Feedback <span className="filterimgspan">
                {/* <img src={filter} className="filterimg" /> */}
                </span></p>
              
                <div className="totalfeedbacklisting">
                {/* {this.props.feedBacklist.length > 0 && */}
                {this.props.feedBacklist.map((x,index) => {
                    return (
                    <div className="feedbackcardlist"
                    // onClick={this.props.handleClick}
                    onClick={()=>this.clickfeedBack(x)}
                   
                    >
                    
                        <div className="feedbacknumbering">
                            <p className="numbertext">{index+1}</p>
                        </div>
                        <div className="feedbacklistdesc">
                            <p className="feedbacktitle">{x.title} </p>
                            <span className="feedbackdesc">{x.description}</span>
                            {x.tableNumber &&
                            <p className="posteddetails">Table Number 
                            <span className="feedbackdesc">{ x.tableNumber}</span>
                         </p>}
                            <p className="posteddetails">Posted on <span className="posteddate">{x.createdDate && moment(parseInt(x.createdDate)).format("YYYY-MM-DD HH:mm")}</span></p>
                        </div>
                    </div>
                )})}
                </div>
               
            </div>
        );
    }
}

// export default LeftFeedbackList;

const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch, state) {
    return {
        actions: bindActionCreators(actioncreators, dispatch)
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(LeftFeedbackList)
);
