import React, { Component } from 'react';
import './Main.css';
import 'semantic-ui-css/semantic.min.css'
import { Form, Input, Button, GridColumn } from "semantic-ui-react";
import QrScanner from 'qr-scanner';
// import QrReader from 'react-qr-scanner'

class CodeScanner extends Component {
  constructor(props) {
    super(props)
    this.videoElem = React.createRef()
    this.state = {
      delay: 100,
      result: 'No result',
      tableId: ''

    }

    this.handleScan = this.handleScan.bind(this)
  }
  componentDidMount() {
    // console.log(document.getElementById('video'))
    // const qrScanner = new QrScanner(document.getElementById('video'), result => console.log('decoded qr code:', result),10);

  }
  handleScan(data) {
    console.log(data)
    this.setState({
      result: data,

    })
  }
  handleError(err) {
    console.error(err)
  }
  enterTableNo = (e) => {
    console.log(e.target.value)
    this.setState({ tableId: e.target.value })
  }
  clickButton=()=>{
    window.location.replace('/Home/info?id='+this.state.tableId)
  }

  render() {

    const previewStyle = {
      width: '100%',
      margin: '0 auto'
    }
    return (
      <div className="inner-summary subpages codescannersectionstart">
        <Form className="formfield emailfield">
          <Form.Field>
            <label className="labelnme">Table Id: </label>
            <Input
              type="text"
              iconPosition="center"
              onChange={this.enterTableNo}
            />
          </Form.Field>
          <Form.Field>
            <Button type='submit' onClick={this.clickButton}>Submit</Button>
          </Form.Field>
        </Form>
        {/* <div className="scannersecdiv"> */}
        {/* <QrReader
          delay={this.state.delay}
          style={previewStyle}
          onError={this.handleError}
          onScan={this.handleScan}
          facingMode={'user'}
          /> */}
        {/* <video id="video"> </video> */}
        {/* <div className="scanningoptiondiv">
            <div class='anim-box'> */}
        {/* <!-- empty first child used for corners --> */}
        {/* <div></div>
    <div class='scanner'></div>
  </div>
  </div>
     </div> */}

      </div >
    );
  }
}

export default CodeScanner;