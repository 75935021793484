import React, { Component } from "react";
import "./Main.css";
import "semantic-ui-css/semantic.min.css";
import {
  Grid,
  Image,
  Button,
  Container,
  Checkbox,
  Form,
  Icon,
  Input,
  Card,
  Modal
} from "semantic-ui-react";
// import {withRouter, NavLink } from 'react-router-dom';
import user from "../../Images/avatar.png";
import password from "../../Images/password.png";
import email from "../../Images/close-envelope.png";
import edit from "../../Images/profileedit.png";
import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, NavLink } from "react-router-dom";
import FeedbackForm from "../Form/FeedbackForm";
import DropIn from "braintree-web-drop-in-react";
import Alert from "./Alert";
import PayPalCheckout from "./PayPalPage";
class AddCreditPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redeemId: "",
      showModal: false,  //alert dialogue
      creditValue: 0,
      payment:false  // payment dialogue
    };
  }
  getVouchers = () => {
    const reqValues = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token")
      }
    };
    fetch(
      process.env.REACT_APP_apiurl + "/voucher/getAllActiveVoucher",
      reqValues
    )
      .then(result => result.json())
      .then(result => {
        console.log(result);
        this.setState({ vouchers: result.result });
      })
      .catch(err => {
        console.log(err);
      });
  };
  async componentDidMount() {
    this.setState({ Message: null });
    console.log(this.props.voucherList);
    //   this.props.actions.getVoucherList()  <<DO NOT UNCOMMENT THIS!!
    // get voucher list>>
    console.log(this.props.userDetail);
    this.getVouchers();

    console.log(this.props.currentOrder);
    console.log(this.props.userDetail);
    const reqValues = {
      method: "GET",
      headers: {
        authorization: localStorage.getItem("token")
      }
    };
    const response = await fetch(
      process.env.REACT_APP_apiurl + "/payment/gettoken",
      reqValues
    );
    console.log(response);
    const clientToken = await response.json(); // If returned as JSON string
    console.log(clientToken);

    this.setState({
      clientToken: clientToken.token
    });
  }
  //enterCredidAmount
  enterVoucher = e => {
    this.setState({ redeemId: e.target.value }, () => {
      console.log(this.state.redeemId);
    });
  };
  enterCreditAmount = e => {
    this.setState({ creditValue: e.target.value }, () => {
      console.log(this.state.creditValue);
    });
  };

  redeemVoucher = () => {
    if (this.state.redeemId.length !== 6) {
      this.setState(
        {
          showModal: true,
          alertcontext: "Error!",
          alertheading: "Incorrect voucher code, please check.",
          alertbutton: "Close",
          imgtype: "warning"
        },
        () => {
          console.log(this.state.showModal);
        }
      );
    } else {
      const reqValues = {
        method: "PUT",
        // body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token")
        }
      };
      fetch(
        process.env.REACT_APP_apiurl +
          "/voucher/consume?id=" +
          this.state.redeemId,
        reqValues
      )
        .then(result => result.json())

        .then(result => {
          console.log(result);
          this.setState({ showModal: true });
          if (!result.error) {
            //Success dialog
            this.setState({
              alertcontext: "Success!",
              alertheading:
                result.voucherValue + "$ has been added to your credit!",
              alertbutton: "Close",
              imgtype: "success"
            });
          } else {
            this.setState({
              alertcontext: "Incorrect voucher code!",
              alertheading:
                "Your voucher has expired/ already redeemed by someone else.",
              alertbutton: "Close",
              imgtype: "error"
            });
          }
        })
        .catch(err => {
          console.log(err, "L96>>");
        });
    }
  };

  payByPal=()=>{
      if(this.state.creditValue>0)
      {
      this.setState({payment:true})
      }
      else{
        this.setState({
            showModal: true,
            alertcontext: "Incorrect amount entered!",
            alertheading:
              "Please enter correct amount!",
            alertbutton: "Close",
            imgtype: "error"
          });
      }
  }
  callBackMsg=(text)=>{

    console.log('SAy what?? '+text)
    if(text==='Success')
    {
        this.setState({
            payment:false,
            showModal: true,
            alertcontext: "Success!",
            alertheading:
              this.state.creditValue + "$ has been added to your credit!",
            alertbutton: "Close",
            imgtype: "success"
          });
      }
    
    else{
        this.setState({
            payment:false,
            showModal: true,
            alertcontext: 'Error!',
            alertheading:
              'Server error!',
            alertbutton: "Close",
            imgtype: "error"
          });
    }

  }
  render() {
    return (
      <div className="inner-summary subpages addcreditpagestart">
        <div className="addcreditvouchersec">
          <p className="title">Add credit using voucher</p>
          <div className="promocodediv">
            <input
              type="text"
              placeholder="Enter promo code"
              onChange={this.enterVoucher}
            />
            <Button className="promosubmissionbtn" onClick={this.redeemVoucher}>
              Apply
            </Button>
          </div>
        </div>
        <hr className="lightborderdivider" />

        <div className="addcreditcardsec">
          <p className="ortext">or</p>
          <p className="title">Add credit using Card</p>
          {/* <div>
                        <DropIn
                            options={{ authorization: this.state.clientToken }}
                            onInstance={instance => (this.instance = instance)}
                        />

                        <p>Demo Card: 4111 1111 1111 1111</p>
                        <p>Expiry: 09/20</p>
                        <Button className="redeemvouchr-butn" onClick={() => {
                            this.paymentfunc()
                            //   this.findbutnfunction()
                        }}>Pay</Button>
                    </div> */}
          <div className="promocodediv">
            <Input iconPosition="left" placeholder="Enter credit amount">
              <Icon name="dollar" />
              <input type="number" onChange={this.enterCreditAmount} />
            </Input>

            <Button className="promosubmissionbtn" onClick={this.payByPal}>Submit</Button>
          </div>
          {this.state.showModal && (
            <Modal
              className="alertmodal"
              open={this.state.showModal}
              onClose={() => this.setState({ showModal: false },()=>{this.setState({creditValue:0})})}
            >
              <Modal.Content>
                <div className="closecrossbtn">
                  <Icon
                    onClick={() => this.setState({ showModal: false },()=>{this.setState({creditValue:0})})}
                    name="close"
                  />
                </div>
                <Alert
                  alertheading={this.state.alertheading}
                  alertcontext={this.state.alertcontext}
                  alertbutton={this.state.alertbutton}
                  imgtype={this.state.imgtype}
                  butnfunction={() => this.setState({ showModal: false },()=>{this.setState({creditValue:0})})}
                />
              </Modal.Content>
            </Modal>
          )}

          {this.state.payment && (
            <Modal
              className="vouchermodal"
              size={"tiny"}
              open={this.state.payment}
              onClose={()=>{this.setState({payment:false})}}
            >
              <Modal.Content className="voucher-modalcontent">
                <PayPalCheckout
                  cost={this.state.creditValue}
                //   orderId={this.props.currentOrder._id}
                  success={this.callBackMsg}
                />
              </Modal.Content>
            </Modal>
          )}
        </div>
      </div>
    );
  }
}

// export default Profile;

const mapStateToProps = state => {
  return state;
};
function mapDispatchToProps(dispatch, state) {
  return {
    action: bindActionCreators(actioncreators, dispatch)
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddCreditPage)
);
