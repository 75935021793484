
import React, { Component } from 'react';
import './Admin.css';
import 'semantic-ui-css/semantic.min.css'
import { Grid, Image, Button, Container, Checkbox, Form, Icon, Input, Card, Radio, GridColumn, TextArea } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom';
import {
    DateInput,
    TimeInput,
    DateTimeInput,
    DatesRangeInput
} from 'semantic-ui-calendar-react';


class AddPaymentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: '',
            time: '',
            dateTime: '',
            datesRange: ''
        };
    }
    handleChange = (event, { name, value }) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }
    }

    render() {

        return (
            <div className="tableliststart adminvoucherform">
                <div style={{ maxWidth: '100%' }} className="tablediv voucherform">
                    <div className="formfieldarea">

                        <Form className="formfield emailfield">
                            <Grid columns={3} divided>
                                <Grid.Column className="border-remove">
                                    <Form.Field className="datepickerfield">
                                        <label className="labelnme">Date</label>
                                        <DateInput
                                            name="vouchervalidity"
                                            // placeholder="Date"
                                            value={this.state.date}
                                            iconPosition="left"
                                            onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column className="border-remove">
                                    <Form.Field>
                                        <label className="labelnme">User Name</label>
                                        <Input iconPosition='left' type="text" name="user name" />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column className="border-remove">
                                    <Form.Field>
                                        <label className="labelnme">Amounts</label>
                                        <Input iconPosition='left' type="number" name="amount" />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid>

                            <div className="formbutn addeditmodal-formbutn"><Button type='submit'>Submit</Button></div>
                        </Form>

                    </div>
                </div>
            </div>
        );
    }
}

export default AddPaymentForm;
