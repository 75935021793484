import React, { Component } from 'react';
import './Home.css';
import 'semantic-ui-css/semantic.min.css';
import { Fade } from 'react-slideshow-image';
import home1 from '../../Images/slider4.jpg';
import home2 from '../../Images/slider1.jpg';
import home3 from '../../Images/slider3.jpg';
import { Grid, Image } from 'semantic-ui-react'
const fadeProperties = {
    duration: 1000,
    transitionDuration: 1000,
    infinite: true,
    indicators: true
}
class Carousel extends Component {
    render() {
        return (
            <div className="carouselstart">
                <div className="carouselpart">
                    <Fade {...fadeProperties} >

                        <div className="each-fade">
                            <div className="image-container">
                                <img src={home1} className="overlay color" />
                            </div>
                        </div>
                        <div className="each-fade">
                            <div className="image-container">
                                <img src={home2} className="overlay color" />
                            </div>
                        </div>
                        <div className="each-fade">
                            <div className="image-container">
                                {/* <div className="imgsec"></div> */}
                                <img src={home3} className="overlay color" />
                            </div>
                        </div>
                    </Fade>

                    <div className="welcometext">
                        <Grid>
                        <Grid.Column mobile={16} tablet={8} computer={1}>
                        </Grid.Column>
                            <Grid.Column mobile={12} tablet={8} computer={7}>
                                <p className="firstwelcome">WELCOME TO</p>
                                <p className="secondlinetext">SHADE <span className="chargerspan">CHARGER</span></p>
                                <p className="descriptntext">Lorem ipsum dolor sit amet, consectetur 
                                adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore 
                                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
                                ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={8} computer={8}>
                            </Grid.Column>
                        </Grid>
                    </div>
                </div>
            </div>
        );
    }
}

export default Carousel;
