
import React, { Component } from 'react';
import './Admin.css';
import 'semantic-ui-css/semantic.min.css'
import { Grid, Image, Button, Container, Checkbox, Form, Icon, Input, Card, Radio, GridColumn, TextArea } from 'semantic-ui-react'
// import { NavLink } from 'react-router-dom';
import {
    DateInput,
    TimeInput,
    DateTimeInput,
    DatesRangeInput
} from 'semantic-ui-calendar-react';
import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, NavLink } from "react-router-dom";
function onChange(date, dateString) {
    console.log(date, dateString);
}
class AddUserListForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: '',
            time: '',
            dateTime: '',
            datesRange: '',
            details: {}
        };
    }
    // handleChange = (event, { name, value }) => {
    //     if (this.state.hasOwnProperty(name)) {
    //         this.setState({ [name]: value });
    //     }
    // }
    
    textInput = (e) => {
        console.log(e.target.name)
        console.log(e.target.value)
        let x = { [e.target.name]: e.target.value }
        this.setState(
            prevState => ({
                details: {
                    ...prevState.details,
                    ...x
                }
            }),
            () => {
                console.log(this.state.details);

            }
        );
    }
    createUsers = (e) => {
        console.log(e)
        // console.log(this.onChange)
        var regex = /\d+/g;
        var string = this.state.details.phone;
        var matches = string.match(regex);
        console.log(matches);
        console.log(matches.join(""));

        this.state.details.phone = matches.join("");

        this.setState({ nonmatchingPassword: false })
        let x = this.state.details

        this.props.actions.createUser(x, this.props.history, "admin"
        )
        this.props.onClose();
        console.log("user created successfully>.......62", )
        // }
    }


    render() {

        return (
            <div className="tableliststart adminvoucherform">
                <div style={{ maxWidth: '100%' }} className="tablediv voucherform">
                    <div className="formfieldarea">

                        <Form className="formfield emailfield">
                            <Grid columns={3} divided>
                                <Grid.Column className="border-remove">
                                    <Form.Field>
                                        <label className="labelnme">First Name</label>
                                        <Input iconPosition='left' type="text" name="firstName" onChange={this.textInput} />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column className="border-remove">
                                    <Form.Field>
                                        <label className="labelnme">Last Name</label>
                                        <Input iconPosition='left' type="text" name="lastName" onChange={this.textInput} />
                                    </Form.Field>
                                </Grid.Column>

                                 <Grid.Column className="border-remove">
                                    <Form.Field className="datepickerfield">
                                        <label className="labelnme">Date of Birth</label>
                                        {/* <DateInput

                                            name={"dob"}
                                            type="text"
                                            placeholder="date"
                                            value={this.state.details.dob}
                                            iconPosition="left"
                                            onChange={this.handleChange}
                                            id="dob"
                                            
                                        /> */}
                                <Input iconPosition='left'  value={this.state.details.dob} type="date" name={"dob"}onChange={this.textInput} />
                                    </Form.Field>
                                </Grid.Column>

                                

                            </Grid>


                            <Grid columns={3} divided>
                                <Grid.Column className="border-remove">
                                    <Form.Field>
                                        <label className="labelnme">Phone</label>
                                        <Input iconPosition='left' type="text" name="phone" onChange={this.textInput} />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column className="border-remove">
                                    <Form.Field>
                                        <label className="labelnme">Email</label>
                                        <Input iconPosition='left' type="email" name="email" onChange={this.textInput} />
                                    </Form.Field>
                                </Grid.Column>

                                <Grid.Column className="border-remove">
                                    <Form.Field>
                                        <label className="labelnme">Password</label>
                                        <Input iconPosition='left' type="password" name="password" onChange={this.textInput} />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid>

                            <div className="formbutn addeditmodal-formbutn"><Button type='submit' onClick={this.createUsers}>Submit</Button></div>
                        </Form>

                    </div>
                </div>
            </div>
        );
    }
}

// export default AddUserListForm;
const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch, state) {
    return {
        actions: bindActionCreators(actioncreators, dispatch)
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AddUserListForm)
);