import React, { Component } from 'react';
import './Form.css';
import 'semantic-ui-css/semantic.min.css'
import { Grid, Image, Button, Container, Checkbox, Form, Icon, Input } from 'semantic-ui-react'

// import Header from './Header';
import { NavLink } from 'react-router-dom';
import Solarimage from '../../Images/Solarimage.jpg';
import slide2 from '../../Images/slide2.png';
import Shadecharger from '../../Images/Shadecharger.png'
import logo from '../../Images/bluelogo.png'
import * as actioncreators from "../../redux/action"
import {connect} from "react-redux"
import {bindActionCreators} from "redux"
import {withRouter} from "react-router-dom"

class ForgotPassword extends Component {
    constructor(props){
        super(this.props);
        this.state={
            loading: false,
            iconLoading: false,
            showInstallMessage: false,
            visible: false,
            mode: 10, //is this a number??
            device: "",
            visible: false,
            current: 0,

        }
    }

    enterLoading = () => {
        this.setState({ loading: true });
      };
          componentWillReceiveProps(newProps) {
        console.log("new");
        if (!Object.is(newProps.error, this.props.error)) {
          this.setState({
            loading: false
          });
        }
      }
    
    
    
    
      next() {
        const current = this.state.current + 1;
        console.log(current)
        if(current===1)
        {
          //email entry
          console.log(this.state.email)
          this.props.actions.verifyemail(this.state.email)
          //send to 2 if email is validated
        }
    
        else if(current===2)
        {
          //otp verify
          this.props.actions.verifyotp(this.state.email,this.state.otp)
        }
    
    
        this.setState({ current });
      }
    
      prev() {
        const current = this.state.current - 1;
        this.setState({ current });
      }
    
     changeemail=(e)=>{
       console.log(e.target.value)
       this.setState({
         email:e.target.value
       })
    
     }
    
     changeotp=(e)=>{
       this.setState({
         otp:e.target.value
       },()=>console.log(this.state.otp))
     }
    
    
    




render() {
    // const { current } = this.state;
    // let steps = [
    //   {
    //     title: "Email",
    //     content: (
    //       <div>
    //           <Form.Field>
    //           <Form.Item
    //              className="firststepheading">
    //               Enter your Email to recieve code
                
    //               <Form className="formfield emailfield">

    //               ]
    //             })(
    //                 <Form.Field>
    //                 <label className="labelnme">Email</label>
    //               <Input onChange={this.changeemail} prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Enter email address" />
    //               </Form.Field>
    //             )}
                
    //           </Form.Item>
    //         </Form>
    //       </div>
    //     )
    //   },
    //   {
    //     title: "OTP",
    //     content: (
    //       <div>
    //         <Form>
    //           <Form.Item className="firststepheading">
    //               Check your email and enter 4-digit OTP
              
    //           {getFieldDecorator("otp", {
    //               rules: [{
    //                 type: 'number', message: 'The input is not valid OTP!',
    //               }, 
    //                 { required: true, message: "Please enter OTP!" }
    //               ]
    //             })(
    //             <Input onChange={this.changeotp} prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="number" placeholder="Enter OTP" />
    //             )}
    //             </Form.Item>
    //         </Form>
    //       </div>
    //     )
    //   },
    //   {
    //     title: "Reset",
    //     content: (
    //       <div>
    //         <Form>
    //           <Form.Item>
    //             <p className="firststepheading">
    //               Reset your password
    //             </p>
    //             <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="text" placeholder="Enter new password" />
    //             <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="text" placeholder="Re-enter password" className="mt15"/>
    //           </Form.Item>
    //         </Form>
    //       </div>
    //     )
    //   }
    // ];

    return (
        <div className="signupform mobileviewclass signinform">
            <Container>
                <div className="logoare">
                    <NavLink to="/home">
                        <img src={logo} />
                    </NavLink>
                </div>
                <div className="sub-inerfrm signin-subfrm">
                    <Grid>
                        <Grid.Row columns={8}>
                            <Grid.Column computer={6} mobile={0} tablet={6} className="hidden-xs">
                                <div className="loginformimg">
                                    <Image src={Shadecharger} />
                                    {/* <NavLink to="/home">
                                        <div className="bacbutnarea">
                                            <p className="homeicnarea">
                                        <img src={homeicn} className="homeicn"/>
                                        </p>
                                        <p className="backhomelink"> Back to home</p>
                                        </div>
                                        </NavLink> */}
                                </div>
                            </Grid.Column>

                            <Grid.Column computer={10} mobile={16}
                            // tablet={10}
                            >
                                <div className="formfieldarea">
                                    <div className="frm-heading">
                                        <h2 className="forgotpswrdhedng">Forgot Password</h2>
                                        {/* <p className="headerlne">New user? <NavLink to="/signup"><span>Sign up</span></NavLink></p> */}
                                    </div>
                                    <Form className="formfield emailfield">

                                        <Form.Field>
                                            <label className="labelnme">Password</label>
                                            <Input iconPosition='left' type="password">
                                                {/* <Icon name='lock' /> */}
                                            </Input>

                                        </Form.Field>
                                        <Form.Field>
                                            <label className="labelnme">Confirm Password</label>
                                            <Input iconPosition='left' type="password">
                                                {/* <Icon name='lock' /> */}
                                            </Input>
                                        </Form.Field>

                                        <div className="formbutn">
                                            {/* <NavLink to="/signin"> */}
                                            <Button type='submit'>Submit</Button>
                                            {/* </NavLink> */}
                                        </div>
                                    </Form>

                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </Container>
        </div>
    );
}
}

// export default ForgotPassword;

const mapStateToProps= state => {
    return  state;
}
function mapDispatchToProps(dispatch,state){
return  {
    action: bindActionCreators(actioncreators,dispatch)

};
}
export default withRouter(
    connect(mapDispatchToProps, mapStateToProps)(ForgotPassword)
);
